import styled, { keyframes } from 'styled-components/macro';
import { FlexMiddle } from '../../styled/primitives.css';

export const LineWrapperAnimation = keyframes`
// 0% { opacity: 0; transform: translateY(30px)};
// 100% {opacity:1; transform: translateY(0)};
`;

export const ChartsContainer = styled.div`
    ${FlexMiddle}
    color: var(--dark-gray);
    align-items: stretch;
    flex-grow: 1;
`;
export const ChartWrapper = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
`;
export const ChartTitle = styled.div`
	background: var(--light-green);
	text-transform: uppercase;
    text-align: center;
    letter-spacing: 1px;
`;
export const LineWrapper = styled.div`
    /* flex-grow: 1; */
    position: relative;
    height: 25vh;
`;

export const ButtonWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-wrap: wrap;
`;

export const Button = styled.button`
	transition: 0.5s;
	width: 8em;
	height: 2.5em;
	border-radius: 10px;
	font-size: 1.2rem;
	font-weight: 600;
	padding: 2px 2px;
	margin-right: 10px;
	text-align: center;
	:hover {
		background: #74c45d;
	}
	:focus {
		background: #74c45d;
	}
`;

export const Select = styled.select`
	padding: 0.3em 0.4em;
	width: 10em;
	border-radius: 4px;
`;

export const DatePickerWrapper = styled.div`
	display: flex;
	justify-content: space-between;
`;

