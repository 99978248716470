import React from 'react';
import { useForm, useFormContext } from 'react-hook-form';

import { noPropagate } from '../../utils/utils';
import Selector from '../Custom/Selector';
import { FormContainer } from '../Maps/styled/styledMapInputs';
import { StyledButton } from '../styled/styledFormElements.css';
import TextInput from '../Custom/TextInput';

const FieldForm = props => {
    const { getValues, groupOptions } = useFormContext();
    const {
        register,
        handleSubmit,
        formState: { errors },
        setError,
    } = useForm({ mode: 'all' });

    function onSubmit(data) {
        const sameNamedField = props.fields.find(field => field.info.name === data.name);

        if (sameNamedField && sameNamedField?.id !== selectedField.id) {
            setError(
                'name',
                { type: 'unique', message: 'Name is already taken' },
                { shouldFocus: true }
            );
        } else {
            props.onSave && props.onSave(data);
        }
    }

    function handleCancel(e) {
        props.onCancel(isFieldNew);
    }

    const selectedFieldIdx = props.fields.findIndex(field => field.id === props.selectedId);
    const selectedField = props.fields[selectedFieldIdx];

    if (!selectedField) return null;

    const initFieldName = selectedField.info.name;
    const isFieldNew = Object.values(selectedField.info).every(val => !val);
    const displayName = selectedField.info.name || `Field: ${selectedFieldIdx + 1}`;

    return (
        <FormContainer>
            <form onSubmit={noPropagate(handleSubmit(onSubmit))}>
                <fieldset>
                    <legend>{displayName}</legend>

                    <label htmlFor='name'>Enter field name</label>
                    <TextInput
                        id='name'
                        {...register('name', { required: 'Required' })}
                        placeholder='Enter field name'
                        defaultValue={selectedField.info?.name}
                        errors={errors}
                    />

                    <label htmlFor='variant'>Enter variant</label>
                    <TextInput
                        id='variant'
                        {...register('variant', { required: 'Required' })}
                        placeholder='Enter variant'
                        defaultValue={selectedField.info?.variant}
                        errors={errors}
                    />

                    <label htmlFor=''>Select variety group</label>
                    <Selector
                        {...register('group', {required: 'Required'})}
                        defaultValue={selectedField.info?.group}
                        placeholder='Groups with examples'
                        errors={errors}
                        options={groupOptions}
                    />
                </fieldset>

                <StyledButton type='submit'>Save</StyledButton>
                <StyledButton type='button' onClick={handleCancel}>
                    Cancel
                </StyledButton>
            </form>
        </FormContainer>
    );
};

export default FieldForm;
