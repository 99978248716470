import styled from 'styled-components/macro';
import { FormProvider } from 'react-hook-form';
import { LoadingView } from '../Custom/LoadingView';
import ValidationError from '../Custom/ValidationError';
import { CloseButton, DeleteButton, OkButton, PlusButton } from '../Custom/IconButton';
import { WinessenseLogo } from '../Landing/styledEntry.css';
import { Flex } from '../styled/primitives.css';
import ZoneInput from './ZoneInput';
import Selector from '../Custom/Selector';
import { useVineyardEditor } from '../../hooks/useVineyardEditor';

const VineyardEditor = ({ isCreate }) => {
    const {
        zones,
        dfltZone,
        userZones,
        zone_id,
        formContext,
        onSubmit,
        isDirty,
        isLoading,
        handleAddZone,
        handleSelectZone,
        handleClear,
        handleExit,
    } = useVineyardEditor(isCreate);

    const {
        handleSubmit,
        formState: { errors },
    } = formContext;

    return (
        <Container>
            <FormProvider {...formContext}>
                <Form id='vineyard-editor' onSubmit={handleSubmit(onSubmit)}>
                    <Header>
                        <Flex gap='3rem' align='center'>
                            <WinessenseLogo size='5rem' />
                            <h1>Vineyard Editor</h1>
                        </Flex>
                        <Flex gap='6rem' align='center'>
                            <Flex gap='1.5rem'>
                                <PlusButton
                                    label='New Zone'
                                    labelPos='right'
                                    onClick={handleAddZone}
                                    disabled={!isCreate && isDirty}
                                />
                                <Selector
                                    id='zoneSelector'
                                    label='or'
                                    placeholder='Edit zone'
                                    disabled={isDirty}
                                    options={userZones}
                                    key={zone_id ?? ''}
                                    defaultValue={zone_id ?? ''}
                                    onChange={handleSelectZone}
                                />
                            </Flex>
                            <Flex gap='1.5rem'>
                                <OkButton
                                    label='Submit'
                                    type='submit'
                                    onClick={() => console.log({ errors })}
                                    disabled={!isDirty}
                                />
                                <DeleteButton
                                    label='Clear'
                                    onClick={handleClear}
                                    disabled={!isDirty}
                                />
                            </Flex>
                        </Flex>
                        <CloseButton label={dfltZone ? 'Exit' : 'Logout'} onClick={handleExit} />
                    </Header>

                    {isLoading ? (
                        <LoadingView />
                    ) : (
                        <ZonesContainer>
                            {zones.map((zone, index) => (
                                <ZoneInput
                                    name={`zones.${index}`}
                                    key={zone.id}
                                    nestIndex={index}
                                />
                            ))}
                        </ZonesContainer>
                    )}
                </Form>
            </FormProvider>
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    min-height: 100%;
    background: var(--green);
    height: 100vh;
`;

const Header = styled.header`
    display: flex;
    height: 10vh;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 3rem;
    background-color: var(--dark-green);
    color: white;

    h1 {
        font-weight: normal;
    }
`;

const Form = styled.form`
    display: contents;
    ${ValidationError} {
        margin: 1rem 15.3rem 0 auto;
        svg {
            filter: drop-shadow(0px 0px 3px rgb(0 0 0 / 0.7));
        }
    }
`;

const ZonesContainer = styled.div`
    width: 100%;
    height: 90vh;
    overflow: auto;
    display: flex;
    align-items: start;
    flex-wrap: wrap;
    gap: 1rem;
    padding-top: 1rem;
    justify-content: center;
`;

export default VineyardEditor;
