import styled from 'styled-components/macro';

import { InputStyle } from './primitives.css';

export const StyledInput = styled.input`
    ${InputStyle};
    --focus-color: var(--orange);
`;

export const StyledButton = styled.button`
    ${InputStyle};
    --focus-color: var(--white);
    border-radius: 0.8em;

    font-size: 1.1em;
    color: var(--white);
    background-color: ${props =>
        props.disabled ? 'var(--gray)' : 'var(--action-color)'};
    font-weight: bold;
    cursor: pointer;

    & :active {
        outline: 1px solid var(--action-color);
    }
`;
