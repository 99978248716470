import { polygon as turfPolygon } from '@turf/helpers';
import { v4 as getUniqueId } from 'uuid';

export class wsZone {
    constructor(id = null) {
        this.id = id || getUniqueId();
        this.name = null;

        this.lat = null;
        this.long = null;
        this.alt = null;
        this.tz = null;
        this.start_ts = null;
        this.init_zone = null;

        this.fields = [];
    }

    static createFromApi(apiZone) {
        let z = new wsZone(apiZone.id);
        z.name = apiZone?.name;
        z.lat = apiZone?.lat;
        z.long = apiZone?.long;
        z.alt = apiZone?.alt;
        z.tz = apiZone?.tz;
        z.fields = apiZone?.fields?.map(wsField.createFromApi);
        return z;
    }
}

export class wsField {
    constructor(id = null) {
        this.id = id || getUniqueId();

        this.polygon = null;
        this.sensor = null;
        this.info = {};
    }

    static create(fieldLikeObject) {
        return new wsField(fieldLikeObject.id)
            .setPolygon(fieldLikeObject.polygon)
            .setInfo(fieldLikeObject.info)
            .setSensor(fieldLikeObject?.sensor && new wsSensor(fieldLikeObject.sensor));
    }

    static createFromApi(apiField) {
        let { id, polygon, ...info } = apiField;

        let f = new wsField(id).setInfo(info);

        if (polygon?.coords) {
            let dots = polygon.coords.map(dot => [+dot.long, +dot.lat]);
            dots.push(dots[0]);
            f.setPolygon(turfPolygon([dots]));
        }

        return f;
    }

    setSensor(sensor) {
        if (!sensor) return this;
        this.sensor = sensor;
        return this;
    }

    setPolygon(polygon) {
        if (!polygon) return this;
        this.polygon = polygon;
        this._updateFeatureProperties();
        return this;
    }

    setInfo(formData) {
        if (!formData) return this;
        this.info = Object.fromEntries(Object.entries(formData));
        this._updateFeatureProperties();
        return this;
    }

    setSensorInfo(formData) {
        if (!this.sensor) this.setSensor(new wsSensor({ fieldId: this.id }));
        this.sensor.setInfo(formData);
        return this;
    }

    setSensorLocation(geoJSON) {
        if (!this.sensor) this.setSensor(new wsSensor({ fieldId: this.id }));
        this.sensor.setPoint(geoJSON);
        return this;
    }

    toGeoJSON() {
        let arr = [];
        this.polygon && arr.push(this.polygon);
        this.sensor?.point && arr.push(this.sensor.point);
        return arr;
    }

    copy() {
        return new wsField(this.id)
            .setPolygon(this.polygon)
            .setInfo(this.info)
            .setSensor(this.sensor);
    }

    _updateFeatureProperties() {
        if (!this.polygon) return;
        this.polygon.properties = {
            ...this.info,
            id: this.id,
        };
    }
}

export class wsSensor {
    constructor({ info = {}, point = null, id = null, fieldId = null } = {}) {
        this.id = id || getUniqueId();
        this.info = info;
        this.point = point;
        this.fieldId = fieldId;
        this._updateFeatureProperties();
    }

    setPoint(feature) {
        this.point = feature;
        this._updateFeatureProperties();
    }

    setInfo(formData) {
        this.info = Object.fromEntries(Object.entries(formData));
        this._updateFeatureProperties();
    }

    getLatLong() {
        if (this.point) {
            return {
                lat: this.point.geometry.coordinates[1],
                long: this.point.geometry.coordinates[0],
            };
        }
    }

    setLatLong(lat, long) {
        let feature = {
            type: 'Feature',
            properties: {},
            geometry: {
                type: 'Point',
                coordinates: [Number(long), Number(lat)],
            },
        };
        this.setPoint(feature);
    }

    getLocationString() {
        const location = this.getLatLong();
        return location
            ? `Lat: ${location.lat}\xB0 \nLong: ${location.long}\xB0`
            : 'Place sensor on map!';
    }

    _updateFeatureProperties() {
        if (!this.point) return;
        this.point.properties.id = this.id;

        if (!this.fieldId) return;
        this.point.properties.fieldId = this.fieldId;

        if (!this.info.serial) return;
        this.point.properties.serial = this.info.serial;
    }
}
