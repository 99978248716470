import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { DebugRouter } from './utils/DebugRouter';
import { publicRoute, privateRoute } from './utils/RouteAuth';
import { useUserCtx } from './context/UserContext';

import NewLayout from './components/Layout/NewLayout';

import Landing from './components/Landing/Landing';
import Login from './components/Landing/Login';
import Signup from './components/Landing/Signup';
import VineyardEditor from './components/VineyardEditor/VineyardEditor';
import FieldDashboard from './views/FieldDashboard';
import FieldCharts from './views/FieldCharts';
import FieldTable from './views/FieldTable';
import Maps from './views/Maps';
import Notifications from './views/Notifications';
import Verify from './components/Landing/Verify';
import ForgotPass from './components/Landing/ForgotPass';
import ResetPass from './components/Landing/ResetPass';
import Redirect from './components/Landing/Redirect';

function App() {
    const { dfltZone, dfltField } = useUserCtx();

    const dfltPrivateRoute = dfltZone ? `/${dfltZone}/${dfltField}/dashboard` : '/create';

    return (
        <BrowserRouter>
            <DebugRouter bypass>
                <Routes>
                    <Route path='/landing' element={publicRoute(<Landing showImg />)}>
                        <Route path='login' element={<Login />} />
                        <Route path='signup' element={<Signup />} />
                        <Route path='verify' element={<Verify />} />
                        <Route path='forgot_pass' element={<ForgotPass />} />
                        <Route path='reset_pass' element={<ResetPass />} />
                        <Route path='redirect' element={<Redirect />} />
                        <Route index element={<Navigate to='login' />} />
                    </Route>

                    <Route path='/edit'>
                        <Route index element={privateRoute(<VineyardEditor isCreate />)} />
                        <Route path=':zone_id' element={privateRoute(<VineyardEditor />)} />
                    </Route>
                    <Route path='/' element={privateRoute(<NewLayout />)}>
                        <Route path='/:zone_id/:field_id/dashboard' element={<FieldDashboard />} />
                        <Route path='/:zone_id/:field_id/charts' element={<FieldCharts />} />
                        <Route path='/:zone_id/:field_id/table' element={<FieldTable />} />
                        <Route path='/:zone_id/:field_id/maps' element={<Maps />} />
                        <Route path='/:zone_id/:field_id/notifications' element={<Notifications />} />

                        <Route index element={<Navigate to={dfltPrivateRoute} />} />
                        <Route path='*' element={<Navigate to={dfltPrivateRoute} />} />
                    </Route>
                </Routes>
            </DebugRouter>
        </BrowserRouter>
    );
}

export default App;
