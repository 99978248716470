import React from 'react';
import styled from 'styled-components/macro';
import { Link, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { ErrorMessage } from '@hookform/error-message';
import { FlexMiddle } from '../styled/primitives.css';
import { NarrowForm, FormTitle, SubmitButton, FormText, WinessenseLogo } from './styledEntry.css';
import TextInput from '../Custom/TextInput';
import { fetchAPI } from '../../utils/fetchAPI';
import hash from '../../utils/hash';

const Container = styled.div`
    width: 100%;
    height: 100%;
    ${FlexMiddle}
`;

const schema = yup.object().shape({
    email: yup.string().email('Email must be valid!'),
    password: yup.string().required('Required'),
    confirmPassword: yup
        .string()
        .required('Required')
        .oneOf([yup.ref('password'), null], 'Passwords must match'),
});

const Signup = () => {
    const navigate = useNavigate();

    const {
        register,
        handleSubmit,
        formState: { errors },
        setError,
        clearErrors,
    } = useForm({
        mode: 'onChange',
        resolver: yupResolver(schema),
    });

    async function onSubmit(data, e) {
        const hash_pass = await hash(data.email + data.password);
        const nav_state = {
            email: data.email,
            hash_pass: hash_pass,
            from: 'signup',
        };
        fetchAPI('gCode', {
            v: '1.0',
            email: data.email,
            hash_pass: hash_pass,
        })
            .then(response => {
                if (response.resp_code === 0) {
                    navigate('/landing/verify', { state: nav_state, replace: true });
                } else setError('submit', { message: response.resp_text });
            })
            .catch(error => setError('submit', { message: error.message }));
    }

    return (
        <Container>
            <WinessenseLogo width='8rem' />
            <FormTitle>Sign up</FormTitle>

            <NarrowForm onSubmit={handleSubmit(onSubmit)} onFocus={e => clearErrors('submit')}>
                <TextInput
                    {...register('email')}
                    type='text'
                    placeholder='Enter your e-mail'
                    errors={errors}
                />
                <TextInput
                    {...register('password')}
                    type='password'
                    placeholder='Enter your password'
                    errors={errors}
                />
                <TextInput
                    {...register('confirmPassword')}
                    type='password'
                    placeholder='Repeat your password'
                    errors={errors}
                />
                <SubmitButton>Sign up</SubmitButton>
                <ErrorMessage
                    errors={errors}
                    name='submit'
                    render={({ message }) => <FormText>{message}</FormText>}
                />
            </NarrowForm>

            <FormText>
                Already have an account? <Link to='/landing/login'>Log in!</Link>
            </FormText>
        </Container>
    );
};

export default Signup;
