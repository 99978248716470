import 'react-tabulator/css/bootstrap/tabulator_bootstrap4.min.css';
import 'react-tabulator/css/semantic-ui/tabulator_semantic-ui.css';

import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';

import { useAuthCtx } from '../context/AuthContext';
import { useDateCtx } from '../context/DateContext';
import { useZoneCtx } from '../context/ZoneContext';
import { useFieldCtx } from '../context/FieldContext';

import DailyTable from '../components/Historical/Tables/DailyTable';
import HourlyTable from '../components/Historical/Tables/HourlyTable';
import TitledSection from '../components/Custom/TitledSection';
import HistoricalPicker from '../components/Historical/HistoricalPicker';
import { useParamsSync } from '../hooks/useParamsSync';
import { fetchAPI, DataAdapter } from '../utils/fetchAPI';

const TableBodyWrapper = styled.div`
    flex: 1;
`;

const FieldTable = () => {
    const { token } = useAuthCtx();
    const { zoneId, setZoneHourlyData, setZoneDailyData } = useZoneCtx();
    const { fieldId, fieldsOfZone } = useFieldCtx();
    const {
        isDaily,
        defaultDailyValue,
        defaultHourlyValue,
        dateFromDaily,
        dateFromHourly,
        dateToDaily,
        dateToHourly,
    } = useDateCtx();

    useEffect(() => {
        (async () => await fetchHistoricalData())();
    }, [
        zoneId,
        fieldId,
        defaultDailyValue,
        defaultHourlyValue,
        dateFromDaily,
        dateFromHourly,
        dateToDaily,
        dateToHourly,
        isDaily,
    ]);

    useParamsSync();

    async function fetchHistoricalData() {
        console.log('fetchHistoricalData');

        const minField = Math.min(...fieldsOfZone);
        const maxField = Math.max(...fieldsOfZone);

        const apiName = isDaily ? 'sDaly' : 'sHrly';
        const dateFrom = isDaily ? dateFromDaily : dateFromHourly;
        const dateTo = isDaily ? dateToDaily : dateToHourly;
        const setZoneData = isDaily ? setZoneDailyData : setZoneHourlyData;

        setZoneData(undefined);
        const historicalData = await fetchAPI(apiName, {
            v: '1.1',
            token: token,
            zone: `${zoneId}`,
            from_ts: `${(dateFrom.getTime() / 1000) | 0}`,
            to_ts: `${(dateTo.getTime() / 1000) | 0}`,
        })
            .then(DataAdapter(apiName, 0.4, 0.2))
            .catch(error => alert(error.message));

        const zoneData = { cols: historicalData.cols, data: [] };

        for (let field = minField; field <= maxField; field++) {
            zoneData.data[field] = [];
            for (let j = 0; j < historicalData.data.length; j++) {
                if (historicalData.data[j][0] === field) {
                    zoneData.data[field].push(historicalData.data[j]);
                }
            }
        }
        setZoneData(zoneData);
    }

    return (
        <TitledSection>
            <HistoricalPicker />
            <TableBodyWrapper>
                {isDaily && <DailyTable />}
                {!isDaily && <HourlyTable />}
            </TableBodyWrapper>
        </TitledSection>
    );
};

export default FieldTable;
