import React, { useRef, useState } from 'react';

import { wsField } from '../../models/wsDataModels';
import { HorizontalSplit, InfoBar } from '../Maps/styled/styledMapInputs';
import useFormZoneCenter from '../Maps/hooks/useFormZoneCenter';
import Modal, { Popup } from '../Custom/Modal';
import LeafletMap from '../Maps/components/LeafletMap';
import MapEvents from '../Maps/components/MapEvents';
import EditTool from '../Maps/components/EditTool';
import SensorForm from './SensorForm';
import FieldDisplay from './FieldDisplay';
import FieldForm from './FieldForm';
import MapPreview from '../Maps/components/MapPreview';
import { StyledButton } from '../styled/styledFormElements.css';
import { useFormContext } from 'react-hook-form';

const FieldsInput = props => {
    const initFields = (props.value || []).map(field => wsField.create(field));
    const [_fields, _setFields] = useState(initFields);

    const [selectedId, _setSelectedId] = useState(0);
    const selectedRef = useRef(selectedId);
    function setSelectedId(id) {
        _setSelectedId(id);
        selectedRef.current = id;
    }

    const editControlRef = useRef();

    const [map, setMap] = useState();
    const [modalOpen, setModalOpen] = useState(false);
    const [fieldPopupOpen, setFieldPopupOpen] = useState(false);
    const [sensorPopupOpen, setSensorPopupOpen] = useState(false);

    const zoneCenter = useFormZoneCenter(props.name);
    const { isCreate } = useFormContext();

    function handleLayerCreate(leafletEvt, fgRef, map) {
        if (leafletEvt.layerType === 'polygon') handleCreatePolygon(leafletEvt, fgRef, map);
        if (leafletEvt.layerType === 'marker') handleCreateMarker(leafletEvt, fgRef, map);
    }

    function handleCreatePolygon(leafletEvt, featureGroupRef, map) {
        const { layer } = leafletEvt;

        const field = new wsField().setPolygon(layer.toGeoJSON());
        _setFields(old => [...old, field]);

        setSelectedId(field.id);
        setFieldPopupOpen(true);
    }

    function handleCreateMarker(leafletEvt, featureGroupRef) {
        const { layer } = leafletEvt;

        _setFields(old =>
            old.map(f =>
                f.id === selectedRef.current ? f.copy().setSensorLocation(layer.toGeoJSON()) : f
            )
        );
    }

    function handleLayerEdit(leafletEvt) {
        const editedFeatures = leafletEvt.layers.getLayers().map(lyr => lyr.toGeoJSON());
        const editedIds = editedFeatures.map(feature => feature.properties.id);
        const editedFeature = id => editedFeatures.find(f => f.properties.id === id);

        _setFields(old =>
            old.map(field => {
                let newField = field;
                if (editedIds.includes(field.id)) {
                    newField = newField.copy().setPolygon(editedFeature(field.id));
                }
                if (editedIds.includes(field.sensor?.id)) {
                    newField = newField.copy().setSensorLocation(editedFeature(field.sensor?.id));
                }
                return newField;
            })
        );
    }

    function handleLayerClick(leafletEvt) {
        const {
            geometry: { type },
            properties,
        } = leafletEvt.target.feature;

        if (type === 'Polygon') setSelectedId(properties.id);
        if (type === 'Point') setSelectedId(properties.fieldId);
    }

    function handleMapClick(_e) {
        setSelectedId(0);
    }

    function handleEditFieldClick(_e) {
        setFieldPopupOpen(true);
    }

    function handleDeleteFieldClick(_e) {
        _setFields(old => old.filter(f => f.id !== selectedId || f.sensor));
    }

    function handleAddSensorClick(_e) {
        setSensorPopupOpen(true);
    }

    function handleEditSensorClick(_e) {
        setSensorPopupOpen(true);
    }

    function handleDeleteSensorClick(_e) {
        _setFields(old => old.map(f => (f.id === selectedId ? f.copy().setSensor(undefined) : f)));
    }

    function handleFieldFormSave(formData) {
        _setFields(old => old.map(f => (f.id === selectedId ? f.copy().setInfo(formData) : f)));
        setFieldPopupOpen(false);
    }

    function handleFieldFormCancel(isFieldNew) {
        // if field was just created delete it on Cancel
        isFieldNew && _setFields(old => old.filter(f => f.id !== selectedId));

        setFieldPopupOpen(false);
        isFieldNew && setSelectedId(0);
    }

    function handleSensorFormSave(formData) {
        const { lat, long, ...info } = formData;
        let shouldDrawSensorByHand = !lat || !long;

        const editedField = _fields
            .find(f => f.id === selectedId)
            .copy()
            .setSensorInfo(info);

        !shouldDrawSensorByHand && editedField.sensor.setLatLong(lat, long);

        _setFields(old => old.map(f => (f.id === selectedId ? editedField : f)));

        setSensorPopupOpen(false);
        shouldDrawSensorByHand && _enableMarkerDraw();
    }

    function _enableMarkerDraw() {
        setTimeout(() => editControlRef.current._toolbars.draw._modes.marker.handler.enable(), 1);
    }

    function handleSensorFormCancel() {
        setSensorPopupOpen(false);
    }

    function handleModalClose() {
        props.onChange(_fields);
        setModalOpen(false);
    }

    const selectedField = _fields.find(f => f.id === selectedId);

    return (
        <>
            <MapPreview
                fields={_fields}
                onClick={() => setModalOpen(true)}
                center={zoneCenter}
                recenterDeps={modalOpen}
            />

            <Modal
                show={modalOpen}
                title='Draw fields of Zone'
                onClose={handleModalClose}
                removeOnHide={isCreate}
            >
                <HorizontalSplit>
                    <LeafletMap
                        center={zoneCenter}
                        tiles={['satellite', 'osm']}
                        minZoom={3}
                        setMap={setMap}
                        recenterDeps={modalOpen}
                    >
                        <MapEvents onClick={handleMapClick} />
                        <EditTool
                            data={_fields}
                            selectedId={selectedId}
                            controlRef={editControlRef}
                            onCreate={handleLayerCreate}
                            onEdit={handleLayerEdit}
                            onLayerClick={handleLayerClick}
                        />
                    </LeafletMap>

                    <InfoBar>
                        <FieldDisplay
                            fields={_fields}
                            selectedId={selectedId}
                            key={JSON.stringify(selectedField)}
                            onAddSensorClick={handleAddSensorClick}
                            onEditSensorClick={handleEditSensorClick}
                            onDeleteSensorClick={handleDeleteSensorClick}
                            onEditFieldClick={handleEditFieldClick}
                            onDeleteFieldClick={handleDeleteFieldClick}
                        />

                        {!!_fields.length && (
                            <StyledButton onClick={handleModalClose}>Save and Close</StyledButton>
                        )}
                    </InfoBar>
                </HorizontalSplit>

                <Popup show={fieldPopupOpen}>
                    <FieldForm
                        fields={_fields}
                        selectedId={selectedId}
                        onSave={handleFieldFormSave}
                        onCancel={handleFieldFormCancel}
                    />
                </Popup>

                <Popup show={sensorPopupOpen}>
                    <SensorForm
                        fields={_fields}
                        selectedId={selectedId}
                        onSave={handleSensorFormSave}
                        onCancel={handleSensorFormCancel}
                    />
                </Popup>
            </Modal>
        </>
    );
};

export default FieldsInput;
