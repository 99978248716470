import temp_min from './img/forecastData/temp_min.svg';
import temp_mean from './img/forecastData/temp_mean.svg';
import temp_max from './img/forecastData/temp_max.svg';
import tempr from './img/forecastData/tempr.svg';
import hum_min from './img/forecastData/hum_min.svg';
import hum_mean from './img/forecastData/hum_mean.svg';
import hum_max from './img/forecastData/hum_max.svg';
import humdt from './img/forecastData/humdt.svg';
import rain from './img/forecastData/rain.svg';
import w_speed from './img/forecastData/w_speed.svg';
import leaf_w from './img/forecastData/leaf_w.svg';
import spraying from './img/forecastData/spraying.svg';
import p_viticola_sev from './img/forecastData/p_viticola_sev.svg';
import e_necator_sev from './img/forecastData/e_necator_sev.svg';
import b_cinerea_sev from './img/forecastData/b_cinerea_sev.svg';
import g_bidwellii_sev from './img/forecastData/g_bidwellii_sev.svg';
import forecast from './img/forecastData/forecast.svg';

const columnsData = {
    temp_min: {
        svg: temp_min,
        name: 'Min. air temperature',
        unit: '°',
    },
    temp_mean: {
        svg: temp_mean,
        name: 'Mean air temperature',
        unit: '°',
    },
    temp_max: {
        svg: temp_max,
        name: 'Max. air temperature',
        unit: '°',
    },
    tempr: {
        svg: tempr,
        name: 'Air Temperature',
        unit: '°',
    },
    hum_min: {
        svg: hum_min,
        name: 'Min. humidity',
        unit: '%',
    },
    hum_mean: {
        svg: hum_mean,
        name: 'Mean humidity',
        unit: '%',
    },
    hum_max: {
        svg: hum_max,
        name: 'Max. humidity',
        unit: '%',
    },
    humdt: {
        svg: humdt,
        name: 'Air humidity',
        unit: '%',
    },
    rain: {
        svg: rain,
        name: 'Rainfall',
        unit: 'mm',
    },
    w_speed: {
        svg: w_speed,
        name: 'Wind speed',
        unit: 'km/h',
    },
    leaf_w: {
        svg: leaf_w,
        name: 'Leaf wetness',
        unit: 'h',
    },
    spraying: {
        svg: spraying,
        name: 'Spraying conditions',
        unit: '',
    },
    p_viticola_sev: {
        svg: p_viticola_sev,
        name: 'Plasmopara viticola',
        unit: '%',
    },
    e_necator_sev: {
        svg: e_necator_sev,
        name: 'Erysiphe necator',
        unit: '%',
    },
    b_cinerea_sev: {
        svg: b_cinerea_sev,
        name: 'Botrytis cinerea',
        unit: '%',
    },
    g_bidwellii_sev: {
        svg: g_bidwellii_sev,
        name: 'Guignardia bidwellii',
        unit: '%',
    },
    forecast: {
        svg: forecast,
        name: 'Weather Forecast',
        unit: '',
    },
};

const colorPalettes = {
    // prettier-ignore
    temp: ['#11103c','#12164a','#131b58','#132166','#142774','#152c82','#163290','#17389e','#173eac','#1843ba','#1b4bc6','#225bcc','#2a6bd1','#317bd6','#388adb','#3a91dd','#3c98df','#3d9fe2','#3fa6e4','#41ade6','#43b4e8','#45bbea','#47c2ec','#49c9ee','#4ad0f0','#4cd7f2','#4edef5','#50e5f7','#52ecf9','#54f0fb','#53eef6','#5feef6','#5af6fc','#6ef7fc','#78f8fc','#81f8fc','#8bf9fc','#95f9fc','#9ffafc','#a9fafc','#b3fbfc','#bdfbfc','#c7fcfc','#d1fcfc','#dbfdfc','#e5fdfc','#effefc','#fdfcf7','#fffceb','#fff9e1','#faf1c9','#faf0c3','#fbeebc','#fbedb6','#fbecaf','#fbeba9','#fbe99c','#fbe895','#fbe481','#fbe37b','#fce274','#fce16e','#fce067','#fcdf61','#fcdd54','#fcda50','#fcd54e','#fcd14c','#ffcd56','#fcc848','#fcc346','#fcbf44','#fcba42','#fcb640','#fcb13e','#fcad3c','#fda83a','#fda438','#fd9f36','#fd9b34','#fd9632','#fd9230','#fd8d2e','#fd892c','#fd842a','#fd8028','#fd7b26','#fd7724','#fc721d','#f86626','#f86626','#f24724','#f5361e','#f23424','#f03229','#ed302f','#ea2e35','#e72c3b','#e52a41','#e22846','#df264c','#dd2452','#da2258','#d7205e','#d22169','#cc2169','#c62069','#c02069','#ba1f69','#b31f69','#ad1f69','#a71e69','#a11e69','#9b1e69','#951d69','#8f1d6a','#881d6a','#821c6a','#7c1c6a','#761c6a','#701b6a','#6a1b6a','#631b6a','#5d1a6a','#571a6a','#511a6a','#4b196a','#45196b',],
    // prettier-ignore
    w_speed: ['#11103c','#12164a','#131b58','#132166','#142774','#152c82','#163290','#17389e','#173eac','#1843ba','#1b4bc6','#225bcc','#2a6bd1','#317bd6','#388adb','#3a91dd','#3c98df','#3d9fe2','#3fa6e4','#41ade6','#43b4e8','#45bbea','#47c2ec','#49c9ee','#4ad0f0','#4cd7f2','#4edef5','#50e5f7','#52ecf9','#54f0fb','#53eef6','#5feef6','#5af6fc','#6ef7fc','#78f8fc','#81f8fc','#8bf9fc','#95f9fc','#9ffafc','#a9fafc','#b3fbfc','#bdfbfc','#c7fcfc','#d1fcfc','#dbfdfc','#e5fdfc','#effefc','#fdfcf7','#fffceb','#fff9e1','#faf1c9','#faf0c3','#fbeebc','#fbedb6','#fbecaf','#fbeba9','#fbe99c','#fbe895','#fbe481','#fbe37b','#fce274','#fce16e','#fce067','#fcdf61','#fcdd54','#fcda50','#fcd54e','#fcd14c','#ffcd56','#fcc848','#fcc346','#fcbf44','#fcba42','#fcb640','#fcb13e','#fcad3c','#fda83a','#fda438','#fd9f36','#fd9b34','#fd9632','#fd9230','#fd8d2e','#fd892c','#fd842a','#fd8028','#fd7b26','#fd7724','#fc721d','#f86626','#f86626','#f24724','#f5361e','#f23424','#f03229','#ed302f','#ea2e35','#e72c3b','#e52a41','#e22846','#df264c','#dd2452','#da2258','#d7205e','#d22169','#cc2169','#c62069','#c02069','#ba1f69','#b31f69','#ad1f69','#a71e69','#a11e69','#9b1e69','#951d69','#8f1d6a','#881d6a','#821c6a','#7c1c6a','#761c6a','#701b6a','#6a1b6a','#631b6a','#5d1a6a','#571a6a','#511a6a','#4b196a','#45196b',],
    // prettier-ignore
    hum: ['#fffceb','#fff9e1','#fffbd4','#fff9bd','#ffffab','#feff84','#feff6c','#fffe22','#fff44e','#ffee22','#efee22','#dfde00','#cfde00','#bfde00','#afde00','#9fde00','#7fde00','#47de00','#47d000','#47d055','#47d0a4','#55e7c7','#3de3e6','#4cd7f2','#4ad0f0','#45bbea','#3d9fe2','#3a91dd','#2a6bd1','#225bcc','#1b4bc6','#173eac','#17389e',],
    // prettier-ignore
    rain: ['#f7fffe','#effefc','#e5fdfc','#bdfbfc','#a9fafc','#95f9fc','#81f8fc','#78f8fc','#54f0fb','#4cd7f2','#4cd7f2','#47c2ec','#45bbea','#43b4e8','#41ade6','#3fa6e4','#3d9fe2','#3c98df','#3a91dd','#388adb','#2a6bd1','#225bcc','#1b4bc6','#173eac','#17389e','#163290','#152c82','#142774','#132166','#131b58','#12164a','#11103c','#030126',],
    // prettier-ignore
    leaf_w_hour: ['#effefc', '#4cd7f2'],
    // prettier-ignore
    leaf_w_day: ['#f7fffe','#effefc','#e5fdfc','#bdfbfc','#a9fafc','#95f9fc','#81f8fc','#78f8fc','#54f0fb','#4cd7f2','#4cd7f2','#47c2ec','#45bbea','#43b4e8','#41ade6','#3fa6e4','#3d9fe2','#3c98df','#3a91dd','#388adb','#2a6bd1','#225bcc','#1b4bc6','#173eac','#17389e',],
    // prettier-ignore
    severity: ['#8ceb14','#9cf22e','#adf94e','#d0f94e','#f1f937','#fff44e','#fffe22','#ffee22','#ffdf52','#fcd843','#ffcd56','#fcc848','#fcbf44','#fcba42','#fcb640','#fcb13e','#fcad3c','#fda83a','#fda438','#fd9f36','#fd9230','#fc8922','#fd842a','#fd7b26','#f86626','#f24724','#f5361e','#f03229','#ed302f','#ea2737','#e31d1d','#da0808','#c90202',],
    // prettier-ignore
    // spraying: ['#fd9f36', '#fffe22', '#bfde00'],
};

export function getSvg(column) {
    checkIfColumnExists(column);
    return columnsData[column].svg;
}

export function getName(column) {
    checkIfColumnExists(column);
    return columnsData[column].name;
}

export function getUnit(column) {
    checkIfColumnExists(column);
    return columnsData[column].unit;
}

export function getColor(value, column, period) {
    const colorMappings = {
        temp: temp =>
            colorPalettes.temp[
                temp < -20 ? 0 : temp > 43 ? 127 : Math.ceil(2 * (temp + 20))
            ],
        hum: hum =>
            colorPalettes.hum[hum < 4 ? 0 : hum > 100 ? 32 : Math.floor((hum - 4) / 3)],
        w_speed: wind =>
            colorPalettes.w_speed[
                wind < 0 ? 0 : wind > 96 ? 127 : Math.floor((wind * 4) / 3)
            ],
        rain: r => colorPalettes.rain[r < 0 ? 0 : r > 96 ? 32 : Math.floor(r / 3)],
        leaf_w_day: lw => colorPalettes.leaf_w_day[lw < 0 ? 0 : lw > 24 ? 24 : lw],
        leaf_w_hour: lw => colorPalettes.leaf_w_hour[lw < 0 ? 0 : lw > 1 ? 1 : lw],
        sev: sev =>
            colorPalettes.severity[sev < 0 ? 0 : sev > 96 ? 32 : Math.floor(sev / 3)],
    };

    // different palletes for daily and hourly leaf_w
    checkIfColumnExists(column)
    if (column === 'leaf_w') return colorMappings[`${column}_${period}`](value);
    for(const name in colorMappings){
        if (column.includes(name)) return colorMappings[name](value)
    }
}

function checkIfColumnExists(column) {
    if (!columnsData[column]) throw new Error(`No tag named '${column}' in iconData.js`);
}
