import React from 'react';
import styled from 'styled-components/macro';
import { ErrorMessage } from '@hookform/error-message';
import { StyledInput } from '../styled/styledFormElements.css';
import ValidationError from './ValidationError';

const Container = styled.div`
    display: flex;
    align-items: center;
    position: relative;

    color: inherit;
    font-size: inherit;

    label {
        font-weight: bold;
        font-size: 1.375em;
        margin-right: 1em;
    }

    ${ValidationError} {
        position: absolute;
        right: 1em;
        top: 50%;
        transform: translateY(-50%);
    }
`;

const Select = styled(StyledInput).attrs({
    as: 'select',
})`
    border: 1px solid var(--gray);
    ${({ allCaps }) => allCaps && 'text-transform:uppercase'};
`;

const Selector = React.forwardRef(({ options, label, placeholder, errors, ...restParams }, ref) => {
    return (
        <Container>
            {label && <label>{label}</label>}
            <Select ref={ref} {...restParams}>
                {placeholder && (
                    <option value='' hidden>
                        {placeholder}
                    </option>
                )}
                {options &&
                    (options instanceof Array
                        ? options.map(option => (
                              <option value={option} key={option}>
                                  {option}
                              </option>
                          ))
                        : Object.entries(options).map(([value, label]) => (
                              <option value={value} key={value}>
                                  {label}
                              </option>
                          )))}
            </Select>
            {errors && (
                <ErrorMessage
                    errors={errors}
                    name={restParams?.name}
                    render={({ message }) => <ValidationError message={message} />}
                />
            )}
        </Container>
    );
});

export default Selector;
