import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components/macro';
import useUpdateEffect from '../../hooks/useUpdateEffect';
import { InputStyle } from '../styled/primitives.css';

import { StyledInput } from '../styled/styledFormElements.css';

const Container = styled.div`
    width: ${({ width }) => width || 'min-content'};
    height: min-content;
    display: flex;
    background: #e7e7e7;
    border-radius: 1rem;
    border: 1px solid var(--gray);
`;

const Button = styled.button`
    ${InputStyle};
    border-radius: 1rem;
    margin: -1px;
    ${({ disabled }) =>
        disabled
            ? css`
                  background: white;
                  font-weight: bold;
                  border: 1px solid var(--gray);
              `
            : css`
                  background: none;
                  font-weight: normal;
                  border: none;
              `}
    flex: 1;

    cursor: pointer;
    text-transform: uppercase;
`;

const Toggle = ({ firstActive, onToggle, labels, width, className }) => {
    return (
        <Container width={width} className={className}>
            <Button onClick={() => onToggle(true)} disabled={firstActive}>
                {labels[0]}
            </Button>
            <Button onClick={() => onToggle(false)} disabled={!firstActive}>
                {labels[1]}
            </Button>
        </Container>
    );
};

export default styled(Toggle)``;
