import React, { useEffect, useRef } from 'react';
import { FeatureGroup, useMap } from 'react-leaflet';
import { EditControl } from 'react-leaflet-draw';
import L from 'leaflet';
import { renderToString } from 'react-dom/server';
import { Polylabel } from '../styled/styledMapInputs';
import polylabel from 'polylabel';

const EditTool = props => {
    const featureGroupRef = useRef();
    const editControlRef = useRef();
    const labelGroupRef = useRef();

    const map = useMap();

    function handleLeafletEvents(e) {
        console.debug('EditTool handler');
        const { type } = e;
        if (type === 'draw:created') props.onCreate(e, featureGroupRef, map);
        if (type === 'draw:edited') props.onEdit(e, featureGroupRef, map);
        if (type === 'draw:deleted') props.onDelete(e, featureGroupRef, map);
    }

    function handleMount(e) {
        props.controlRef.current = e;
        editControlRef.current = e;
    }

    useEffect(() => {
        featureGroupRef.current.clearLayers();
        labelGroupRef.current.clearLayers();

        const featureArray = props.data.map(field => field.toGeoJSON()).flat();
        if (!featureArray.length) return;

        const dataLayer = L.geoJSON(featureArray, { bubblingMouseEvents: false });

        dataLayer.eachLayer(layer => {
            featureGroupRef.current.addLayer(layer);
            layer.on('click', e => props.onLayerClick(e));

            // for every polygon(field) add field name label 
            if (layer.feature.geometry.type === 'Polygon') {
                var fieldLabel = L.marker(polylabel(layer.feature.geometry.coordinates).reverse(), {
                    icon: L.divIcon({
                        className: 'poly-label',
                        html: renderToString(
                            <Polylabel>{layer.feature.properties.name}</Polylabel>
                        ),
                        iconSize: [0, 0],
                    }),
                    bubblingMouseEvents: true,
                });
                labelGroupRef.current.addLayer(fieldLabel);
            }
            // for every marker(sensor) bind serial number tooltip
            if (layer.feature.geometry.type === 'Point') {
                layer.bindTooltip(layer.feature.properties.serial, { direction: 'right' });
            }
        });
    }, [JSON.stringify(props.data.map(field => field.toGeoJSON()))]);

    useEffect(() => {
        map.removeControl(editControlRef.current);
        map.addControl(editControlRef.current);

        return () => map.removeControl(editControlRef.current);
    }, [map]);

    return (
        <>
            <FeatureGroup ref={featureGroupRef}>
                <EditControl
                    position='topright'
                    onMounted={handleMount}
                    draw={{
                        polygon: true,
                        marker: true,
                        circle: false,
                        circlemarker: false,
                        polyline: false,
                        rectangle: false,
                    }}
                    edit={{
                        remove: false,
                    }}
                    onCreated={handleLeafletEvents}
                    onEdited={handleLeafletEvents}
                    // onDeleted={handleLeafletEvents}
                />
            </FeatureGroup>
            <FeatureGroup ref={labelGroupRef} />
        </>
    );
};

export default EditTool;
