import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n.use(initReactI18next).init({
    // debug: true,
    lng: 'en',
    fallbackLng: 'en',
    ns: ['translations', 'common'],
    defaultNS: 'translations',
    fallbackNS: 'common',
    interpolation: {
        escapeValue: false,
    },
    resources: {
        en: {
            translations: require('./locales/en/translations.json'),
            common: require('./locales/en/common.json'),
        },
        // sr: {
        //     translations: require('./locales/sr/translations.json'),
        // },
    },
});

i18n.languages = ['en', 'rs'];

export default i18n;
