import { useLocation } from "react-router-dom"

export const DebugRouter = ({ children, bypass }) => {
    const location = useLocation()
    if (process.env.NODE_ENV === 'development' && !bypass) {
        console.log(
            `Route: ${location.pathname}${location.search}, State: ${JSON.stringify(location.state)}`,
        )
    }
  
    return children
}