import React, { useEffect, useMemo, useRef, useState, useReducer } from 'react';

import {
    Chart as ChartJS,
    LineElement,
    BarElement,
    LinearScale,
    CategoryScale,
    PointElement,
    Filler,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import zoomPlugin from 'chartjs-plugin-zoom';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { formatDate } from '../../../utils/formatDate';
import { useZoneCtx } from '../../../context/ZoneContext';
import { useFieldCtx } from '../../../context/FieldContext';
import { useDateCtx } from '../../../context/DateContext';

import { LineWrapper, ChartWrapper, ChartsContainer, ChartTitle } from './styledCharts.css';
import { LoadingView } from '../../Custom/LoadingView';

ChartJS.defaults.scale.ticks.maxRotation = 0;
ChartJS.register(
    zoomPlugin,
    ChartDataLabels,
    LineElement,
    BarElement,
    LinearScale,
    CategoryScale,
    PointElement,
    Filler
);

//TODO: Check limits options
const DailyCharts = () => {
    const myChartA = useRef();
    const myChartB = useRef();

    const { fieldId } = useFieldCtx();
    const { zoneDaily } = useZoneCtx();
    const { dateFromDaily, dateToDaily, defaultDailyValue } = useDateCtx();

    const [time, setTime] = useState([]);
    const timeFormat = 'DD/MM';

    const [temp_max, setTemp_max] = useState([]);
    const [temp_min, setTemp_min] = useState([]);
    const [temp_mean, setTemp_mean] = useState([]);
    const [hum_max, setHum_max] = useState([]);
    const [hum_min, setHum_min] = useState([]);
    const [hum_mean, setHum_mean] = useState([]);
    const [rain, setRain] = useState([]);

    const [leafWetness, setLeafWetness] = useState([]);
    const [plasmopara, setPlasmopara] = useState([]);
    const [uncinula, setUncinula] = useState([]);
    const [greyRot, setGreyRot] = useState([]);
    const [blackRot, setBlackRot] = useState([]);

    const defaultYAxis = {
        temp: { min: -10, max: 40 }, // 10%
        rain: { min: 0, max: 100 }, // 10%
        hum: { min: 0, max: 110 },
        leaf: { min: 0, max: 24 },
        disease: { min: 0, max: 100 },
    };
    const [yAxis, dispatchAxis] = useReducer((state, action) => {
        if (action.type in state) return { ...state, [action.type]: action.payload };
        else return state;
    }, defaultYAxis);

    const getDailyData = (data, period, rangeFrom, rangeTo) => {
        console.log('getDailyData');
        if (!(data && period && rangeFrom && rangeTo)) return;

        var arrays = [];
        var ts_helpData = [];
        console.log('PODACI: ', data);
        for (let i = 0; i < data.cols.length; i++) {
            arrays[data.cols[i]] = [];
            for (let j = 0; j < data.data[fieldId].length; j++) {
                arrays[data.cols[i]].push(data.data[fieldId][j][i]);
            }
        }
        console.log('ARRAYS: ', arrays);
        for (let timestamp of arrays['string_ts']) {
            ts_helpData.push(formatDate(timestamp, timeFormat));
        }

        arrays['rain'].forEach(item =>
            item > 6 ? Math.round(item) : Number.parseFloat(item).toFixed(1)
        );

        setTime(ts_helpData);

        setTemp_max(arrays['temp_max']);
        setTemp_min(arrays['temp_min']);
        setTemp_mean(arrays['temp_mean']);
        setRain(arrays['rain']);
        setHum_max(arrays['hum_max']);
        setHum_min(arrays['hum_min']);
        setHum_mean(arrays['hum_mean']);
        setLeafWetness(arrays['leaf_w']);
        setPlasmopara(arrays['pvit_severity']);
        setUncinula(arrays['enec_severity']);
        setGreyRot(arrays['bcin_severity']);
        setBlackRot(arrays['gbdw_severity']);

        dispatchAxis({
            type: 'temp',
            payload: {
                min: (Math.min(...arrays['temp_min']) - 5) >> 0,
                max: (Math.max(...arrays['temp_max']) + 5) >> 0,
            },
        });
        dispatchAxis({
            type: 'rain',
            payload: {
                min: 0,
                max: (Math.max(...arrays['rain']) + 10) >> 0,
            },
        });
    };

    // TODO: Unify chart options
    const tempChart = useMemo(() => {
        return (
            <Line
                ref={myChartA}
                data={{
                    labels: time,

                    datasets: [
                        {
                            label: 'MAX TEMP',
                            yAxisID: 'temp',
                            data: temp_max,
                            fill: false,
                            borderColor: '#E05963',
                            backgroundColor: '#E05963',
                            tension: 0.5,
                            pointRadius: 2,
                            borderWidth: 1,

                            datalabels: {
                                display: false,
                            },
                        },
                        {
                            label: 'MIN TEMP',
                            yAxisID: 'temp',
                            data: temp_min,
                            fill: false,
                            borderColor: '#5C7BD3',
                            backgroundColor: '#5C7BD3',
                            tension: 0.5,
                            pointRadius: 2,
                            borderWidth: 1,
                            datalabels: {
                                display: false,
                            },
                        },
                        {
                            label: 'MEAN TEMP',
                            yAxisID: 'temp',
                            data: temp_mean,
                            fill: false,
                            borderColor: '#F8AB0D',
                            backgroundColor: '#F8AB0D',
                            tension: 0.5,
                            pointRadius: 2,
                            borderWidth: 1,
                            datalabels: {
                                display: false,
                            },
                        },
                        {
                            label: 'RAIN',
                            yAxisID: 'rain',
                            data: rain,
                            type: 'bar',
                            fill: true,
                            borderColor: 'rgb(127, 0, 255)',
                            datalabels: {
                                display: function (context) {
                                    if (context.dataset.data.length > 60) {
                                        return false;
                                    }
                                    var index = context.dataIndex;
                                    var value = context.dataset.data[index];
                                    if (value === 0) {
                                        return false;
                                    }
                                },
                                color: '#000',
                                anchor: 'start',
                                align: 'end',
                                font: {
                                    size: '15',
                                    weight: '400',
                                    opacity: '0.8',
                                },
                            },
                        },
                    ],
                }}
                options={{
                    datasets: {
                        bar: {
                            barPercentage: 1.25,
                        },
                    },
                    responsive: true,
                    maintainAspectRatio: false,
                    plugins: {
                        zoom: {
                            zoom: {
                                wheel: {
                                    modifierKey: 'ctrl',
                                    enabled: true,
                                    speed: 0.2,
                                },
                                mode: 'x',
                                // onZoom: function (chart) {
                                //     if (myChartA.current.getZoomLevel() > 3) {
                                //         myChartA.current.config._config.data.datasets[0].backgroundColor =
                                //             'green';

                                //         myChartA.current.config._config.data.datasets[0].borderColor =
                                //             'green';
                                //         myChartA.current.config._config.data.datasets[0].pointRadius = 7;
                                //     } else {
                                //         myChartA.current.config._config.data.datasets[0].pointRadius = 2;
                                //         myChartA.current.config._config.data.datasets[0].backgroundColor =
                                //             'red';
                                //         myChartA.current.config._config.data.datasets[0].borderColor =
                                //             'red';
                                //     }
                                // },
                            },
                            limits: {
                                temp: { min: -10, max: 50 },
                                rain: { min: 0, max: 40 },
                            },
                        },
                        legend: {
                            labels: {
                                usePointStyle: true,
                                // This more specific font property overrides the global property
                                font: {
                                    size: 12,
                                },
                            },
                        },
                        title: {
                            display: false,
                        },
                    },
                    scales: {
                        temp: {
                            position: 'left',
                            min: yAxis.temp.min,
                            max: yAxis.temp.max,
                            grid: { display: false },
                        },
                        rain: {
                            position: 'right',
                            min: yAxis.rain.min,
                            max: yAxis.rain.max,
                            grid: { display: false },
                        },
                        x: {
                            grid: {
                                borderDash: [1, 2, 3],
                                tickLength: 0,
                            },
                            ticks: {
                                // color: 'red',
                                maxRotation: 0,
                            },
                        },
                    },
                    elements: {
                        point: {
                            borderWidth: 20,
                        },
                    },
                }}
            />
        );
    }, [time, rain, temp_max, temp_mean, temp_min, yAxis]);

    const humChart = useMemo(() => {
        return (
            <Line
                ref={myChartB}
                data={{
                    labels: time,
                    datasets: [
                        {
                            label: 'MAX HUM',
                            yAxisID: 'hum',
                            data: hum_max,
                            fill: false,
                            borderColor: '#E05963',
                            backgroundColor: '#E05963',
                            tension: 0.5,
                            pointRadius: 2,
                            borderWidth: 1,

                            datalabels: {
                                display: false,
                            },
                        },
                        {
                            label: 'MIN HUM',
                            yAxisID: 'hum',
                            data: hum_min,
                            fill: false,
                            borderColor: '#5C7BD3',
                            backgroundColor: '#5C7BD3',
                            tension: 0.5,
                            pointRadius: 2,
                            borderWidth: 1,
                            datalabels: {
                                display: false,
                            },
                        },
                        {
                            label: 'MEAN HUM',
                            yAxisID: 'hum',
                            data: hum_mean,
                            fill: false,
                            borderColor: '#F8AB0D',
                            backgroundColor: '#F8AB0D',
                            tension: 0.5,
                            pointRadius: 2,
                            borderWidth: 1,
                            datalabels: {
                                display: false,
                            },
                        },
                        {
                            label: 'LEAF WETNESS',
                            yAxisID: 'leaf',
                            type: 'bar',
                            data: leafWetness,
                            fill: true,
                            borderColor: '#e5e5e5',
                            backgroundColor: '#e5e5e5',
                            datalabels: {
                                display: function (context) {
                                    if (context.dataset.data.length > 90) {
                                        return false;
                                    }
                                    var index = context.dataIndex;
                                    var value = context.dataset.data[index];
                                    if (value === 0) {
                                        return false;
                                    }
                                },
                                color: '#000',
                                anchor: 'start',
                                align: 'end',
                                font: {
                                    size: '15',
                                    weight: '400',
                                    opacity: '0.8',
                                },
                            },
                        },
                    ],
                }}
                options={{
                    datasets: {
                        bar: {
                            barPercentage: 1.25,
                        },
                    },
                    responsive: true,
                    maintainAspectRatio: false,
                    scales: {
                        hum: {
                            position: 'left',
                            min: yAxis.hum.min,
                            max: yAxis.hum.max,
                            grid: { display: false },
                            animation: {
                                duration: 3000,
                            },
                        },
                        leaf: {
                            position: 'right',
                            min: yAxis.leaf.min,
                            max: yAxis.leaf.max,
                            grid: { display: false },
                        },
                        x: {
                            grid: {
                                borderDash: [1, 2, 3],
                                tickLength: 0,
                            },
                        },
                    },
                    plugins: {
                        zoom: {
                            zoom: {
                                wheel: {
                                    modifierKey: 'ctrl',
                                    enabled: true,
                                    speed: 0.2,
                                },
                                mode: 'x',
                                onZoom: function (chart) {
                                    // console.log(chart);
                                    // console.log(myChartA);
                                    // chart.current.config._config.data.datasets[0].backgroundColor =
                                    // 	'#ccc';
                                    // console.log(myChartA.current.getZoomLevel());
                                    // console.log(myChartB.current.getZoomLevel());
                                    // myChartA.current.zoom(myChartB.current.getZoomLevel(), false);
                                    // leftEnd =
                                    // 	myChartA.getDatasetMeta(0).dataset._scale.chart.scales[
                                    // 		'x-axis-0'
                                    // 	]._table[0].time;
                                    // rightEnd =
                                    // 	myChartA.getDatasetMeta(0).dataset._scale.chart.scales[
                                    // 		'x-axis-0'
                                    // 	]._table[1].time;
                                    // updateChart();
                                },
                            },
                            limits: {
                                hum: { min: 0, max: 120 },
                                leaf: { min: 0, max: 40 },
                            },
                        },
                        legend: {
                            display: true,
                            labels: {
                                usePointStyle: true,
                                // This more specific font property overrides the global property
                                font: {
                                    size: 12,
                                },
                            },
                        },
                        title: {
                            display: false,
                        },
                    },
                }}
            />
        );
    }, [time, hum_max, hum_mean, hum_min, leafWetness, yAxis]);

    const diseaseChart = useMemo(() => {
        return (
            <Line
                data={{
                    labels: time,
                    datasets: [
                        {
                            label: 'P.VITICOLA',
                            data: plasmopara,
                            fill: false,
                            borderColor: '#E05963',
                            backgroundColor: '#E05963',
                            tension: 0.1,
                            pointRadius: 2,
                            borderWidth: 1,
                        },
                        {
                            label: 'E.NECATOR',
                            data: uncinula,
                            fill: false,
                            borderColor: '#5C7BD3',
                            backgroundColor: '#5C7BD3',
                            tension: 0.1,
                            pointRadius: 2,
                            borderWidth: 1,
                        },
                        {
                            // label: 'B.CINEREA',
                            label: 'G.BIDWELLII',
                            data: blackRot,
                            fill: false,
                            borderColor: '#F8AB0D',
                            backgroundColor: '#F8AB0D',
                            tension: 0.1,
                            pointRadius: 2,
                            borderWidth: 1,
                        },
                        {
                            label: 'B.CINEREA',
                            data: greyRot,
                            fill: false,
                            borderColor: '#7a7a7a',
                            backgroundColor: '#7a7a7a',
                            tension: 0.1,
                            pointRadius: 2,
                            borderWidth: 1,
                        },
                    ],
                }}
                options={{
                    responsive: true,
                    maintainAspectRatio: false,
                    scales: {
                        y: {
                            position: 'left',
                            min: yAxis.disease.min,
                            max: yAxis.disease.max,
                            grid: { display: false },
                        },
                        x: {
                            grid: {
                                borderDash: [1, 2, 3],
                                tickLength: 0,
                            },
                        },
                    },
                    plugins: {
                        zoom: {
                            zoom: {
                                wheel: {
                                    modifierKey: 'ctrl',
                                    enabled: true,
                                    speed: 0.2,
                                },
                                mode: 'x',
                            },
                            limits: {
                                y: {
                                    min: yAxis.disease.min,
                                    max: yAxis.disease.max,
                                },
                            },
                        },
                        legend: {
                            display: true,
                            labels: {
                                usePointStyle: true,
                                font: {
                                    size: 12,
                                },
                            },
                        },
                        title: {
                            display: false,
                        },
                    },
                }}
            />
        );
    }, [time, blackRot, greyRot, plasmopara, uncinula, yAxis]);

    useEffect(() => {
        if (!zoneDaily) return;
        getDailyData(zoneDaily, defaultDailyValue, dateFromDaily, dateToDaily);
    }, [zoneDaily]);

    console.log('RERENDER DAILY');

    return (
        <ChartsContainer>
            <ChartWrapper>
                <ChartTitle>
                    <h3>Temperature</h3>
                </ChartTitle>
                {!zoneDaily ? <LoadingView /> : <LineWrapper>{tempChart}</LineWrapper>}
            </ChartWrapper>
            <ChartWrapper>
                <ChartTitle>
                    <h3>Humidity</h3>
                </ChartTitle>
                {!zoneDaily ? <LoadingView /> : <LineWrapper>{humChart}</LineWrapper>}
            </ChartWrapper>
            <ChartWrapper>
                <ChartTitle>
                    <h3>Disease</h3>
                </ChartTitle>
                {!zoneDaily ? <LoadingView /> : <LineWrapper>{diseaseChart}</LineWrapper>}
            </ChartWrapper>
        </ChartsContainer>
    );
};

export default DailyCharts;
