import React from 'react';
import styled from 'styled-components/macro';

import userProfile from '../../assets/img/userProfile.svg';
import downArrow from '../../assets/img/downArrow.svg';

//TODO: get actual user data
const UserProfile = ({ showName, onPress, innerRef }) => {
    return (
        <UserProfileContainer onClick={onPress} ref={innerRef}>
            {showName && <h3>Name</h3>}
            <img src={userProfile} alt='user profile picture' />
            <span>
                <img src={downArrow} alt='' />
            </span>
        </UserProfileContainer>
    );
};

export default UserProfile;

const UserProfileContainer = styled.div`
    all: unset;
    display: flex;
    align-items: center;
    gap: 1em;

    cursor: pointer;
    user-select: none;
    font-size: inherit;
    color: inherit;

    & > img {
        width: 4em;
        height: 4em;
    }
    & > h3 {
        font-size: 1.25em;
    }
    & > span > img {
        width: 1em;
        height: 1em;
    }
`;
