import React from 'react';
import styled from 'styled-components/macro';
import { FocusStyle } from '../styled/primitives.css';

const Container = styled.label`
    display: flex;
    align-items: center;
    gap: ${props => props.label && '0.5rem'};
    flex-direction: ${({ labelPos }) => (labelPos == 'left' ? 'row-reverse' : 'row')};
    user-select: none;
    & > input {
        width: 1px;
        height: 1px;
        opacity: 0;
    }
    & > span {
        display: inline-block;
        width: 1.1em;
        height: 1.1em;
        border-radius: 50%;
        background-color: var(--white);

        position: relative;
    }
    & > input:focus-visible + span {
        --focus-color: var(--action-color);
        --focus-width: 2px;
        ${FocusStyle};
    }
    & > input:checked + span:before {
        content: '';
        position: absolute;
        top: 3px;
        bottom: 3px;
        left: 3px;
        right: 3px;
        background-color: var(--action-color);
        border-radius: 50%;
    }
`;
const CheckBox = React.forwardRef(({ label, labelPos, className, ...register }, ref) => {
    return (
        <Container {...{ label, labelPos }}>
            <input type='checkbox' {...register} ref={ref} />
            <span className={className}></span>
            {label}
        </Container>
    );
});

export default styled(CheckBox)``;
