import React from 'react';
import styled from 'styled-components/macro';
import { FlexMiddle, FocusableStyle } from '../styled/primitives.css';
import {
    IoArrowBackCircleOutline as ArrowLeftIcon,
    IoArrowForwardCircleOutline as ArrowRightIcon,
    IoCheckmarkCircleOutline as CheckMarkIcon,
    IoCloseCircleOutline as CloseIcon,
} from 'react-icons/io5';
import { RiDeleteBinLine as DeleteIcon } from 'react-icons/ri';
import { AiOutlinePlusCircle as PlusIcon } from 'react-icons/ai';

export const IconButtonRaw = ({
    icon: Icon,
    label,
    labelPos = 'bottom',
    size,
    color,
    hoverColor,
    onClick,
    type,
    disabled,
    ...rest
}) => {
    return (
        <Container
            onClick={onClick}
            label={label}
            labelPos={labelPos}
            type={type}
            color={color}
            hoverColor={hoverColor}
            disabled={disabled}
            {...rest}
        >
            {Icon && <Icon color={disabled ? 'gray': null} size={size || '2.5rem'} />}
            {label && <span style={disabled ? { color: 'gray' } : {}}>{label}</span>}
        </Container>
    );
};

//prettier-ignore
const 
    _DeleteButton = props => <IconButtonRaw icon={DeleteIcon} {...props} hoverColor='var(--danger-red)' />,
    _PlusButton = props => <IconButtonRaw icon={PlusIcon} {...props} />,
    _OkButton = props => <IconButtonRaw icon={CheckMarkIcon} {...props} />,
    _CloseButton = props => <IconButtonRaw icon={CloseIcon} {...props} />,
    _ArrowLeftButton = props => <IconButtonRaw icon={ArrowLeftIcon} {...props} />,
    _ArrowRightButton = props => <IconButtonRaw icon={ArrowRightIcon} {...props} />;
//prettier-ignore
const 
    IconButton = styled(IconButtonRaw)``,
    DeleteButton = styled(_DeleteButton)``,
    PlusButton = styled(_PlusButton)``,
    OkButton = styled(_OkButton)``,
    CloseButton = styled(_CloseButton)``,
    ArrowLeftButton = styled(_ArrowLeftButton)``,
    ArrowRightButton = styled(_ArrowRightButton)``;

export {
    IconButton as default,
    DeleteButton,
    PlusButton,
    OkButton,
    CloseButton,
    ArrowLeftButton,
    ArrowRightButton,
};

const Container = styled.button.attrs(({ type, as }) => ({
    as: as || 'button',
    type: type || 'button',
}))`
    all: unset;
    ${FlexMiddle}

    font-size: 1.2em;
    border-radius: 0.3rem;
    ${({ as }) => as === 'button' && 'cursor: pointer;'};
    user-select: none;
    pointer-events: auto;
    transition: filter 150ms, transform 150ms;

    color: ${({ color }) => color || `var(--action-color)`};
    &:hover {
        color: ${({ hoverColor, color }) => hoverColor || color || 'var(--action-color)'};
    }
    flex-direction: ${({ labelPos }) => {
        switch (labelPos) {
            case 'top':
                return 'column-reverse';
            case 'bottom':
                return 'column';
            case 'left':
                return 'row-reverse; gap: .5rem;';
            case 'right':
                return 'row; gap: .5rem;';
            default:
                return 'column';
        }
    }};

    & > span {
        color: var(--white);
        display: block;
        width: max-content;
    }

    &:hover {
        ${({ as }) => as === 'button' && 'transform: translateY(-3px);'};
        filter: drop-shadow(2px 3px 2px rgb(0 0 0 / 0.2));
    }

    &:focus-visible {
        filter: drop-shadow(2px 3px 2px rgb(0 0 0 / 0.2));
        --focus-color: var(--action-color);
        --focus-width: 2px;
        ${FocusableStyle};
    }
`;
