import React, { useMemo, useRef } from 'react';
import { ReactTabulator } from 'react-tabulator';
import { useDateCtx } from '../../../context/DateContext';
import { useFieldCtx } from '../../../context/FieldContext';
import { useZoneCtx } from '../../../context/ZoneContext';
import { LoadingView } from '../../Custom/LoadingView';
import { TableWrapper } from './styledTable.css';


const HourlyTable = () => {
	const tableRef = useRef(false);
	const { fieldId } = useFieldCtx();
	const { zoneHourly } = useZoneCtx();
	const { defaultHourlyValue, dateFromHourly, dateToHourly, hourlyRange } =
		useDateCtx();


	function decimalFormatter (cell, formatterParams, orRendered) {
		let value = cell.getValue()
		if (Math.floor(value) === value) return value
		if (`${value}`.split('.').length === 1) return value
		let [int, dec] = `${value}`.split('.')
		return `${int}.${dec.charAt(0)}`
	}

	const tableData = () => {
		if (!zoneHourly) return null;
		console.log('FIELDDATA', zoneHourly);
		console.log(fieldId);
		// console.log(object);

		let data = [];
		for (let i = 0; i < zoneHourly.data[fieldId].length; i++) {
			let date = new Date(zoneHourly.data[fieldId][i][2] * 1000);
			let year = date.getFullYear();
			let month = date.getMonth() + 1;
			let day = date.getDate();
			let hour = ('0' + date.getHours()).slice(-2);
			let minutes = ('0' + date.getMinutes()).slice(-2);
			let formattedDate = `${day}/${month}/${year} ${hour}:${minutes}`;
			// zoneHourly.data[fieldId][i][0] = formattedDate;
			let row = {time: formattedDate};
			for (let j = 1; j < zoneHourly.cols.length; j++) {
				let string = zoneHourly.cols[j];
				row[string] = zoneHourly.data[fieldId][i][j];
			}
			data.push(row);
		}

		if (!hourlyRange) {
			console.log(defaultHourlyValue);
			return data.slice(data.length - defaultHourlyValue);
		} else {
			data = data.filter(el => {
				return (
					el['data_ts'] >= dateFromHourly.getTime() / 1000 &&
					el['data_ts'] <= parseInt(dateToHourly.getTime() / 1000)
				);
			});

			return data;
		}
	};

	const createColumns = () => {
		if (!zoneHourly) return null;
		console.log('FIELD DATA COLUIMNS:', zoneHourly);
		let columns = [];
		let arrayOfRows = zoneHourly.cols;
		console.log(arrayOfRows);

		for (let i = 0; i < arrayOfRows.length; i++) {
			if (arrayOfRows[i] === 'zone_id') {
				continue;
			} else if (arrayOfRows[i] === 'field_id') {
				columns.push({
					headerSort: false,
					title: 'Time',
					field: 'time',
					hozAlign: 'center',
					headerHozAlign: 'center',
				});
			} else {
				columns.push({
					headerSort: false,
					title: arrayOfRows[i],
					field: arrayOfRows[i],
					hozAlign: 'center',
					headerHozAlign: 'center',
					formatter: decimalFormatter,
				});
			}
	
		}

		console.log(columns);
		// return columns.slice(1, columns.length);
		return columns;
	};

	const setOptions = () => {
		if (!zoneHourly) return null;
		const options = {
			tableBuilt: function () {
				let footerEl = document.querySelector('.tabulator-footer');

				let customBtn = document.createElement('button');
				customBtn.innerHTML = 'Download XLSX';
				customBtn.style.background = '#F8AB0D';
				customBtn.style.color = '#fff';
				customBtn.style.width = '10rem';
				customBtn.style.padding = '.5rem';
				customBtn.style.border = 'none';
				customBtn.style.borderRadius = '0.6rem';
				customBtn.style.textTransform = 'uppercase';
				customBtn.style.fontWeight = '600';
				customBtn.style.cursor = 'pointer';
				customBtn.onclick = function () {
					tableRef.current.table.download('xlsx', `${new Date()}.xlsx`);
				};
				footerEl.appendChild(customBtn);
			},
			layout: 'fitData',
			height: '100%',
			pagination: 'local',
			paginationSize: 20,
			paginationSizeSelector: [20, 50, 100],
			downloadDataFormatter: data => data,
			downloadReady: (fileContents, blob) => blob
		};
		return options;
	};

	const hourlyTable = useMemo(() => {
		return (
			<>
				<ReactTabulator
					ref={tableRef}
					options={setOptions()}
					columns={createColumns()}
					data={tableData()}
				/>
			</>
		);
	}, [zoneHourly]);

	return !zoneHourly ? ( <LoadingView /> ) : (<TableWrapper>{hourlyTable}</TableWrapper>);
};

export default HourlyTable;
