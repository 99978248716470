import dayjs from "dayjs";

/**
 * 
 * @param {Date | String} date Date object or string_ts from sDaly or sHrly
 * @param {string | string[]} format Dayjs format string or array of format strings
 * @returns {string | string[]} Date formated by dayjs or array of such
 */
export function formatDate(date, format) {
    if (Array.isArray(format)){
        return format.map(part => formatDate(date, part))
    }
    return dayjs(date).format(format)
}