import React from 'react';
import styled from 'styled-components/macro';
import { useNavigate, Link } from 'react-router-dom';

import { useAuthCtx } from '../../context/AuthContext';
import { useZoneCtx } from '../../context/ZoneContext';
import { useFieldCtx } from '../../context/FieldContext';
import { useUserCtx } from '../../context/UserContext';

import { FlexMiddle } from '../styled/primitives.css';
import {
    NarrowForm,
    FormTitle,
    SubmitButton,
    FormText,
    WinessenseLogo,
} from './styledEntry.css';
import TextInput from '../Custom/TextInput';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

const Container = styled.div`
    width: 100%;
    height: 100%;
    ${FlexMiddle}
`;

const schema = yup.object().shape({
    email: yup.string().email('Email address must be valid'),
    password: yup.string().required('Required'),
});

export default function Login() {
    const navigate = useNavigate();

    const {
        register,
        handleSubmit,
        formState: { errors },
        setError,
    } = useForm({
        mode: 'onBlur',
        resolver: yupResolver(schema),
    });

    const { login } = useAuthCtx();
    const { setZoneIdFunc } = useZoneCtx();
    const { setFieldIdFunc, firstFieldOfZone } = useFieldCtx();
    const { setUserConfig } = useUserCtx();

    async function onSubmit(data, e) {
        login(data.email, data.password)
            .then(uConf => {
                if (uConf.response_code === 2) throw 'Invalid PIN';
                return uConf;
            })
            .then(userConfig => {
                if (!userConfig || !userConfig.dflt_zone || !userConfig.fields) return;
                const { dflt_zone, fields } = userConfig;
                setUserConfig(userConfig);
                setZoneIdFunc(dflt_zone);
                setFieldIdFunc(fields[dflt_zone][0]);
                // navigate(`/${dflt_zone}/${firstFieldOfZone(dflt_zone)}/dashboard`);
            })
            .catch(error => {
                if (error === 'Invalid PIN') setError('password', { message: error });
                else setError('password', { message: error.message });
            });
    }

    return (
        <Container>
            <WinessenseLogo width='8rem' />
            <FormTitle>Log in</FormTitle>

            <NarrowForm onSubmit={handleSubmit(onSubmit)}>
                <TextInput
                    {...register('email')} //
                    type='text'
                    placeholder='Email'
                    errors={errors}
                />
                <TextInput
                    {...register('password')}
                    type='password'
                    placeholder='Password'
                    errors={errors}
                />
                <SubmitButton>Login</SubmitButton>
            </NarrowForm>

            <FormText>
                <Link to='/landing/forgot_pass'>Forgot password?</Link>
                <br />
                Don't have an account? <Link to='/landing/signup'>Sign up!</Link>
            </FormText>
        </Container>
    );
}
