import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import Loader from 'react-loader-spinner';

import styled from 'styled-components/macro';
import { FlexMiddle } from '../styled/primitives.css';

const LoadingWrapper = styled.div`
    flex-grow: 1;
    width: 100%;
    height: 100%;
    ${FlexMiddle}
`;
export const Spinner = styled(Loader).attrs({
    color: '#3e644d',
    height: 40,
    width: 40,
})``;
export const Loading = styled(Loader).attrs({
    type: 'TailSpin',
    color: '#3e644d',
    height: 40,
    width: 40,
})``;

export const LoadingView = () => {
    return (
        <LoadingWrapper>
            <Loading />
        </LoadingWrapper>
    );
};
