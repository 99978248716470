import React from 'react';
import styled from 'styled-components/macro';

const CardWrapper = styled.div`
    background: var(--light-gray);
    display: grid;
    place-items: center;
    flex: 100% 0 1;
    height: 90%;
    padding: 0 0.5rem;
    border-radius: 2rem;
    box-shadow: 1px 2px 3px 0px rgba(var(--RGB-gray), 0.5);
`;

const DataWrapper = styled.div`
    height: 70%;
    white-space: pre-line;
    text-align: center;
    img {
        width: 2.5rem;
        height: 2.5rem;
    }
`;

const Img = styled.div`
    height: 50%;
    color: var(--green);
`;

const Card = ({ item: { imgName, value, textValue, imgText } }) => {
    return (
        <CardWrapper>
            <DataWrapper>
                <Img>
                    {imgName && <img src={imgName} />}
                    {imgText && <h2>{imgText}</h2>}
                </Img>
                <h3> {value} </h3>
                <p>{textValue}</p>
            </DataWrapper>
        </CardWrapper>
    );
};

export default Card;
