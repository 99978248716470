import React, { useState, useEffect, useMemo, useReducer } from 'react';

import {
    Chart as ChartJS,
    LineElement,
    BarElement,
    LinearScale,
    CategoryScale,
    PointElement,
    Filler,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import zoomPlugin from 'chartjs-plugin-zoom';
import ChartDataLabels from 'chartjs-plugin-datalabels';

import { formatDate } from '../../../utils/formatDate';

import { useZoneCtx } from '../../../context/ZoneContext';
import { useFieldCtx } from '../../../context/FieldContext';
import { useDateCtx } from '../../../context/DateContext';

import { LineWrapper, ChartWrapper, ChartsContainer, ChartTitle } from './styledCharts.css';
import { LoadingView } from '../../Custom/LoadingView';

ChartJS.register(
    zoomPlugin,
    ChartDataLabels,
    LineElement,
    BarElement,
    LinearScale,
    CategoryScale,
    PointElement,
    Filler
);

//TODO: Check limits options
const HourlyCharts = () => {
    const { fieldId } = useFieldCtx();
    const { zoneHourly } = useZoneCtx();
    const { dateFromHourly, dateToHourly, defaultHourlyValue } = useDateCtx();

    const [time, setTime] = useState([]);
    const timeFormat = ['HH:mm', 'DD/MM'];

    const [temp, setTemp] = useState();
    const [hum, setHum] = useState();
    const [rain, setRain] = useState();

    const [leafWetness, setLeafWetness] = useState();
    const [plasmopara, setPlasmopara] = useState([]);
    const [uncinula, setUncinula] = useState([]);
    const [greyRot, setGreyRot] = useState([]);
    const [blackRot, setBlackRot] = useState([]);

    const defaultYAxis = {
        temp: { min: -10, max: 40 }, // 10%
        rain: { min: 0, max: 100 }, // 10%
        hum: { min: 0, max: 110 },
        leaf: { min: 0, max: 1.2 },
        disease: { min: 0, max: 100 },
    };
    const [yAxis, dispatchAxis] = useReducer((state, action) => {
        if (action.type in state) return { ...state, [action.type]: action.payload };
        else return state;
    }, defaultYAxis);

    const getHourlyData = (data, period, rangeFrom, rangeTo) => {
        console.log('getHourlyData');
        if (!(data && period && rangeFrom && rangeTo)) return;

        let arrays = [];
        let ts_helpData = [];
        console.log('PODACI: ', data);
        for (let i = 0; i < data.cols.length; i++) {
            arrays[data.cols[i]] = [];
            for (let j = 0; j < data.data[fieldId].length; j++) {
                arrays[data.cols[i]].push(data.data[fieldId][j][i]);
            }
        }
        console.log('ARRAYS: ', arrays);
        for (let timestamp of arrays['string_ts']) {
            ts_helpData.push(formatDate(timestamp, timeFormat));
        }

        setTime(ts_helpData);

        setTemp(arrays['tempr']);
        setHum(arrays['humdt']);
        setRain(arrays['rain']);
        setLeafWetness(arrays['leaf_w']);
        setPlasmopara(arrays['pvit_severity']);
        setUncinula(arrays['enec_severity']);
        setGreyRot(arrays['bcin_severity']);
        setBlackRot(arrays['gbdw_severity']);

        dispatchAxis({
            type: 'temp',
            payload: {
                min: (Math.min(...arrays['tempr']) - 5) >> 0,
                max: (Math.max(...arrays['tempr']) + 5) >> 0,
            },
        });
        dispatchAxis({
            type: 'rain',
            payload: {
                min: 0,
                max: (Math.max(...arrays['rain']) + 10) >> 0,
            },
        });
    };

    // TODO: Unify chart options
    const tempChart = useMemo(() => {
        return (
            <Line
                data={{
                    labels: time,
                    datasets: [
                        {
                            label: 'TEMPERATURE',
                            yAxisID: 'temp',
                            data: temp,
                            fill: false,
                            borderColor: '#E05963',
                            backgroundColor: '#E05963',
                            tension: time?.length > 100 ? 0 : 0.5,
                            pointRadius: time?.length > 100 ? 1 : 2,
                            borderWidth: 1,
                        },
                        {
                            type: 'bar',
                            label: 'RAIN',
                            yAxisID: 'rain',
                            data: rain,
                            fill: false,
                            borderColor: '#e5e5e5',
                            backgroundColor: '#e5e5e5',
                        },
                    ],
                }}
                options={{
                    datasets: {
                        bar: { barPercentage: 1.25 },
                    },
                    responsive: true,
                    maintainAspectRatio: false,
                    plugins: {
                        datalabels: {
                            align: 'end',
                            anchor: 'end',
                            display: function (context) {
                                return context.dataIndex % 2; // display labels with an odd index
                            },
                            backgroundColor: function (context) {
                                return context.dataset.backgroundColor;
                            },
                            borderRadius: 4,
                            color: 'red',
                            font: { weight: 'bold' },
                            formatter: Math.round,
                            padding: 6,
                        },
                        zoom: {
                            zoom: {
                                wheel: {
                                    modifierKey: 'ctrl',
                                    enabled: true,
                                    speed: 0.2,
                                },
                                mode: 'x',
                            },
                        },
                        legend: {
                            labels: {
                                usePointStyle: true,
                                font: { size: 12 },
                            },
                        },
                        title: { display: false },
                    },
                    scales: {
                        temp: {
                            position: 'left',
                            min: yAxis.temp.min,
                            max: yAxis.temp.max,
                            grid: { display: false },
                        },
                        rain: {
                            position: 'right',
                            min: yAxis.rain.min,
                            max: yAxis.rain.max,
                            grid: { display: false },
                        },
                        x: {
                            grid: {
                                borderDash: [1, 2, 3],
                                tickLength: 0,
                            },
                        },
                    },
                }}
            />
        );
    }, [time, rain, temp, yAxis]);

    const humChart = useMemo(() => {
        return (
            <Line
                data={{
                    labels: time,
                    datasets: [
                        {
                            label: 'HUMIDITY',
                            yAxisID: 'hum',
                            data: hum,
                            fill: false,
                            borderColor: '#E05963',
                            backgroundColor: '#E05963',
                            tension: time?.length > 100 ? 0 : 0.5,
                            pointRadius: time?.length > 100 ? 1 : 2,
                            borderWidth: 1,
                        },
                        {
                            type: 'bar',
                            label: 'LEAF_WETNESS',
                            yAxisID: 'leaf',
                            data: leafWetness,
                            fill: false,
                            borderColor: '#e5e5e5',
                            backgroundColor: '#e5e5e5',
                            tension: time?.length > 100 ? 0 : 0.5,
                        },
                    ],
                }}
                options={{
                    datasets: {
                        bar: { barPercentage: 1.25 },
                    },
                    responsive: true,
                    maintainAspectRatio: false,
                    plugins: {
                        zoom: {
                            zoom: {
                                wheel: {
                                    modifierKey: 'ctrl',
                                    enabled: true,
                                    speed: 0.2,
                                },
                                mode: 'x',
                            },
                            limits: {
                                hum: { min: 0, max: 120 },
                                leaf: { min: 0, max: 1.2 },
                            },
                        },
                        legend: {
                            labels: {
                                usePointStyle: true,
                                font: { size: 12 },
                            },
                        },
                        title: { display: false },
                    },
                    scales: {
                        hum: {
                            position: 'left',
                            min: yAxis.hum.min,
                            max: yAxis.hum.max,
                            grid: { display: false },
                        },
                        leaf: {
                            position: 'right',
                            min: yAxis.leaf.min,
                            max: yAxis.leaf.max,
                            grid: { display: false },
                        },
                        x: {
                            grid: {
                                borderDash: [1, 2, 3],
                                tickLength: 0,
                            },
                        },
                    },
                }}
            />
        );
    }, [time, hum, leafWetness, yAxis]);

    const diseaseChart = useMemo(() => {
        return (
            <Line
                data={{
                    labels: time,
                    datasets: [
                        {
                            label: 'P.VITICOLA',
                            data: plasmopara,
                            fill: false,
                            borderColor: '#E05963',
                            backgroundColor: '#E05963',
                            tension: time?.length > 100 ? 0 : 0.5,
                            pointRadius: time?.length > 100 ? 1 : 2,
                            borderWidth: 1,
                        },
                        {
                            label: 'E.NECATOR',
                            data: uncinula,
                            fill: false,
                            borderColor: '#5C7BD3',
                            backgroundColor: '#5C7BD3',
                            tension: time?.length > 100 ? 0 : 0.5,
                            pointRadius: time?.length > 100 ? 1 : 2,
                            borderWidth: 1.5,
                        },
                        {
                            label: 'G.BIDWELLII',
                            data: blackRot,
                            fill: false,
                            borderColor: '#F8AB0D',
                            backgroundColor: '#F8AB0D',
                            tension: time?.length > 100 ? 0 : 0.5,
                            pointRadius: time?.length > 100 ? 1 : 2,
                            borderWidth: 1.5,
                        },
                        {
                            label: 'B.CINEREA',
                            data: greyRot,
                            fill: false,
                            borderColor: '#7a7a7a',
                            backgroundColor: '#7a7a7a',
                            tension: time?.length > 100 ? 0 : 0.5,
                            pointRadius: time?.length > 100 ? 1 : 2,
                            borderWidth: 1.5,
                        },
                    ],
                }}
                options={{
                    responsive: true,
                    maintainAspectRatio: false,
                    plugins: {
                        zoom: {
                            zoom: {
                                wheel: {
                                    modifierKey: 'ctrl',
                                    enabled: true,
                                    speed: 0.2,
                                },
                                mode: 'x',
                            },
                            limits: {
                                y: { min: 0, max: 5 },
                            },
                        },
                        legend: {
                            labels: {
                                usePointStyle: true,
                                font: { size: 12 },
                            },
                        },
                        title: { display: false },
                    },
                    scales: {
                        y: {
                            position: 'left',
                            min: yAxis.disease.min,
                            max: yAxis.disease.max,
                            grid: { display: false },
                        },
                        x: {
                            grid: {
                                borderDash: [1, 2, 3],
                                tickLength: 0,
                            },
                        },
                    },
                }}
            />
        );
    }, [time, blackRot, greyRot, plasmopara, uncinula, yAxis]);

    useEffect(() => {
        console.log({ zoneHourly });
        if (!zoneHourly) return;
        getHourlyData(zoneHourly, defaultHourlyValue, dateFromHourly, dateToHourly);
    }, [zoneHourly]);

    console.log('RERENDER HOURLY');
    return (
        <ChartsContainer>
            <ChartWrapper>
                <ChartTitle>
                    <h3>Temperature/Rain</h3>
                </ChartTitle>
                {!zoneHourly ? <LoadingView /> : <LineWrapper>{tempChart}</LineWrapper>}
            </ChartWrapper>
            <ChartWrapper>
                <ChartTitle>
                    <h3>Humidity/Leaf Wetness</h3>
                </ChartTitle>
                {!zoneHourly ? <LoadingView /> : <LineWrapper>{humChart}</LineWrapper>}
            </ChartWrapper>
            <ChartWrapper>
                <ChartTitle>
                    <h3>Disease</h3>
                </ChartTitle>
                {!zoneHourly ? <LoadingView /> : <LineWrapper>{diseaseChart}</LineWrapper>}
            </ChartWrapper>
        </ChartsContainer>
    );
};

export default HourlyCharts;
