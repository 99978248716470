import React, { useEffect } from 'react';
import { useState } from 'react';
import { IoFilterSharp } from 'react-icons/io5';
import styled, { css } from 'styled-components/macro';
import useOnClickOutside from '../../hooks/useOnClickOutside';
import { Loading } from '../Custom/LoadingView';
import Toggle from '../Historical/Toggle';
import { SubmitButton } from '../Landing/styledEntry.css';
import { getName, getSvg } from '../../assets/assetData';

const Container = styled.div`
    display: contents;
    & ${Loading} {
        position: absolute;
        left: 0;
        top: 0;
    }
`;

export const controlBorder = css`
    border: 2px solid rgba(0, 0, 0, 0.2);
    background-clip: padding-box;
`;

const ToggleButton = styled.div`
    position: absolute;
    top: 0;
    left: 0;

    height: 2.5rem;
    width: 2.5rem;
    border-radius: 50%;
    background: ${({ active, background, activeBackground }) =>
        active ? activeBackground : background};
    color: ${({ active, color, activeColor }) => (active ? activeColor : color)};
    ${controlBorder};
    display: grid;
    place-items: center;
    cursor: pointer;
`;

const FilterMenu = styled.div`
    position: absolute;
    top: 50px;
    left: 0;
    width: 17rem;
    background: var(--white);
    border-radius: 0.5rem;
    ${controlBorder};
    padding: 0.5rem 0.8rem;
    & > ${Toggle} {
        font-size: 0.8rem;
        margin-bottom: 1rem;
    }
    & > ${SubmitButton} {
        padding: 0.5rem;
        font-size: 0.8rem;
        width: 100%;
    }
    & li * {
        cursor: pointer;
    }
`;

const FilterOptions = styled.ul`
    list-style: none;
    margin-top: 0.2rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    li {
        display: flex;
        gap: 0.5rem;

        label {
            display: flex;
            gap: 1rem;
        }
    }
`;

const Img = styled.div`
    width: 3rem;
    display: flex;
    justify-content: left;
    img {
        height: 1.2rem;
    }
`;

const filterOptions = {
    day: [
        'temp_min',
        'temp_mean',
        'temp_max',
        'hum_min',
        'hum_mean',
        'hum_max',
        'rain',
        'w_speed',
        'leaf_w',
        'p_viticola_sev',
        'e_necator_sev',
        'b_cinerea_sev',
        'g_bidwellii_sev',
    ],
    hour: [
        'tempr',
        'humdt',
        'rain',
        'w_speed',
        'leaf_w',
        'p_viticola_sev',
        'e_necator_sev',
        'b_cinerea_sev',
        'g_bidwellii_sev',
    ],
};

const HeatMapPicker = ({ heatMap, onChange }) => {
    function getDefaultValue() {
        return { column: heatMap.column, period: heatMap.period ?? 'day' };
    }

    const [picked, setPicked] = useState(getDefaultValue());
    const [isOpen, setIsOpen] = useState(false);

    const clickOutsideRef = useOnClickOutside(() => {
        if (!isOpen) return;
        setIsOpen(false);
        setPicked(getDefaultValue());
    });

    const isApplied = !!heatMap.column;

    const isValid =
        !!picked.column && (picked.period !== heatMap.period || picked.column !== heatMap.column);

    const isChecked = option => {
        return !picked.column
            ? option === heatMap.column && picked.period === heatMap.period
            : option === picked.column;
    };

    const handlePeriodToggle = daily => {
        setPicked({
            column: undefined,
            period: daily ? 'day' : 'hour',
        });
    };

    const handleFilterChange = e => {
        setPicked(old => ({
            ...old,
            column: e.target.value,
        }));
    };

    const handleSubmit = e => {
        e.stopPropagation();
        onChange(picked);
        setIsOpen(false);
    };

    useEffect(() => {
        setPicked(getDefaultValue());
    }, [heatMap]);

    const FilterOption = ({ option, index }) => (
        <li>
            <input
                type='radio'
                name='filterOptionsGroup'
                value={option}
                defaultChecked={isChecked(option)}
                id={`column${index + 1}`}
            />
            <label htmlFor={`column${index + 1}`}>
                <Img>
                    <img src={getSvg(option)} alt={getName(option)} />
                </Img>
                {getName(option)}
            </label>
        </li>
    );

    return (
        <Container>
            <ToggleButton
                active={isOpen || isApplied}
                color='black'
                background='white'
                activeColor='white'
                activeBackground='var(--orange)'
                onClick={() => setIsOpen(state => !state)}
                ref={clickOutsideRef}
            >
                <IoFilterSharp size='1.6rem' />
            </ToggleButton>

            {isApplied && !heatMap.ready && <Loading />}

            {isOpen && (
                <FilterMenu ref={clickOutsideRef}>
                    <Toggle
                        firstActive={picked.period === 'day'}
                        onToggle={handlePeriodToggle}
                        labels={['Daily', 'Hourly']}
                    />

                    <FilterOptions onChange={handleFilterChange}>
                        {filterOptions[picked.period]?.map((option, index) => (
                            <FilterOption {...{ option, index }} key={`${picked.period}${index}`} />
                        ))}
                    </FilterOptions>

                    <SubmitButton type='button' disabled={!isValid} onClick={handleSubmit}>
                        Apply
                    </SubmitButton>
                </FilterMenu>
            )}
        </Container>
    );
};

export default styled(HeatMapPicker)``;
