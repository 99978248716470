import React from 'react';
import ReactDOM from 'react-dom';
import './i18n/config';

import { AuthProvider } from './context/AuthContext';
import { DateProvider } from './context/DateContext';
import { FieldProvider } from './context/FieldContext';
import { ZoneProvider } from './context/ZoneContext';
import { UserProvider } from './context/UserContext';

import App from './App';
import GlobalStyle from './components/styled/GlobalStyles.css';

ReactDOM.render(
    <React.StrictMode>
        <AuthProvider>
            <UserProvider>
                <DateProvider>
                    <ZoneProvider>
                        <FieldProvider>
                            <GlobalStyle />
                            <App />
                        </FieldProvider>
                    </ZoneProvider>
                </DateProvider>
            </UserProvider>
        </AuthProvider>
    </React.StrictMode>,
    document.getElementById('root')
);
