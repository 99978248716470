import styled, { css } from 'styled-components/macro';

export const FocusStyle = css`
    outline: var(--focus-width) solid
        ${({ error }) => (error ? 'var(--red)' : 'var(--focus-color)')};
`;
export const FocusableStyle = css`
    &:focus-visible {
        ${FocusStyle};
    }
`;

export const InputStyle = css`
    font-size: inherit;
    color: inherit;
    padding: .8rem 1rem .4rem 1rem;
    border: 1px solid hsl(0, 0%, 80%);
    border-radius: 1rem;
    color: var(--text-color);
    &::placeholder {
        color: var(--placeholder-color);
    }

    --focus-width: 1px;
    ${FocusableStyle};
`;

export const Flex = styled.div`
    display: flex;
    ${({ direction }) => direction && `flex-direction: ${direction};`};
    ${({ gap }) => gap && `gap: ${gap};`};
    ${({ justify }) => justify && `justify-content: ${justify};`};
    ${({ align }) => align && `align-items: ${align};`};
`;

export const FlexMiddle = css`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`;

export const FlexCenter = css`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
`;

export const WidthProp = ({ initial }) => css`
    ${({ width }) => (width ? `width:${width};` : initial ? `width:${initial};` : '')}
`;
