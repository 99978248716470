import React, { useMemo, useRef } from 'react';
import { ReactTabulator } from 'react-tabulator';
import { useDateCtx } from '../../../context/DateContext';
import { useFieldCtx } from '../../../context/FieldContext';
import { useZoneCtx } from '../../../context/ZoneContext';
import { LoadingView } from '../../Custom/LoadingView';
import { TableWrapper } from './styledTable.css';


const DailyTable = () => {
	const tableRef = useRef();
	const { fieldId } = useFieldCtx();
	const { dateFromDaily, dateToDaily, dailyRange, defaultDailyValue } = useDateCtx();
	const { zoneDaily } = useZoneCtx();


	function decimalFormatter (cell, formatterParams, orRendered) {
		let value = cell.getValue()
		if (Math.floor(value) === value) return value
		if (`${value}`.split('.').length === 1) return value
		let [int, dec] = `${value}`.split('.')
		return `${int}.${dec.charAt(0)}`
	}
	console.log(dateFromDaily);
	const tableData = () => {
		if (!zoneDaily) return null;
		console.log('FIELDDATA', zoneDaily);
		console.log(fieldId);

		let data = [];
		for (let i = 0; i < zoneDaily.data[fieldId].length; i++) {
			let date = new Date(zoneDaily.data[fieldId][i][1] * 1000);
			let year = date.getFullYear();
			let month = date.getMonth() + 1;
			let day = date.getDate();
			let hour = ('0' + date.getHours()).slice(-2);
			let minutes = ('0' + date.getMinutes()).slice(-2);
			let formattedDate = `${day}/${month}/${year} ${hour}:${minutes}`;
			// zoneDaily.data[fieldId][i][0] = formattedDate;
			let row = {date: formattedDate};
			for (let j = 1; j < zoneDaily.cols.length; j++) {
				let string = zoneDaily.cols[j];
				row[string] = zoneDaily.data[fieldId][i][j];
			}
			data.push(row);
		}

		if (!dailyRange) {
			console.log(defaultDailyValue);
			return data.slice(data.length - defaultDailyValue);
		} else {
			data = data.filter(el => {
				console.log(el);
				return (
					el['data_ts'] >
						dateFromDaily.getTime() / 1000 - dateFromDaily.getHours() * 3600 &&
					el['data_ts'] <
						parseInt(
							dateToDaily.getTime() / 1000 +
								dateToDaily.getHours() * 3600 +
								7200
						)
				);
			});

			return data;
		}
	};

	const createColumns = () => {
		if (!zoneDaily) return null;
		console.log('FIELD DATA COLUIMNS:', zoneDaily);
		let columns = [];
		let arrayOfRows = zoneDaily.cols;

		let tempArray = [];
		let humArray = [];
		for (let i = 0; i < arrayOfRows.length; i++) {
			// if (arrayOfRows[i] === 'field_id') continue;
			if (arrayOfRows[i] === 'field_id') {
				columns.push({
					headerSort: false,
					title: 'Date',
					field: 'date',
					hozAlign: 'center',
					headerHozAlign: 'center',
				});
			} else if (arrayOfRows[i] === 'zone_id') {
				continue;
			} else if (arrayOfRows[i].includes('temp')) {
				tempArray.push({
					headerSort: false,
					title: arrayOfRows[i],
					field: arrayOfRows[i],
					hozAlign: 'center',
					headerHozAlign: 'center',
					formatter: decimalFormatter,
				});
				if (arrayOfRows[i] === 'temp_mean') {
					columns.push({
						// headerContextMenu: headerMenu,
						title: 'Temperature',
						headerHozAlign: 'center',
						headerSort: false,
						columns: [...tempArray],
						formatter: decimalFormatter,
					});
				}
			} else if (arrayOfRows[i].includes('hum')) {
				humArray.push({
					headerSort: false,
					title: arrayOfRows[i],
					field: arrayOfRows[i],
					hozAlign: 'center',
					headerHozAlign: 'center',
					formatter: decimalFormatter,
					// width: '130'
				});
				if (arrayOfRows[i] === 'hum_mean') {
					columns.push({
						// headerContextMenu: headerMenu,
						title: 'Humidity',
						headerHozAlign: 'center',
						columns: [...humArray],
						formatter: decimalFormatter,
					});
				}
			} else {
				columns.push({
					headerSort: false,
					title: arrayOfRows[i],
					field: arrayOfRows[i],
					hozAlign: 'center',
					headerHozAlign: 'center',
					formatter: decimalFormatter,
					// width: '130',
					// headerContextMenu: headerMenu
				});
			}
		}

		console.log(columns);
		// return columns.slice(1, columns.length);
		return columns;
	};

	const setOptions = () => {
		if (!zoneDaily) return null;
		const options = {
			tableBuilt: function () {
				let footerEl = document.querySelector('.tabulator-footer');

				let customBtn = document.createElement('button');
				customBtn.innerHTML = 'Download XLSX';
				customBtn.style.background = '#F8AB0D';
				customBtn.style.color = '#fff';
				customBtn.style.width = '10rem';
				customBtn.style.padding = '.5rem';
				customBtn.style.border = 'none';
				customBtn.style.borderRadius = '0.6rem';
				customBtn.style.textTransform = 'uppercase';
				customBtn.style.fontWeight = '600';
				customBtn.style.cursor = 'pointer';
				customBtn.onclick = function () {
					tableRef.current.table.download('xlsx', `${new Date()}.xlsx`);
				};
				footerEl.appendChild(customBtn);
			},
			layout: 'fitData',
			height: '100%',
			pagination: 'local',
			paginationSize: 20,
			paginationSizeSelector: [20, 50, 100],
			downloadDataFormatter: data => data,
			downloadReady: (fileContents, blob) => blob
		};
		return options;
	};

	const dailyTable = useMemo(() => {
		return (
			<ReactTabulator
				ref={tableRef}
				options={setOptions()}
				columns={createColumns()}
				data={tableData()}
			/>
		);
	}, [zoneDaily]);

	return !zoneDaily ? ( <LoadingView /> ) : (<TableWrapper>{dailyTable}</TableWrapper>)
};

export default DailyTable;
