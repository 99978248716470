import styled from 'styled-components/macro';

import { shake } from '../styled/animations.css';
import winessense_logo from '../../assets/img/winessense.svg';
import { FlexMiddle, FocusStyle, InputStyle, WidthProp } from '../styled/primitives.css';
import { StyledInput, StyledButton } from '../styled/styledFormElements.css';

export const NarrowForm = styled.form`
    max-width: 22rem;

    margin-top: 2rem;
    margin-bottom: 2rem;

    ${FlexMiddle}
    gap: 1rem;
`;

export const FormTitle = styled.h1`
    font-size: 2em;
    text-align: center;
    line-height: 1;
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
`;

export const FormInput = styled(StyledInput)`
    ${WidthProp({ initial: '100%' })}

    --focus-color: var(--orange);
    --focus-width: 2px;
    color: var(--text-color);
    background: var(--white);

    animation-duration: 0.3s;
    animation-name: ${({ error }) => (error ? shake : 'none')};

    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    &:disabled {
        border-color: transparent;
        color: rgba(var(--RGB-gray, 20));
        &::placeholder {
            color: rgba(var(--RGB-gray, 20));
        }
    }
`;

export const InputLabel = styled.label`
    font-weight: 400;
    color: inherit;
    & > span {
        color: var(--red);
    }
`;

export const SubmitButton = styled(StyledButton).attrs({
    type: 'submit',
})`
    width: ${({ width }) => width || '20ch'};

    padding: 0.6em;
    margin-top: 1rem;
    --focus-width: 1px;
    font-weight: 600;
`;

export const HiddenSubmit = styled.button.attrs({
    type: 'submit',
})`
    width: 0;
    height: 0;
    display: none;
    pointer-events: none;
`;

export const NavButtons = styled.div`
    width: 80%;
    display: flex;
    justify-content: space-between;
    justify-self: flex-end;
`;

export const FormText = styled.p`
    text-align: center;
    white-space: pre-wrap;
    margin-bottom: 1rem;
    & > a {
        color: var(--action-color);
    }
`;

export const WinessenseLogo = styled.div`
    background-image: url(${winessense_logo});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    aspect-ratio: 1.2;
    width: ${({ size }) => size || '6rem'};
    height: ${({ size }) => `calc(${size} * 0.83465)` || '5rem'};
    display: ${({ inline }) => (inline ? 'inline-block' : 'block')};
`;

export const SetupNav = styled.nav`
    width: 80%;
    position: absolute;
    top: 9rem;
    display: flex;
    justify-content: space-between;
    pointer-events: none;
`;

export const Button = styled(StyledButton).attrs(function (props) {
    return { type: props.type || 'button' };
})`
    width: ${({ width }) => width || '20ch'};

    padding: 1em;
    --focus-width: 1px;
    font-weight: 600;
    background: var(--action-color);
`;

export const TextArea = styled(FormInput).attrs({
    as: 'textarea',
    spellCheck: 'false',
})`
    padding: 0.5rem;
    font-size: 0.9em;
    padding-left: 1rem;
    resize: none;
    overflow: auto;
    cursor: auto;
    white-space: pre;
    font: inherit;
    ::-webkit-scrollbar {
        width: 5px;
        height: 5px;
    }
    ::-webkit-scrollbar-track {
        background: var(--gray);
        border-radius: 100vw;
        margin-top: 0.9rem;
        margin-bottom: 0.7rem;
        margin-left: 0.9rem;
        margin-right: 0.6rem;
    }
    ::-webkit-scrollbar-thumb {
        background: var(--dark-gray);
        border-radius: 100vw;
    }
    ::-webkit-scrollbar-corner {
        display: none;
    }
    @supports (scrollbar-width: none) {
        scrollbar-width: none;
    }
    ::placeholder {
        font-size: 0.9em;
    }
`;
