import { useFormContext } from 'react-hook-form';

export default function useFormZoneCenter(zoneChildName) {
    const { watch } = useFormContext();
    
    if (!zoneChildName) return [0, 0];

    const nameParts = zoneChildName.split('.');
    const zoneIndex = nameParts[nameParts.findIndex(p => p === 'zones') + 1];

    const watchZoneLat = +watch(`zones.${zoneIndex}.lat`);
    const watchZoneLong = +watch(`zones.${zoneIndex}.long`);

    const zoneCenter = [
        //
        +watch(`zones.${zoneIndex}.lat`),
        +watch(`zones.${zoneIndex}.long`),
    ];

    if (zoneCenter.some(c => !c)) return [0, 0];
    return zoneCenter;
}
