import React, { useState, useEffect } from 'react';
import styled from 'styled-components/macro';
import { fetchAPI } from '../utils/fetchAPI';
import { useParamsSync } from '../hooks/useParamsSync';

import { useFieldCtx } from '../context/FieldContext';
import { useAuthCtx } from '../context/AuthContext';
import { useZoneCtx } from '../context/ZoneContext';

import { LoadingView } from '../components/Custom/LoadingView';
import TitledSection from '../components/Custom/TitledSection';
import CurrentData from '../components/CurrentData/CurrentData';
import FieldForecast from '../components/FieldForecast/FieldForecast';

const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
`;

const FieldDashboard = () => {
    const [long, setLong] = useState(undefined);
    const [lat, setLat] = useState(undefined);
    const [zoom, setZoom] = useState(undefined);
    const [coordinates, setCoordinates] = useState(undefined);

    const [isLoading, setIsLoading] = useState(false);
    const [zCondData, setZCondData] = useState(undefined);
    const [fcstData, setFcstData] = useState(undefined);

    const { token } = useAuthCtx();
    const { zoneId } = useZoneCtx();
    const { fieldId } = useFieldCtx();

    useEffect(() => {
        (async () => {
            setIsLoading(true);
            if (token && zoneId && fieldId) await getDashboardData();
            setIsLoading(false);
        })();
    }, [zoneId, fieldId]);

    useParamsSync();

    const getDashboardData = async () => {
        console.log('Udji u backend data');

        ////////////////////////////////////
        // Fetch coords from new fCrds API
        ////////////////////////////////////
        fetchAPI('fCrds', {
            v: '1.0',
            token: token,
            zone: zoneId,
        })
            .then(({ coords }) => {
                let allCoords = coords.flat();

                let minLong = Math.min(...allCoords.map(o => o.lng));
                let maxLong = Math.max(...allCoords.map(o => o.lng));
                let minLat = Math.min(...allCoords.map(o => o.lat));
                let maxLat = Math.max(...allCoords.map(o => o.lat));

                let distance = maxLong - minLong;
                let zoomLevel =
                    distance > 0.022
                        ? 13
                        : distance > 0.011
                        ? 14
                        : distance > 0.005
                        ? 15
                        : distance > 0.003
                        ? 16
                        : distance > 0.001
                        ? 16.5
                        : 18;

                setCoordinates(coords);
                setLong((maxLong + minLong) / 2);
                setLat((maxLat + minLat) / 2);
                setZoom(zoomLevel);
            })
            .catch(error => console.error(error.message));

        const zoneCondData = await fetchAPI('zCond', {
            v: '1.0',
            token: token,
            zone: `${zoneId}`,
            now: 'for now, any now',
        });

        const forecastData = await fetchAPI('dFcst', {
            v: '1.1',
            token: token,
            zone: `${zoneId}`,
            now: 'utc_ts',
        });

        let tempArray = [];
        let timestamps = Object.keys(forecastData.forecast);
        let dalyValues = Object.values(forecastData.forecast);
        for (let i = 0; i < dalyValues.length; i++) {
            let dayObj = {};
            let time = new Date(timestamps[i] * 1000).toString().substring(0, 11);
            dayObj['time'] = time;
            const element = dalyValues[i];
            for (let j = 0; j < element.length; j++) {
                dayObj[element[j].name] = element[j].value;
            }
            tempArray.push(dayObj);
        }
        tempArray = tempArray.slice(0, 9);

        setFcstData(tempArray);
        setZCondData(zoneCondData);
    };

    return isLoading ? (
        <LoadingView />
    ) : (
        <Container>
            <TitledSection title='Current Data'>
                <CurrentData
                    currData={zCondData}
                    coordinates={coordinates}
                    lng={long}
                    lat={lat}
                    zoom={zoom}
                />
            </TitledSection>
            <TitledSection title='Forecast Data' flex={1}>
                <FieldForecast data={fcstData} />
            </TitledSection>
        </Container>
    );
};

export default FieldDashboard;
