import React from 'react'
import { useAuthCtx } from '../context/AuthContext'
import { Navigate } from 'react-router-dom'

const RouteAuth = ({ children, noAuth = false }) => {
    const {token} = useAuthCtx()
    return (noAuth ? !token : token) ? <>{children}</>: <Navigate to={noAuth ? '/' : '/landing/login'} />;
}

const privateRoute = (element) => <RouteAuth>{element}</RouteAuth>
const publicRoute = (element) => <RouteAuth noAuth>{element}</RouteAuth>

export {
    RouteAuth,

    publicRoute,
    privateRoute
};
