import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components/macro';
import ReactSlider from 'react-slider';
import {
    MdArrowForwardIos,
    MdArrowBackIos,
    MdClose,
    MdOutlineRefresh,
} from 'react-icons/md';
import { controlBorder } from './HeatMapPicker';
import dayjs from 'dayjs';
import { getName } from '../../assets/assetData';

const Container = styled.div`
    --height: 30px;
    width: 100%;
    display: flex;
    gap: 0.5rem;
    align-items: flex-start;
`;

const SliderContainer = styled.div`
    flex-grow: 1;
`;

const SliderControls = styled.div`
    height: var(--height);
    width: 100%;
    ${controlBorder}
    border-radius: calc(0.5 * var(--height));
    display: flex;
`;

const SliderDetails = styled.div`
    height: calc(0.5 * var(--height) + 2rem);
    width: 100%;
    position: relative;
    top: calc(-0.5 * var(--height));
    padding: calc(0.5 * var(--height)) 2rem 0 2rem;
    z-index: -1;
    display: flex;
    align-items: stretch;

    background-color: rgba(0, 0, 0, 0.2);
    border-bottom-left-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
    color: var(--white);
    font-size: 1.1rem;
    text-shadow: 1px 1px 3px var(--black);
`;

const Half = styled.div`
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-start;
`;

const StyledSlider = styled(ReactSlider)`
    flex-grow: 1;
    height: 100%;
    width: 400px;
    cursor: pointer;
`;

const StyledThumb = styled.div`
    height: calc(0.6 * var(--height));
    margin-top: calc(0.15 * var(--height));
    width: 8px;
    background: var(--dark-gray);
    color: #fff;
    border-radius: 2px;
`;

const StyledTrack = styled.div`
    height: 100%;
    background: var(--white);
    border-left: 1px solid var(--light-gray);
    border-right: 1px solid var(--light-gray);
    position: relative;
    &::after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        top: calc(50% - 1px);
        height: 2px;
        background: var(--light-gray);
    }
`;

const SliderButton = styled.button`
    border: none;
    cursor: pointer;
    height: 100%;
    width: var(--height);
    background: var(--white);
    display: grid;
    place-items: center;
    color: var(--dark-gray);
`;
const RightButton = styled(SliderButton)`
    border-bottom-right-radius: 50%;
    border-top-right-radius: 50%;
`;

const LeftButton = styled(SliderButton)`
    border-bottom-left-radius: 50%;
    border-top-left-radius: 50%;
`;

const CloseButton = styled(SliderButton)`
    height: var(--height);
    width: var(--height);
    border-radius: 50%;
    ${controlBorder};
`;

const CloseSlider = props => (
    <CloseButton onClick={props.onClick}>
        <MdClose size={'1.2rem'} />
    </CloseButton>
);

const Thumb = props => <StyledThumb {...props} />;
const Track = props => <StyledTrack {...props} />;

const Slider = props => {

    const MAX_VALUE = props.max || 100;
    const MIN_VALUE = props.min || 0;

    const increment = () => {
        props.heatMap.slider != MAX_VALUE && props.onChange(props.heatMap.slider + 1);
    };
    const decrement = () => {
        props.heatMap.slider != MIN_VALUE && props.onChange(props.heatMap.slider - 1);
    };

    // TODO: loadMore
    function loadMore() {
        // fetchAPI('mHeat')
    }

    const { column, period, slider, data } = props.heatMap;
    const columnName = getName(column);
    const sliderTime = dayjs(data?.[0]?.data_ts[slider] * 1000).format(
        period === 'day' ? 'DD/MM/YYYY' : 'DD/MM/YYYY HH:mm'
    );

    console.log('render slider');

    return (
        <Container className={props.className}>
            <SliderContainer>
                <SliderControls>
                    {/* {slider === MAX_VALUE ? ( */}
                    {false ? (
                        <LeftButton onClick={loadMore}>
                            <MdOutlineRefresh size={'1.5rem'} />
                        </LeftButton>
                    ) : (
                        <LeftButton onClick={increment}>
                            <MdArrowBackIos size={'1rem'} />
                        </LeftButton>
                    )}

                    <StyledSlider
                        renderTrack={Track}
                        renderThumb={Thumb}
                        min={MIN_VALUE}
                        max={MAX_VALUE}
                        invert
                        step={1}
                        value={slider}
                        onChange={props.onChange}
                    />

                    <RightButton onClick={decrement}>
                        <MdArrowForwardIos size={'1rem'} />
                    </RightButton>
                </SliderControls>

                <SliderDetails>
                    <Half>{columnName}</Half>
                    <Half>{sliderTime}</Half>
                    <Half>value: {slider}</Half>
                </SliderDetails>
            </SliderContainer>
            
            <CloseSlider onClick={props.onClose} />
        </Container>
    );
};

export default styled(Slider)``;
