import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components/macro';
import dayjs from 'dayjs';

import Toggle from '../components/Historical/Toggle';
import Selector from '../components/Custom/Selector';
import CheckBox from '../components/Custom/CheckBox';
import { useNavigate } from 'react-router-dom';
import { useZoneCtx } from '../context/ZoneContext';
import { useFieldCtx } from '../context/FieldContext';
import { fetchAPI } from '../utils/fetchAPI';
import { useAuthCtx } from '../context/AuthContext';

import useNotificationRadioGroup from '../hooks/useNotificationRadioGroup';
import Notification from '../components/Notifications/Notification';
import InfiniteScroll from 'react-infinite-scroll-component';

const initialLastData = {
    notification: 0,
    chronology: 0,
    notificationsMore: true,
    chronologyMore: true,
};

const Notifications = () => {
    const { token } = useAuthCtx();
    const { zoneId } = useZoneCtx();
    const { fieldId, fieldNames, setFieldIdFunc } = useFieldCtx();
    const navigate = useNavigate();

    const [modeToggle, setModeToggle] = useState(true);
    const { toggle, isActive, activeGroups, getLabel } = useNotificationRadioGroup();

    const [notifications, setNotifications] = useState([]);
    const [chronology, setChronology] = useState([]);
    const [lastData, setLastData] = useState(initialLastData);

    const overflowContainerRef = useRef();

    function changeField(field) {
        setFieldIdFunc(field);
        navigate(`/${zoneId}/${field}/notifications`);
    }

    function fetchNotifications() {
        console.log(`${!lastData.notification ? 'fetch' : 'more'}Notifications!`);
        fetchAPI('sNotf', {
            v: '1.1',
            token: token,
            zone: zoneId,
            group: activeGroups,
            ts: !lastData.notification ? dayjs().unix() : lastData.notification,
            num: '20',
            simulator: '0',
        }).then(sNotf => {
            console.log(sNotf);
            setLastData(old => ({
                ...old,
                notification: sNotf[sNotf.length - 1]?.ts,
                notificationsMore: sNotf.length === 20,
            }));
            if (!lastData.notification) setNotifications(sNotf);
            else setNotifications(old => [...old, ...sNotf]);
        });
    }

    function fetchChronology() {
        console.log(`${!lastData.chronology ? 'fetch' : 'more'}Chronology!`);
        fetchAPI('sChro', {
            v: '1.1',
            token: token,
            field: fieldId,
            group: activeGroups,
            ts: !lastData.chronology ? dayjs().unix() : lastData.chronology,
            num: '20',
            simulator: '0',
        }).then(sChro => {
            console.log(sChro);
            setLastData(old => ({
                ...old,
                chronology: sChro[sChro.length - 1]?.ts,
                chronologyMore: sChro.length === 20,
            }));
            if (!lastData.chronology) setChronology(sChro);
            else setChronology(old => [...old, ...sChro]);
        });
    }

    function handleToggle(e) {
        setLastData({ ...initialLastData });
        setModeToggle(old => !old);
    }

    function handleFieldChange(e) {
        setLastData({ ...initialLastData });
        changeField(e.target.value);
    }

    function handleGroupChange(group) {
        setLastData({ ...initialLastData });
        toggle(group);
    }

    useEffect(() => {
        console.log('FETCH!');
        overflowContainerRef.current.scroll({ top: 0 });
        modeToggle ? fetchNotifications() : fetchChronology();
    }, [modeToggle, fieldId, activeGroups.length]);

    return (
        <Container>
            <Header>
                <Toggle
                    firstActive={modeToggle}
                    labels={['Notifications', 'Chronology']}
                    onToggle={handleToggle}
                />
                <CheckboxWrapper>
                    <CheckBox
                        label={getLabel('diseases')}
                        checked={isActive('diseases')}
                        onChange={() => handleGroupChange('diseases')}
                    />
                    <CheckBox
                        label={getLabel('phenology')}
                        checked={isActive('phenology')}
                        onChange={() => handleGroupChange('phenology')}
                    />
                    <CheckBox
                        label={getLabel('weather')}
                        checked={isActive('weather')}
                        onChange={() => handleGroupChange('weather')}
                    />
                    <CheckBox
                        label={getLabel('service')}
                        checked={isActive('service')}
                        onChange={() => handleGroupChange('service')}
                    />
                    {!modeToggle && (
                        <CheckBox
                            label={getLabel('report')}
                            checked={isActive('report')}
                            onChange={() => handleGroupChange('report')}
                        />
                    )}
                </CheckboxWrapper>
                {!modeToggle && (
                    <Selector
                        label='For field:'
                        options={fieldNames}
                        value={fieldId}
                        onChange={handleFieldChange}
                    />
                )}
                {'notifications' + notifications.length}
                {'chronology' + chronology.length}
            </Header>
            <OverflowContainer id='overflow-container' ref={overflowContainerRef}>
                {modeToggle ? (
                    <StyledInfiniteScroll
                        gap='1rem'
                        dataLength={notifications.length}
                        scrollableTarget='overflow-container'
                        next={fetchNotifications}
                        hasMore={lastData.notificationsMore}
                        loader={<h4>Loading...</h4>}
                        endMessage={<h4>Yay! You have seen it all</h4>}
                    >
                        {notifications.map((n, i) => (
                            <Notification n={n} mode={modeToggle} key={'notifications' + i} />
                        ))}
                    </StyledInfiniteScroll>
                ) : (
                    <StyledInfiniteScroll
                        gap='2rem'
                        dataLength={chronology.length}
                        scrollableTarget='overflow-container'
                        next={fetchChronology}
                        hasMore={lastData.chronologyMore}
                        loader={<h4>Loading...</h4>}
                        endMessage={
                            <p style={{ textAlign: 'center' }}>
                                <b>Yay! You have seen it all</b>
                            </p>
                        }
                    >
                        {chronology.map((n, i) => (
                            <Notification n={n} mode={modeToggle} key={'chronology' + i} />
                        ))}
                    </StyledInfiniteScroll>
                )}
            </OverflowContainer>
        </Container>
    );
};

const OverflowContainer = styled.div`
    margin: 0 auto;
    width: 57rem;
    overflow-y: scroll;
    max-height: 90%;
    color: var(--text-color);
`;

const Container = styled.div`
    height: 100%;
`;

const Header = styled.header`
    display: flex;
    justify-content: center;
    align-items: start;
    gap: 2rem;
    margin: 0 auto 2rem auto;
    padding-top: 2px;

    font-size: 0.85rem;
    background-color: var(--light-green);

    & > *:first-child {
        margin-left: 3rem;
    }
    ${CheckBox} {
        border: 1px solid var(--gray);
    }
`;

const CheckboxWrapper = styled.div`
    display: grid;
    grid-template-columns: 2fr 3fr;
    grid-template-rows: 1fr 1fr;
`;

const StyledInfiniteScroll = styled(InfiniteScroll)`
    display: flex;
    flex-direction: column;
    /* flex-wrap: wrap; */
    width: 100%;
    height: max-content;
    align-items: center;
    gap: ${props => props.gap};
`;

export default Notifications;
