import React, { useRef } from 'react';
import styled from 'styled-components/macro';

import {
    MapContainer,
    ScaleControl,
    ZoomControl,
    FeatureGroup,
} from 'react-leaflet';

import L from 'leaflet';
import TileControl from './TileControl';
import ChangeView from './ChangeView';
delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

const Container = styled.div`
    height: 100%;
    width: 100%;
    position: relative;
    border-radius: inherit;
`;

const LeafletMap = ({
    children,
    setMap,
    tiles = ['osm'],
    center,
    zoom,
    zoomControl = true,
    scaleControl = true,
    className,
    extraClass,
    recenterDeps,
    ...rest
}) => {
    const childrenFgRef = useRef();
    return (
        <Container className={className + ' ' + extraClass}>
            <MapContainer
                center={[0, 0]}
                zoom={2}
                zoomSnap={1}
                zoomDelta={1}
                zoomControl={false}
                style={{ height: '100%', width: '100%', borderRadius: 'inherit' }}
                maxBounds={[
                    [-120, -180],
                    [120, 180],
                ]}
                maxBoundsViscosity={0.8}
                whenCreated={map => setMap && setMap(map)}
                {...rest}
            >
                <ChangeView
                    center={center}
                    zoom={zoom}
                    childrenFgRef={childrenFgRef}
                    recenterDeps={recenterDeps}
                />
                <TileControl tiles={tiles} />
                {zoomControl && <ZoomControl position='topright' />}
                {scaleControl && <ScaleControl position='bottomright' />}

                <FeatureGroup ref={childrenFgRef}>{children}</FeatureGroup>
            </MapContainer>
        </Container>
    );
};

export default styled(LeafletMap)``;
