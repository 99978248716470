import React, { memo, useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components/macro';
import { useAuthCtx } from '../../context/AuthContext';
import { useZoneCtx } from '../../context/ZoneContext';
import { fetchAPI } from '../../utils/fetchAPI';
import Slider from './Slider';
import HeatMapPicker from './HeatMapPicker';
import dayjs from 'dayjs';
import useUpdateEffect from '../../hooks/useUpdateEffect';
import { getUnit, getColor } from '../../assets/assetData';

const OverlayContainer = styled.div`
    position: absolute;
    top: 10px;
    left: 10px;
    right: 10px;
    bottom: 10px;
    z-index: 1100;

    pointer-events: none;
    & > ${Slider} {
        width: 80%;
        margin: 0 auto;
    }

    & > * {
        pointer-events: all;
    }
`;

const HeatMapOverlay = ({ polygons, setPolygons, heatMap, setHeatMap, defaultHeatMapValue }) => {
    console.log('render overlay');
    const { token } = useAuthCtx();
    const { zoneId } = useZoneCtx();

    const NUM_OF_TS = 20;

    useEffect(() => {
        if (!heatMap.column) return;
        setHeatMap(old => ({ ...old, slider: 0, data: undefined, ready: false }));
        console.log('mHeat effect');
        fetchAPI('mHeat', {
            v: '2.2',
            token: token,
            zone: zoneId,
            to_ts: dayjs().unix(),
            num: NUM_OF_TS,
            column: heatMap.column,
            period: heatMap.period,
        })
            .then(data => {
                const isEmpty = !Object.keys(data).length;
                isEmpty && console.debug(`%c NO mHEAT DATA FOR ZONE ${zoneId}`, 'color: red')
                Object.keys(data).length !== polygons.length && console.debug(`%c NUMBER OF MHEAT(${Object.keys(data).length}) AND POLYGONS(${polygons.length}) DIFFERENT FOR ZONE ${zoneId}`, 'color: red')
                console.debug({mHeat: data})
                setHeatMap(old => ({
                    ...old,
                    slider: 0,
                    data: isEmpty ? alert('No data!') : data,
                    ready: true,
                }));
            })
            .catch(error => {
                alert(error.message);
            });
    }, [heatMap.column, heatMap.period]);

    useUpdateEffect(() => {
        console.log('update effect');
        if (!heatMap.data) {
            setPolygons(old =>
                old.map(field => ({
                    ...field,
                    values: undefined,
                    colors: undefined,
                }))
            );
            return;
        }
        console.log('...with data');

        const formatValue = value => Math.round(+value * 10) / 10;

        setPolygons(old =>
            old.map(field => {
                console.debug(heatMap.data.find(elm => elm.field_id === field.field_id))
                if (!heatMap.data.find(elm => elm.field_id === field.field_id)) {
                    console.debug(`%c NO HEATMAP ${heatMap.column} DATA FOR FIELD ` + field.field_id, 'color: red')
                }
                return {
                    ...field,

                    values: heatMap.data
                        .find(elm => elm.field_id === field.field_id)
                        ?.[heatMap.column].map(
                            val => `${formatValue(val)}${getUnit(heatMap.column)}`
                        ),

                    colors: heatMap.data
                        .find(elm => elm.field_id === field.field_id)
                        ?.[heatMap.column].map(val =>
                            getColor(val, heatMap.column, heatMap.period)
                        ),
                };
            })
        );
    }, [heatMap.data]);

    return (
        <OverlayContainer>
            <HeatMapPicker
                heatMap={heatMap}
                onChange={val => setHeatMap(old => ({ ...old, ...val }))}
            />

            {heatMap.ready && (
                <Slider
                    heatMap={heatMap}
                    onChange={val => setHeatMap(old => ({ ...old, slider: val }))}
                    onClose={() => setHeatMap(defaultHeatMapValue)}
                    min={0}
                    max={NUM_OF_TS - 1}
                />
            )}
        </OverlayContainer>
    );
};

export default HeatMapOverlay;
