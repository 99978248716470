/**
 * noPropagate - wraps a function with preventDefault and stopPropagation
 * 
 * @param {function} callback 
 * @returns 
 */
export function noPropagate(callback) {
    return event => {
        event.preventDefault();
        event.stopPropagation();
        callback(event);
    }
}
