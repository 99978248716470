import React from 'react';
import styled from 'styled-components/macro';

import TimezoneSelect, { allTimezones } from 'react-timezone-select';
import ValidationError from './ValidationError';
import { FocusStyle, InputStyle } from '../styled/primitives.css';
import { ErrorMessage } from '@hookform/error-message';

const Container = styled.div`
    position: relative;
    min-width: 20ch;
    & > ${ValidationError} {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 2.5rem;
    }
`;

const TZSelect = styled(TimezoneSelect).attrs({
    className: 'react-select__container',
    classNamePrefix: 'react-select',
})`
    color: var(--text-color);
    font-family: inherit;
    & * {
        font: inherit;
    }
    &.react-select__container {
        ${InputStyle};
        ${FocusStyle}
        padding: 0;
        .react-select__control {
            border-radius: calc(1rem - 1px);
            padding: 0;
            height: 100%;
            box-shadow: none;
            border: none;
            &.react-select__control--is-focused,
            &.react-select__control--menu-is-open {
                outline: 2px solid var(--action-color) !important;
            }
            .react-select__value-container {
                flex-wrap: wrap;
                width: 100%;
                height: 100%;
                padding: 0 1rem;
                .react-select__multi-value {
                    border-radius: 0.5rem;
                    .react-select__multi-value__label {
                    }
                    .react-select__multi-value__remove {
                        border-radius: 0.5rem;
                    }
                }
                .react-select__input-container {
                    padding: 0;
                    margin: 0;
                }
                .react-select__single-value {
                    margin: 0;
                }
            }
            .react-select__indicators {
            }
        }
        .react-select__menu {
            .react-select__menu-list {
            }
            .react-select__option {
            }
        }
    }
`;

const TimeZoneInput = ({ value, onChange, errors, name, ...rest }) => {
    return (
        <Container>
            <TZSelect value={value} onChange={e => onChange(e.value)} {...rest} />
            <ErrorMessage
                errors={errors}
                name={name}
                render={({ message }) => <ValidationError message={message} />}
            />
        </Container>
    );
};

export default TimeZoneInput;
