import React, { useContext, useEffect, useState } from 'react';
import { useAuthCtx } from './AuthContext';
const UserContext = React.createContext();

export const useUserCtx = () => useContext(UserContext);

export const UserProvider = ({ children }) => {
    const { token } = useAuthCtx();
    console.log({ token });

    const initialConfig = token
        ? JSON.parse(localStorage.getItem('userConfig'))
        : undefined;

    const [userName, setUserName] = useState(initialConfig?.['acc_name']);
    const [dfltZone, setDfltZone] = useState(initialConfig?.['dflt_zone']);
    const [userZones, setUserZones] = useState(initialConfig?.['zones']);
    const [userFields, setUserFields] = useState(initialConfig?.['fields']);
    const [dfltField, setDfltField] = useState(
        Object.keys(userFields?.[dfltZone] ?? {})[0]
    );

    function setUserConfig(userConfig) {
        if (!userConfig) return;
        const { acc_name, dflt_zone, zones, fields } = userConfig;
        setUserName(acc_name);
        setDfltZone(dflt_zone);
        setUserZones(zones);
        console.log({userConfig})
        setUserFields(fields);
        setDfltField(Object.keys(fields?.[dflt_zone] ?? {})[0]);
    }

    useEffect(() => {
        setUserConfig(JSON.parse(localStorage.getItem('userConfig')));
    }, [token]);

    const value = {
        userName,
        dfltZone,
        dfltField,
        userZones,
        userFields,
        setUserConfig,
    };
    return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};
