import React from 'react';
import { FormInput, InputLabel } from '../Landing/styledEntry.css';
import styled from 'styled-components/macro';
import ValidationError from './ValidationError';
import { ErrorMessage } from '@hookform/error-message';

const StyledTextInput = styled.div`
    position: relative;

    & > ${ValidationError} {
        position: absolute;
        top: 50%;
        right: 0.5em;
        transform: translateY(-50%);
    }

    & > ${InputLabel} {
        position: absolute;
        top: 0px;
        left: 1.1rem;
        font-size: 11px;
        color: var(--gray);
    }
`;

const TextInput = React.forwardRef(({ name, label, errors, ...rest }, ref) => {
    return (
        <StyledTextInput>
            {label && <InputLabel>{label}</InputLabel>}
            <FormInput id={name} name={name} ref={ref} {...rest} />
            <ErrorMessage
                errors={errors}
                name={name}
                render={({ message }) => <ValidationError message={message} />}
            />
        </StyledTextInput>
    );
});

export default TextInput;
