import { useState } from 'react';

export const notificationGroups = {
    diseases: { value: 1, name: 'Diseases' },
    weather: { value: 2, name: 'Weather' },
    phenology: { value: 3, name: 'Plant development' },
    service: { value: 4, name: 'Service information' },
    report: { value: 5, name: 'Field Report' },
};

const initialActiveGroups = Object.values(notificationGroups).map(val => val.value);

export default function useNotificationRadioGroup() {
    const [activeGroups, setActiveGroups] = useState(initialActiveGroups);

    const _getValue = group => {
        if (!notificationGroups[group]) throw new Error(`No notification group named ${group}!`);
        return notificationGroups[group].value;
    };

    const _isActive = groupArray => group => groupArray.includes(_getValue(group));

    /**
     * @typedef {('diseases'|'weather'|'phenology'|'service')} NotificationGroup
     */

    /**
     * @param {NotificationGroup} group - Valid Winessense notification groups
     * @returns {boolean} Whether given group is active (selected) or not
     */
    const isActive = group => _isActive(activeGroups)(group);

    /**
     * Toggle the given group active state
     * @param {NotificationGroup} group - Valid Winessense notification groups
     */
    const toggle = group => {
        let groupValue = _getValue(group);
        setActiveGroups(oldActive =>
            _isActive(oldActive)(group)
                ? oldActive.filter(val => val !== groupValue)
                : [...oldActive, groupValue]
        );
    };

    /**
     * Return the name of the given group
     * @param {NotificationGroup} group - Valid Winessense notification groups
     */
    const getLabel = group => {
        if (!notificationGroups[group]) throw new Error(`No notification group named ${group}!`);
        return notificationGroups[group].name;
    };

    /** Toggle all groups on*/
    const selectAll = () => setActiveGroups(initialActiveGroups);

    /** Toggle all groups off*/
    const unselectAll = () => setActiveGroups([]);

    return { toggle, isActive, selectAll, unselectAll, activeGroups, getLabel };
}
