import React, { useContext, useEffect, useState } from 'react';
import { useUserCtx } from './UserContext';
import { useZoneCtx } from './ZoneContext';

const FieldContext = React.createContext();

export function useFieldCtx() {
    return useContext(FieldContext);
}

export function FieldProvider({ children }) {
    const { userFields } = useUserCtx();
    const { zoneId } = useZoneCtx();
    const initialField = sessionStorage.getItem('fieldID')
        ? sessionStorage.getItem('fieldID')
        : userFields?.[zoneId]?.[0];

    console.log({ zoneId });
    const [fieldId, setFieldId] = useState(initialField);
    const [fieldDaily, setFieldDaily] = useState();
    const [fieldHourly, setFieldHourly] = useState();

    var fieldNames = userFields?.[zoneId] ?? {};
    var fieldsOfZone = Object.keys(fieldNames).sort((a, b) => a - b);

    useEffect(() => {
        fieldNames = userFields?.[zoneId] ?? {};
        fieldsOfZone = Object.keys(fieldNames).sort((a, b) => a - b);
    }, [zoneId, userFields]);

    function setFieldIdFunc(id) {
        if (!id) return;
        setFieldId(String(id));
        sessionStorage.setItem('fieldID', id);
    }

    function setFieldDailyData(data) {
        setFieldDaily(data);
    }

    function setFieldHourlyData(data) {
        setFieldHourly(data);
    }

    function firstFieldOfZone(zone_id) {
        return Object.keys(userFields?.[zone_id] ?? {})[0];
    }
    const value = {
        fieldId,
        fieldsOfZone,
        fieldNames,
        fieldDaily,
        fieldHourly,
        setFieldIdFunc,
        setFieldHourlyData,
        setFieldDailyData,
        firstFieldOfZone,
    };
    return <FieldContext.Provider value={value}>{children}</FieldContext.Provider>;
}
