import React, { useContext, useState } from 'react';

import { fetchAPI } from '../utils/fetchAPI';
import hash from '../utils/hash';

const AuthContext = React.createContext();

export function useAuthCtx() {
    return useContext(AuthContext);
}

export function AuthProvider({ children }) {
    const initialToken = localStorage.getItem('token') ? localStorage.getItem('token') : undefined;
    console.log({ initialToken });

    const [token, setToken] = useState(initialToken);

    // TODO: bump uConf to v1.2
    async function login(email, password) {
        return fetchAPI('uConf', {
            v: email ? '1.3' : '1.1',
            p: email ? await hash(email + password) : password,
        }).then(uConf => {
            const { token, ...userConfig } = uConf;
            setToken(token);
            localStorage.setItem('token', token);
            localStorage.setItem('userConfig', JSON.stringify(userConfig));
            return userConfig;
        });
    }

    function logout() {
        localStorage.clear();
        sessionStorage.clear();
        setToken(undefined);
    }

    const value = {
        token,
        login,
        logout,
        DATA_TOKEN: process.env.REACT_APP_DATA_TOKEN,
    };
    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
