import { createGlobalStyle } from 'styled-components/macro';

import 'leaflet/dist/leaflet.css';
import 'leaflet-draw/dist/leaflet.draw.css';

const GlobalStyle = createGlobalStyle`
* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

html, body, div#root {
	max-width: 100vw;
	height: 100vh;
	overflow: hidden;
	
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
	Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

::placeholder {
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
		Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
/* leaflet attribution flag */
.leaflet-control-attribution {
    display: none !important;
}
/* enables centering polygon labels */
.poly-label {
    display: flex !important;
    pointer-events: none !important;
}
/* leaflet draw */
.sr-only {
    display: none;
}
/* round vertices when editing polygon */
.leaflet-marker-icon.leaflet-div-icon.leaflet-editing-icon {
    width: 1rem !important;
    height: 1rem !important;
    border-radius: 50%;
}
/* hide leaflet draw marker control */
.leaflet-control-container .leaflet-draw-section .leaflet-draw-draw-marker{
    display: none !important;
}

.hidden-polygon {
    pointer-events: none;
}


:root{
	--pale-light-green: #fbfffa;
	--light-green: #e5f4e4;
	--pale-green: #5c896e;
	--green: #3e644d;
	--dark-green: #23382b;
	
	--white: #ffffff;
	--light-gray: #f6f6f6;
	--gray: #9a9898;
    --RGB-gray: 154, 152, 152;
	--dark-gray: #808080;
    --darker-gray: #626367;
	--black: #22293d;

	--orange: #f8ab0d;
	--red: #E05963;
    --danger-red: #de350b;

    --good-color: #6bc063;
    --fair-color: #EBD700;
    --poor-color: var(--orange);
    --bad-color: var(--red);

    --action-color: var(--orange);
    --text-color: var(--darker-gray);
    --placeholder-color: var(--dark-gray);
}
`;

export default GlobalStyle;
