import React from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { fetchAPI } from '../../utils/fetchAPI';

import { noPropagate } from '../../utils/utils';
import { FormContainer } from '../Maps/styled/styledMapInputs';
import { Flex } from '../styled/primitives.css';
import { StyledButton } from '../styled/styledFormElements.css';
import TextInput from '../Custom/TextInput';

const SensorForm = props => {
    const {
        register,
        handleSubmit,
        formState: { errors },
        getValues,
        control,
    } = useForm({
        mode: 'onBlur',
    });

    function onSubmit(data) {
        props.onSave(data);
    }

    function handleCancel(e) {
        props.onCancel();
    }

    const selectedFieldIdx = props.fields.findIndex(field => field.id === props.selectedId);
    const selectedField = props.fields[selectedFieldIdx];

    const shouldDrawSensorByHand = useWatch({
        name: ['lat', 'long'],
        defaultValue: [
            selectedField.sensor?.point?.geometry.coordinates[1] ?? '',
            selectedField.sensor?.point?.geometry.coordinates[0] ?? '',
        ],
        control,
    }).includes('');

    if (!selectedField) return null;

    const displayName = selectedField.info.name || `Sensor for Field ${selectedFieldIdx + 1}`;

    const validateSensor = async value => {
        return await fetchAPI('vSens', {
            v: '1.0',
            serial_number: value,
            verification_code: getValues('verification') ?? '',
        })
            .then(({ resp_code, resp_text }) => {
                if (resp_code === 0) {
                    return true;
                } else {
                    return resp_text;
                }
            })
            .catch(() => 'Server error, try later');
    };

    return (
        <FormContainer>
            <form onSubmit={noPropagate(handleSubmit(onSubmit))}>
                <h3>{displayName}</h3>

                <fieldset>
                    <legend>Sensor configuration</legend>

                    <label htmlFor='serial'>Serial number</label>
                    <TextInput
                        id='serial'
                        {...register('serial', { required: 'Required', validate: validateSensor })}
                        errors={errors}
                        placeholder='Enter serial number'
                        defaultValue={selectedField.sensor?.info?.serial}
                    />

                    <label htmlFor='wind_offset'>Offset from north</label>
                    <TextInput
                        id='wind_offset'
                        {...register('wind_offset', { min: 0, max: 360 })}
                        type='number'
                        min='0'
                        max='360'
                        errors={errors}
                        placeholder='Offset in degrees (0-360)'
                        defaultValue={selectedField.sensor?.info?.verification}
                    />

                    <label htmlFor='verification'>Verification number (ignored)</label>
                    <TextInput
                        disabled
                        id='verification'
                        {...register('verification')}
                        errors={errors}
                        placeholder='Enter verification number'
                        defaultValue={selectedField.sensor?.info?.verification}
                    />
                </fieldset>

                <fieldset className='location'>
                    <legend>Sensor location</legend>
                    <Flex gap='0.5rem'>
                        <div>
                            <label htmlFor='sensor_lat'>Latitude</label>
                            <TextInput
                                id='sensor_lat'
                                {...register('lat')}
                                errors={errors}
                                placeholder='Enter lat'
                                defaultValue={selectedField.sensor?.point?.geometry.coordinates[1]}
                            />
                        </div>
                        <div>
                            <label htmlFor='sensor_long'>Longitude</label>
                            <TextInput
                                id='sensor_long'
                                {...register('long')}
                                errors={errors}
                                placeholder='Enter long'
                                defaultValue={selectedField.sensor?.point?.geometry.coordinates[0]}
                            />
                        </div>
                    </Flex>

                    <h3 style={{ textAlign: 'center' }}>OR</h3>
                    <StyledButton disabled={!shouldDrawSensorByHand} type='submit'>
                        Place on map and Save
                    </StyledButton>
                </fieldset>

                <StyledButton disabled={shouldDrawSensorByHand} type='submit'>
                    Save
                </StyledButton>
                <StyledButton type='button' onClick={() => handleCancel()}>
                    Cancel
                </StyledButton>
            </form>
        </FormContainer>
    );
};

export default SensorForm;
