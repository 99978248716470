import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useZoneCtx } from "../context/ZoneContext";
import { useFieldCtx } from "../context/FieldContext";

const useParamsSync = () => {
    const {zone_id, field_id} = useParams()
    const {zoneId, setZoneIdFunc} = useZoneCtx()
    const {fieldId, setFieldIdFunc} = useFieldCtx()


    useEffect(() => {
        if(zoneId !== zone_id && zone_id !== 'undefined'){
            console.log('useParamsSync')
            setZoneIdFunc(zone_id)
        }
        if(fieldId !== field_id && field_id !== 'undefined'){
            setFieldIdFunc(field_id)
        }
    },[])  
}

export {useParamsSync}