import React, { useCallback, useContext, useState } from 'react';
import dayjs from 'dayjs';

const DateContext = React.createContext();

export function useDateCtx() {
	return useContext(DateContext);
}

export function DateProvider({ children }) {
	const minDate = dayjs("1/1/21")
	const maxDate = dayjs()
	const hoursToSeconds = hours => hours * 60 * 60
	const daysToSeconds = days => hoursToSeconds(days * 24)

	const [isDaily, setIsDaily] = useState(true);

	const [defaultDailyValue, setDefaultDailyValue] = useState('30');
	const [dailyRange, setDailyRange] = useState(false);
	const [dateToDaily, setDateToDaily] = useState(maxDate.toDate());
	const [dateFromDaily, setDateFromDaily] = useState(maxDate.subtract(defaultDailyValue, 'day').toDate());
	
	const [defaultHourlyValue, setDefaultHourlyValue] = useState('72');
	const [hourlyRange, setHourlyRange] = useState(false);
	const [dateToHourly, setDateToHourly] = useState(maxDate.toDate());
	const [dateFromHourly, setDateFromHourly] = useState(maxDate.subtract(defaultHourlyValue, 'hour').toDate());

	function setDefaultDailyValueFunc(val) {
		setDefaultDailyValue(val)
		if (val !== 'range') {
			setDateFromDaily(maxDate.subtract(defaultDailyValue, 'day').toDate())
			setDateToDaily(maxDate.toDate())
		}
	}

	function setDefaultHourlyValueFunc(val) {
		setDefaultHourlyValue(val)
		if (val !== 'range') {
			setDateFromHourly(maxDate.subtract(defaultHourlyValue, 'hour').toDate())
			setDateToDaily(maxDate.toDate())
		}
	}

	const value = {
		dateFromDaily,
		dateToDaily,
		minDate,
		maxDate,
		defaultDailyValue,
		dailyRange,
		dateFromHourly,
		dateToHourly,
		defaultHourlyValue,
		hourlyRange,
		isDaily,
		setDateFromDailyFunc: setDateFromDaily,
		setDateToDailyFunc: setDateToDaily,
		setDateFromHourlyFunc: setDateFromHourly,
		setDateToHourlyFunc: setDateToHourly,
		setDailyRangeFunc: setDailyRange,
		setHourlyRangeFunc: setHourlyRange,
		setIsDailyFunc: setIsDaily,
		setDefaultDailyValueFunc,
		setDefaultHourlyValueFunc,
		hoursToSeconds,
		daysToSeconds,
	};
	return <DateContext.Provider value={value}>{children}</DateContext.Provider>;
}
