import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

import { useUserCtx } from '../../context/UserContext';

import diseaseImg from '../../assets/img/svg/disease_white.svg';
import weatherImg from '../../assets/img/forecastData/white/forecast_white.svg';
import plantImg from '../../assets/img/forecastData/white/leaf_w_white.svg';
import serviceImg from '../../assets/img/forecastData/sensor_field.svg';

const groupMap = {
    1: 'Disease',
    2: 'Weather',
    3: 'Phenology',
    4: 'Service',
};

const severityMap = {
    1: 'mild',
    2: 'moderate',
    3: 'severe',
};

const diseaseMap = {
    pv: 'plasmopara viticola',
    en: 'erysiphe necator',
    bc: 'botrytis cinerea',
    gb: 'guignardia bidwellii',
};

const actionMap = {
    1: 'Visit',
    2: 'Pruning',
    3: 'Spraying',
    4: 'Watering',
    5: 'Fertilizing',
    6: 'Other',
};

const iconMap = {
    1: diseaseImg,
    2: weatherImg,
    3: plantImg,
    4: serviceImg,
};

const colorMap = {
    1: '#9873AC',       //purple
    2: '#5576d1',       //blue
    3: '#5FC33B',       //green
    4: '#888B8D',       //gray
    5: '#FFAD00',       //orange
};

const Notification = props => {
    const { t } = useTranslation();
    const { userZones, userFields } = useUserCtx();

    const formattingMap = {
        type: t => `type_${t}`,
        min_temp: t => `${t}°C`,
        bbch_text: t => t.split('-').join('_'),

        ts: ts => dayjs.unix(ts).format('D. MMMM YY'),
        user_bbch_date: ts => dayjs.unix(ts).format('D. MMMM YY'),
        start_time: ts => dayjs.unix(ts).format('DD.MM.YYYY [at] HH:mm'),
        end_time: ts => dayjs.unix(ts).format('DD.MM.YYYY [at] HH:mm'),
        last_spray: ts => dayjs.unix(ts).format('DD.MM.YYYY'),
        last_sig_rainfall_date: ts => dayjs.unix(ts).format('DD.MM.YYYY'),
        prev_sp_frost_time: ts => dayjs.unix(ts).format('DD.MM.YYYY'),
        next_phase_date: ts => dayjs.unix(ts).format('DD.MM.YYYY'),

        max_sev_field_id: fId =>
            (userFields[props.n.title.zone_id]?.[fId] ?? '').trim().toUpperCase(),
        field_id: fId => (userFields[props.n.title.zone_id]?.[fId] ?? '').trim().toUpperCase(),
        zone_id: zId => userZones[zId].toUpperCase(),
        group: g => groupMap[g],
        action: a => actionMap[a],
        severity: s => severityMap[s],
        d_max_sev: sArr => severityMap[Math.max(...sArr)],
        diseases: dArr =>
            dArr.reduce(
                (prev, curr, idx, arr) =>
                    `${prev}${diseaseMap[curr]}${idx === arr.length - 1 ? '' : ', '}`,
                '['
            ) + ']',

        // infected_num_fields: num => num,
        // last_sig_rainfall: num => num,
        // total_num_fields: num => num,
        // last_7d_rainfall: num => num,
        // annual_rainfall: num => num,
        // max_severity: num => num,
        // rainfall: num => num,
        // bbch: num => num,
    };

    const harlemShake = (obj, depth = 0) => {
        // !depth && console.log('=========================================');
        // !depth && console.log(obj);
        // !depth && console.log(obj.title.type);
        var tmp = { ...obj };
        for (let [key, value] of Object.entries(tmp)) {
            if (['title', 'msg', 'forecast', 'state'].includes(key)) {
                tmp[key] = harlemShake(value, depth + 1);
            } else {
                tmp[key] = formattingMap[key] ? formattingMap[key](value) : value;
            }
        }
        // notification types with affected fields status text
        if (depth === 0 && [1007, 1008].includes(obj.title.type)) {
            const affectedFields = tmp.msg.state.field_sev
                .reduce((prev, curr, idx) => {
                    if (curr !== 0) {
                        prev.push([formattingMap['field_id'](tmp.msg.state.fields[idx]), curr]);
                    }
                    return prev;
                }, [])
                .filter(entry => entry[0] !== '');

            const tStart = t(`notifications.affected.start`, {
                count: affectedFields.length,
            });
            const tJoin = t(`notifications.affected.join`);

            const clg =
                affectedFields
                    .map(([fName, sev]) => `${fName} ${tJoin} ${sev}`)
                    .reduce(
                        (prev, curr, idx, arr) =>
                            prev +
                            (idx
                                ? idx === arr.length - 1
                                    ? ` ${t('&')} ${curr}`
                                    : `, ${curr}`
                                : curr),
                        `\n${tStart} `
                    ) + '.\n';
            tmp.fields_affected = affectedFields.length ? clg : '';
        }
        return tmp;
    };

    var mode = props.mode ? 'notifications' : 'chronology';
    const n = harlemShake(props.n);
    // console.log(n, props.n);
    return (
        <Container>
            <Header color={colorMap[props.n.title.group]}>
                <h2>
                    {<img src={iconMap[props.n.title.group]} />}
                    {n.title.group}
                </h2>
                <div>{n.ts}</div>
            </Header>

            <Body>
                {props.mode && (
                    <>
                        FORECAST
                        <Section severity={props.n.msg.forecast.severity}>
                            <h3
                                dangerouslySetInnerHTML={{
                                    __html: t(`${mode}.${n.title.type}.forecast.title`, { n }),
                                }}
                            />
                            <p
                                dangerouslySetInnerHTML={{
                                    __html: t(`${mode}.${n.title.type}.forecast.text`, { n }),
                                }}
                            />
                        </Section>
                    </>
                )}
                STATE
                <Section severity={!props.mode && props.n.msg.state.severity}>
                    <h3
                        dangerouslySetInnerHTML={{
                            __html: t(`${mode}.${n.title.type}.state.title`, { n }),
                        }}
                    />
                    <p
                        dangerouslySetInnerHTML={{
                            __html: t(
                                `${mode}.${n.title.type}.state.text${
                                    !props.mode && n.msg.state.bbch_text
                                        ? `_${n.msg.state.bbch_text}`
                                        : ''
                                }`,
                                { n }
                            ),
                        }}
                    />
                </Section>
            </Body>
        </Container>
    );
};

const Container = styled.div`
    width: 55rem;
    box-shadow: -0px 5px 8px 0px #00000050;
`;

const Header = styled.div`
    --diseases-color: ${props => props.color};
    border-top-right-radius: 0.6rem;
    border-top-left-radius: 0.6rem;
    background-color: var(--diseases-color);
    color: white;

    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 2rem 0 1.2rem;

    & h2 {
        * {
            display: inline-block;
            :first-child {
                width: 1.3rem;
                height: 1.3rem;
                margin-right: 0.5rem;
                :hover {
                    transform: none;
                    cursor: auto;
                }
            }
        }
    }
`;

const Body = styled.div`
    --0-color: transparent;
    --1-color: #fffcc9;      //yellow
    --2-color: #fad3ae;      //orange
    --3-color: #FDC1C2;     //red

    display: flex;
    flex-direction: column;
    padding: 1rem;
    gap: 0.5rem;
    background-color: white;
    * {
        white-space: pre-wrap;
    }
`;

const Section = styled.section`
    background-color: ${props => props.severity && `var(--${props.severity}-color);`};
`;

const State = styled.section``;

export default Notification;
