import React from 'react';
import styled from 'styled-components/macro';

import { StyledButton } from '../styled/styledFormElements.css';
import { TextArea } from '../Landing/styledEntry.css';
import TextInput from '../Custom/TextInput';
import { useFormContext } from 'react-hook-form';

const FieldDisplay = props => {
    const selectedFieldIdx = props.fields.findIndex(field => field.id === props.selectedId);
    const selectedField = props.fields[selectedFieldIdx];
    const isExistingField = props.selectedId && !String(props.selectedId).includes('-'); // existing fields will have number as id, new ones will have uuid instead

    const { groupOptions } = useFormContext();

    if (!selectedField) {
        return props.fields.length ? (
            <TextBox>Click the field for details!</TextBox>
        ) : (
            <TextBox>
                This zone has no fields.
                <br />
                <br />
                Use polygon tool to draw fields on map!
            </TextBox>
        );
    }

    const displayName = selectedField.info.name || `Field ${selectedFieldIdx + 1}`;

    return (
        <Container>
            <h3>{displayName} details</h3>

            <label>Name:</label>
            <TextInput readOnly defaultValue={selectedField?.info.name} />

            <label>Variant:</label>
            <TextInput readOnly defaultValue={selectedField?.info.variant} />

            <label>Group:</label>
            <TextInput readOnly defaultValue={groupOptions?.[selectedField?.info.group]} />

            <StyledButton type='button' onClick={props.onEditFieldClick}>
                Edit Field
            </StyledButton>
            {props.debug && <pre>{JSON.stringify(selectedField, null, ' .')}</pre>}

            {!selectedField?.sensor && !isExistingField && (
                <>
                    <StyledButton type='button' onClick={props.onDeleteFieldClick}>
                        Delete Field
                    </StyledButton>
                    <hr />
                    <StyledButton type='button' onClick={props.onAddSensorClick}>
                        Add Sensor
                    </StyledButton>
                </>
            )}
            {!!selectedField?.sensor && (
                <>
                    <hr />
                    <h3>Sensor details</h3>

                    <label>Serial number:</label>
                    <TextInput readOnly defaultValue={selectedField?.sensor?.info?.serial} />

                    <label>Location:</label>
                    <TextArea
                        readOnly
                        defaultValue={selectedField?.sensor?.getLocationString()}
                        key={selectedField?.sensor?.getLocationString()}
                    />

                    <StyledButton type='button' onClick={props.onEditSensorClick}>
                        Edit Sensor
                    </StyledButton>
                    <StyledButton type='button' onClick={props.onDeleteSensorClick}>
                        Delete Sensor
                    </StyledButton>
                </>
            )}
        </Container>
    );
};

const Container = styled.div`
    flex-basis: 100%;
    display: flex;
    flex-direction: column;
    height: max-content;
    padding: 0.5rem;
    background-color: #a1bfa9;
    border: 2px solid var(--pale-green);

    label,
    input {
        width: 100%;
    }
    ${StyledButton} {
        margin: 0.2rem auto;
        width: 60%;
        background-color: var(--light-green);
        border: 2px solid var(--gray);
        box-shadow: 1px 1px 1px var(--dark-gray);
        color: var(--active-color);
        letter-spacing: 0.1em;
    }
`;

const TextBox = styled.h3`
    margin: auto auto;
    text-align: center;
`;

export default FieldDisplay;
