import React from 'react';
import styled from 'styled-components/macro';
import { useNavigate, useLocation } from 'react-router-dom';

import { useZoneCtx } from '../../context/ZoneContext';
import { useFieldCtx } from '../../context/FieldContext';
import { useUserCtx } from '../../context/UserContext';

import DateTime from './DateTime';
import UserMenu from './UserMenu';
import Selector from '../Custom/Selector';

// TODO: fix color

export const HeaderContainer = styled.header`
    --heder-padding-X: 2.5rem;
    --heder-padding-Y: 0.2rem;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 1rem;
    padding: var(--heder-padding-Y) var(--heder-padding-X);

    font-size: 1rem;
    color: var(--dark-gray);
`;

const ZoneSelectorWrapper = styled.div`
    flex-grow: 1;
`;

const NewHeader = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { userZones } = useUserCtx();
    const { zoneId, setZoneIdFunc } = useZoneCtx();
    const { setFieldIdFunc, firstFieldOfZone } = useFieldCtx();

    const onZoneChange = (e) => {
        let newZone = e.target.value;
        let newField = firstFieldOfZone(newZone);
        setZoneIdFunc(newZone);
        setFieldIdFunc(newField);
        const view = location.pathname.split('/').pop();
        navigate(`/${newZone}/${newField}/${view}`);
    };

    return (
        <HeaderContainer>
            <ZoneSelectorWrapper>
                <Selector
                    id='zoneSelector'
                    label='Select zone:'
                    options={userZones}
                    value={zoneId}
                    onChange={onZoneChange}
                    allCaps
                />
            </ZoneSelectorWrapper>
            <DateTime />
            <UserMenu showName />
        </HeaderContainer>
    );
};

export default NewHeader;
