import 'react-datepicker/dist/react-datepicker.css';

import React, { useRef, useState } from 'react';
import { useNavigate, useLocation } from 'react-router';
import styled from 'styled-components/macro';
import { InputStyle } from '../styled/primitives.css';
import { StyledButton } from '../styled/styledFormElements.css';

import { useDateCtx } from '../../context/DateContext';
import { useFieldCtx } from '../../context/FieldContext';
import { useZoneCtx } from '../../context/ZoneContext';

import DatePicker from 'react-datepicker';
import Selector from '../Custom/Selector';
import Toggle from './Toggle';

const HistoricalPicker = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const refSelectField = useRef(false);
    const refSelectDaily = useRef(false);
    const refSelectHourly = useRef(false);

    const { fieldId, setFieldIdFunc, fieldNames } = useFieldCtx();
    const { zoneId } = useZoneCtx();
    const {
        minDate,
        maxDate,
        dateFromDaily,
        dateToDaily,
        dailyRange,
        dateFromHourly,
        dateToHourly,
        hourlyRange,
        isDaily,
        setIsDailyFunc,
        defaultDailyValue,
        defaultHourlyValue,
        setDateFromDailyFunc,
        setDateToDailyFunc,
        setDateFromHourlyFunc,
        setDateToHourlyFunc,
        setDefaultDailyValueFunc,
        setDefaultHourlyValueFunc,
        setDailyRangeFunc,
        setHourlyRangeFunc,
    } = useDateCtx();

    const [fromDailyPicker, setFromDailyPicker] = useState(dateFromDaily);
    const [toDailyPicker, setToDailyPicker] = useState(dateToDaily);
    const [fromHourlyPicker, setFromHourlyPicker] = useState(dateFromHourly);
    const [toHourlyPicker, setToHourlyPicker] = useState(dateToHourly);
    const [isHourly, setIsHourly] = useState(!isDaily);

    const dailyOptions = {
        7: 'Last Week',
        14: 'Last 2 Weeks',
        30: 'Last Month',
        60: 'Last 2 Months',
        90: 'Last 3 Months',
        365: 'Last Year',
        range: 'Range',
    };
    const hourlyOptions = {
        24: 'Last 24 Hours',
        48: 'Last 2 Days',
        72: 'Last 3 Days',
        168: 'Last Week',
        720: 'Last Month',
        2184: 'Last 3 Months',
        range: 'Range',
    };

    let selectPeriod = isHourly ? defaultHourlyValue : defaultDailyValue;
    let setSelectPeriod = isHourly ? setDefaultHourlyValueFunc : setDefaultDailyValueFunc;
    let isRange = isHourly ? hourlyRange : dailyRange;
    let setRange = isHourly ? setHourlyRangeFunc : setDailyRangeFunc;
    let setDateFrom = isHourly ? setDateFromHourlyFunc : setDateFromDailyFunc;
    let setDateTo = isHourly ? setDateToHourlyFunc : setDateToDailyFunc;
    let fromPicker = isHourly ? fromHourlyPicker : fromDailyPicker;
    let toPicker = isHourly ? toHourlyPicker : toDailyPicker;
    let refSelect = isHourly ? refSelectHourly : refSelectDaily;
    let options = isHourly ? hourlyOptions : dailyOptions;

    const changeField = () => {
        const field = refSelectField.current.value;
        setFieldIdFunc(field);
        const view = location.pathname.split('/').pop();
        navigate(`/${zoneId}/${field}/${view}`, { replace: true });
    };

    const handleSubmit = () => {
        setSelectPeriod(refSelect.current.value);
        if (isRange) {
            setDateFrom(fromPicker);
            setDateTo(toPicker);
        } else {
            setDateFrom(
                maxDate
                    .subtract(refSelect.current.value, isHourly ? 'hour' : 'day')
                    .toDate()
            );
            setDateTo(maxDate.toDate());
        }
        setIsDailyFunc(!isHourly);
        changeField();
    };

    const handleSelectChange = e => {
        if (e.target.value === 'range') setRange(true);
        else setRange(false);
    };

    // TODO: Refactor
    // TODO: Use React Select maybe
    return (
        <Container>
            <Selector
                id='fieldSelector'
                label='Select field:'
                ref={refSelectField}
                defaultValue={fieldId}
                options={fieldNames}
            />
            <Toggle
                firstActive={!isHourly}
                onToggle={() => setIsHourly(prev => !prev)}
                labels={['Daily', 'Hourly']}
                width='15rem'
            />

            {!isHourly && (
                <RangeWrapper>
                    <Selector
                        id='periodSelector'
                        ref={refSelect}
                        defaultValue={selectPeriod}
                        options={options}
                        onChange={handleSelectChange}
                        allCaps
                    />
                    {dailyRange && (
                        <>
                            <StyledDatePicker label='FROM'>
                                <DatePicker
                                    selected={fromDailyPicker}
                                    minDate={minDate}
                                    maxDate={maxDate}
                                    onChange={date => setFromDailyPicker(date)}
                                    dateFormat='dd/MM/y'
                                    shouldCloseOnSelect={false}
                                />
                            </StyledDatePicker>
                            <StyledDatePicker label='TO'>
                                <DatePicker
                                    selected={toDailyPicker}
                                    onChange={date => setToDailyPicker(date)}
                                    minDate={minDate}
                                    maxDate={maxDate}
                                    dateFormat='dd/MM/y'
                                    shouldCloseOnSelect={false}
                                />
                            </StyledDatePicker>
                        </>
                    )}
                </RangeWrapper>
            )}
            {isHourly && (
                <>
                    <Selector
                        id='periodSelector'
                        ref={refSelect}
                        defaultValue={selectPeriod}
                        options={options}
                        onChange={handleSelectChange}
                        allCaps
                    />
                    {hourlyRange && (
                        <RangeWrapper>
                            <StyledDatePicker label='FROM'>
                                <DatePicker
                                    showTimeSelect
                                    selected={fromHourlyPicker}
                                    onChange={date => setFromHourlyPicker(date)}
                                    minDate={minDate}
                                    maxDate={maxDate}
                                    timeIntervals={60}
                                    timeFormat='HH:mm'
                                    dateFormat='dd/MM/y HH:mm'
                                    shouldCloseOnSelect={false}
                                />
                            </StyledDatePicker>
                            <StyledDatePicker label='TO'>
                                <DatePicker
                                    showTimeSelect
                                    selected={toHourlyPicker}
                                    onChange={date => setToHourlyPicker(date)}
                                    minDate={minDate}
                                    maxDate={maxDate}
                                    timeIntervals={60}
                                    timeFormat='HH:mm'
                                    dateFormat='dd/MM/y HH:mm'
                                    shouldCloseOnSelect={false}
                                />
                            </StyledDatePicker>
                        </RangeWrapper>
                    )}
                </>
            )}
            <ApplyButton onClick={handleSubmit}>Apply</ApplyButton>
        </Container>
    );
};

const Container = styled.div`
    font-size: 1rem;
    color: var(--dark-gray);
    width: 100%;
    padding: 0.5rem 1rem;
    display: flex;
    justify-content: space-between;
    align-items: stretch;
`;

const ApplyButton = styled(StyledButton)`
    --focus-color: var(--dark-gray);
`;

const StyledDatePicker = styled.div`
    & > div.react-datepicker-wrapper::before {
        content: '${props => props.label}';
        position: absolute;
        top: 50%;
        left: 0;
        transform: translate(1ch, -55%);
        font-weight: bold;
        z-index: 1;
    }

    & > div.react-datepicker-wrapper {
        position: relative;
    }

    input {
        ${InputStyle}
        border: 1px solid #d1d1d1;
        padding-left: ${({ label }) => (label == 'TO' ? '5ch' : '8ch')};
        width: ${({ label }) => (label == 'TO' ? '22ch' : '25ch')};
    }
`;

const RangeWrapper = styled.div`
    display: flex;
    gap: 1rem;
`;

export default HistoricalPicker;
