import React from 'react';
import styled from 'styled-components/macro';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { FlexMiddle } from '../styled/primitives.css';
import { NarrowForm, FormTitle, SubmitButton, FormText, WinessenseLogo } from './styledEntry.css';
import TextInput from '../Custom/TextInput';
import { fetchAPI } from '../../utils/fetchAPI';
import { ErrorMessage } from '@hookform/error-message';
import hash from '../../utils/hash';

const Container = styled.div`
    width: 100%;
    height: 100%;
    ${FlexMiddle}
    text-align: center;
`;

const schema = yup.object().shape({
    verification: yup.string().required('Enter verification code!'),
    newPassword: yup.string().required('Required'),
    confirmPassword: yup
        .string()
        .required('Required')
        .oneOf([yup.ref('newPassword'), null], 'Passwords must match'),
});

const ResetPass = () => {
    const navigate = useNavigate();
    const { state } = useLocation();

    const {
        register,
        handleSubmit,
        formState: { errors },
        setError,
        clearErrors,
    } = useForm({
        mode: 'onChange',
        resolver: yupResolver(schema),
    });

    async function resendResetPassEmail(e, email) {
        e.preventDefault();
        fetchAPI('fPass', {
            v: '1.0',
            email: email,
        })
            .then(response => setError('submit', { message: response.resp_text }))
            .catch(error => setError('submit', { message: error.message }));
    }

    async function onSubmit(data, e) {
        fetchAPI('rPass', {
            v: '1.0',
            email: state.email,
            code: data.verification,
            hash_pass: await hash(state.email + data.newPassword),
        })
            .then(response => {
                if (response.resp_code === 0) {
                    navigate('/landing/redirect', {
                        state: {
                            ...state,
                            from: 'reset_pass',
                            redirect: '/landing/login',
                            title: 'Email verified!',
                            explanation:
                                response.resp_text +
                                '!\nYou will be automatically redirected to Log in page.',
                        },
                    });
                } else setError('submit', { message: response.resp_text });
            })
            .catch(error => setError('submit', { message: error.message }));
    }

    return (
        <Container>
            <WinessenseLogo width='8rem' />
            <FormTitle>Reset your password</FormTitle>

            <p>
                We've sent an email to <b>{`${state.email}`}</b>.<br />
                Enter the verification code from email and the new password into the form below.
                <br />
                If you have not received verification email, please check you "Spam" folder
                <br />
                or click the "Send it again!" link below for a new one.
            </p>

            <NarrowForm onSubmit={handleSubmit(onSubmit)} onFocus={e => clearErrors('submit')}>
                <TextInput
                    {...register('verification')}
                    type='text'
                    placeholder='Reset code'
                    errors={errors}
                />
                <TextInput
                    {...register('newPassword')}
                    type='password'
                    placeholder='New password'
                    errors={errors}
                />
                <TextInput
                    {...register('confirmPassword')}
                    type='password'
                    placeholder='Repeat password'
                    errors={errors}
                />
                <SubmitButton>Reset password</SubmitButton>

                <ErrorMessage
                    errors={errors}
                    name='submit'
                    render={({ message }) => <FormText>{message}</FormText>}
                />
            </NarrowForm>

            <FormText>
                Didn't get password reset email?{' '}
                <a href='#' onClick={e => resendResetPassEmail(e, state.email)}>
                    Send it again!
                </a>
                <br />
                <Link to='/landing/login'>Back to Login page</Link>
            </FormText>
        </Container>
    );
};

export default ResetPass;
