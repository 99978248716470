import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components/macro';
import useOnClickOutside from '../../hooks/useOnClickOutside';
import { MdClose } from 'react-icons/md';

const Overlay = styled.div`
    position: fixed;
    top: ${({ show }) => (show ? '0' : '120%')};
    left: 0;
    height: 100vh;
    width: 100vw;
    z-index: ${props => props.zIndex || 100};
    display: grid;
    place-items: center;
    background-color: #00000080;
`;

const Container = styled.div`
    --header-height: 2rem;
    --footer-height: 1rem;
    background-color: var(--white);
    color: var(--text-color);
    width: 95%;
    height: 90vh;
    border-radius: 0.5rem;
    padding: 1rem;
    padding-top: 0;
    position: relative;
    display: flex;
    flex-direction: column;
`;

const Header = styled.header`
    border-bottom: 1px solid var(--gray);
    align-self: center;
    font-size: 1.2rem;
    height: var(--header-height);
`;

const Body = styled.main`
    flex-grow: 1;
    max-height: calc(100% - var(--header-height) - var(--footer-height));
`;

const Footer = styled.footer`
    height: var(--footer-height);
`;

const _CloseButton = props => (
    <MdClose
        className={props.className}
        color='var(--white)'
        size='1.5rem'
        onClick={props.onClick}
    />
);
const CloseButton = styled(_CloseButton)`
    background: var(--dark-gray);
    border-radius: 50%;
    border: 2px solid var(--white);
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(50%, -50%);
    cursor: pointer;
`;

const modalRoot = document.getElementById('modal-root');

const Modal = props => {
    // const clickOutsideRef = useOnClickOutside(props.show && props.onClose);

    function handleClose(e) {
        props.show && props.onClose();
        e.stopPropagation();
    }

    function stopPropagation(e) {
        e.stopPropagation();
    }

    return props.removeOnHide && !props.show
        ? null
        : ReactDOM.createPortal(
              <Overlay show={props.show} zIndex={1000} onClick={handleClose}>
                  <Container onClick={stopPropagation}>
                      <CloseButton onClick={handleClose} />
                      {props.title && <Header>{props.title}</Header>}
                      <Body>{props.children}</Body>
                      {props.footer && <Footer>{props.footer}</Footer>}
                  </Container>
              </Overlay>,
              modalRoot
          );
};

const PopupOverlay = styled.div`
    position: fixed;
    top: ${({ show }) => (show ? '0' : '100%')};
    left: 0;
    height: 100vh;
    width: 100vw;
    z-index: ${props => props.zIndex || 100};
    display: grid;
    place-items: center;
    background-color: #00000080;
`;

const PopupContainer = styled.div`
    --header-height: 2rem;
    --footer-height: 1rem;
    background-color: var(--white);
    color: var(--text-color);

    border-radius: 0.5rem;
    padding: 0.8rem;
    position: relative;
    display: flex;
    flex-direction: column;
`;

export const Popup = props => {
    function handleClose(e) {
        props.show && props.onClose && props.onClose();
        e.stopPropagation();
    }

    function stopPropagation(e) {
        e.stopPropagation();
    }

    return !props.show
        ? null
        : ReactDOM.createPortal(
              <PopupOverlay show={true} zIndex={2000} onClick={handleClose}>
                  <PopupContainer onClick={stopPropagation}>
                      {props.title && <Header>{props.title}</Header>}
                      <Body>{props.children}</Body>
                  </PopupContainer>
              </PopupOverlay>,
              modalRoot
          );
};

export default Modal;
