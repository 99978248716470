import React from 'react';
import { LayersControl, TileLayer } from 'react-leaflet';

const tileLayers = {
    osm: {
        name: 'Open Street Maps',
        element: props => (
            <TileLayer
                url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                eventHandlers={props.eventHandlers}
            />
        ),
    },
    satellite: {
        name: 'Google Maps Satelite',
        element: props => (
            <TileLayer
                url='https://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}'
                maxZoom={20}
                subdomains={['mt1', 'mt2', 'mt3']}
                eventHandlers={props.eventHandlers}
            />
        ),
    },
};

const TileControl = props => {
    const mapTiles = props.tiles ?? ['osm'];
    const tileErrorEventHandlers = {
        tileerror: e => console.log(e.target._map.zoomOut() && 'Tile loading error! Zoomed out.'),
    };

    return mapTiles.length > 1 ? (
        <LayersControl>
            {mapTiles.map((tile, index) => {
                const Tile = tileLayers[tile].element;
                return (
                    <LayersControl.BaseLayer
                        name={tileLayers?.[tile].name}
                        checked={index === 0}
                        key={index}
                    >
                        <Tile eventHandlers={tileErrorEventHandlers} />
                    </LayersControl.BaseLayer>
                );
            })}
        </LayersControl>
    ) : (
        tileLayers[mapTiles[0]].element({ eventHandlers: tileErrorEventHandlers })
    );
};

export default TileControl;
