import React from 'react';
import styled from 'styled-components/macro';
import Card from './Card';

import { FlexMiddle } from '../styled/primitives.css';
import { getSvg } from '../../assets/assetData';

const ForecastWrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: stretch;
    color: var(--gray);
`;

const TitlesColumn = styled.div`
    background: #fbfffa;
    width: max-content;
    display: flex;
    flex-direction: column;
`;

const RowTitles = styled.div`
    flex: 1;
    ${FlexMiddle};
    justify-content: space-around;
    align-items: flex-start;
    padding-right: 0.8rem;
`;

const RowTitle = styled.div`
    width: 100%;
    display: flex;
    gap: 1rem;
    align-items: center;
`;

const Img = styled.div`
    width: 3rem;
    display: flex;
    justify-content: center;
    /* margin-left: 1rem; */
    img {
        height: 1.5rem;
    }
`;

const ForecastTitle = styled.div`
    height: 7.5rem;
    ${RowTitle} {
        height: 2rem;
        background-color: var(--light-gray);
        color: var(--dark-gray);
    }
`;

const ForecastCards = styled.div`
    flex-grow: 1;
    display: flex;
`;

const forecastTitles = [
    { name: 'Rain', tag: 'rain' },
    { name: 'Max Temperature', tag: 'temp_max' },
    { name: 'Min Temperature', tag: 'temp_min' },
    { name: 'Humidity', tag: 'humdt' },
    { name: 'Wind', tag: 'w_speed' },
    { name: 'P.Viticola', tag: 'p_viticola_sev' },
    { name: 'E.Necator', tag: 'e_necator_sev' },
    { name: 'B.Cinerea', tag: 'b_cinerea_sev' },
    { name: 'G.Bidwelli', tag: 'g_bidwellii_sev' },
    { name: 'Spraying cond.', tag: 'spraying' },
];

const FieldForecast = ({ data }) => {
    console.log({ data });
    return (
        <ForecastWrapper>
            <TitlesColumn>
                <ForecastTitle>
                    <RowTitle>
                        <Img>
                            <img src={getSvg('forecast')} alt='' />
                        </Img>
                        <h5>DAILY FORECAST</h5>
                    </RowTitle>
                </ForecastTitle>
                <RowTitles>
                    {forecastTitles.map(({ name, tag }, index) => (
                        <RowTitle key={index}>
                            <Img>
                                <img src={getSvg(tag)} alt={name} />
                            </Img>
                            <h5>{name.toUpperCase()}</h5>
                        </RowTitle>
                    ))}
                </RowTitles>
            </TitlesColumn>
            <ForecastCards>
                {data?.map((item, id) => (
                    <Card item={item} key={id}></Card>
                ))}
            </ForecastCards>
        </ForecastWrapper>
    );
};

export default FieldForecast;
