import styled, { keyframes } from 'styled-components/macro';



const tableAnimation = keyframes`
  0% { opacity: 0; transform: translateY(30px)};
  100% {opacity:1; transform: translateY(0)};
`;

export const TableWrapper = styled.div`
	animation-name: ${tableAnimation};
	animation-duration: 0.6s;
	height: calc(100vh - 150px);
	& > .tabulator {
		margin: 0;
	}
`;