import React from 'react';
import styled from 'styled-components/macro';
import { NavLink } from 'react-router-dom';

import { useFieldCtx } from '../../context/FieldContext';
import { useZoneCtx } from '../../context/ZoneContext';

import winessenseLogo from '../../assets/img/winessense.svg';
import DashboardIcon from '../../assets/img/dashboardIcon.svg';
import ChartIcon from '../../assets/img/chartIcon.svg';
import TableIcon from '../../assets/img/tableIcon.svg';
import CompareIcon from '../../assets/img/compareIcon.svg';
import MapsIcon from '../../assets/img/mapsIcon.svg';
import atfieldLogo from '../../assets/img/atfield-logo.svg';
import { IoMdNotificationsOutline as NotificationIcon } from 'react-icons/io';

import { FlexMiddle, FocusableStyle } from '../styled/primitives.css';

export const NavigationWrapper = styled.div`
    background: var(--green);
    display: flex;
    flex-direction: column;
`;
const LogoWrapper = styled.div`
    flex: 0.2;
    ${FlexMiddle}
`;
const Nav = styled.nav`
    flex: 0.7;
    display: block;
    display: flex;
    justify-content: flex-start;
    align-items: flex-top;
    padding: 0;
    margin: 0;
`;
const NavList = styled.ul`
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
    width: 100%;
    list-style: none;
`;
const NavListItem = styled.li`
    display: flex;
    border-bottom: 1px #5c896e solid;
    &:last-child {
        border-bottom: none;
    }
    a {
        --focus-color: var(--orange);
        --focus-width: 2px;
        ${FocusableStyle};
        padding: 1.3rem 1.3rem 1.3rem 0;
        display: flex;
        flex-grow: 1;
        flex-shrink: 1;
        text-transform: uppercase;
        font-weight: 500;
        font-size: 1.2rem;
        text-decoration: none;
        color: #fff;
        padding-left: 1rem;
    }
    a.active {
        background: var(--pale-green);
    }
`;
const IconWrapper = styled.div`
    ${FlexMiddle}
    img, svg {
        width: 20px;
        height: 20px;
        margin-left: 1rem;
        margin-right: 1rem;
    }
`;
const FooterWrapper = styled.div`
    flex: 0.13;
    background: #23382b;
    display: flex;
    align-items: center;

    img {
        margin-left: 1rem;
    }
`;

const Navigation = () => {
    const { fieldId } = useFieldCtx();
    const { zoneId } = useZoneCtx();

    return (
        <NavigationWrapper>
            <LogoWrapper>
                <img src={winessenseLogo} alt='Winessense logo' />
            </LogoWrapper>
            <Nav>
                <NavList>
                    <NavListItem>
                        <NavLink to={`/${zoneId}/${fieldId}/dashboard`}>
                            <IconWrapper>
                                <img src={DashboardIcon} alt='dashboard icon' />
                            </IconWrapper>
                            Dashboard
                        </NavLink>
                    </NavListItem>

                    <NavListItem>
                        <NavLink to={`/${zoneId}/${fieldId}/charts`}>
                            <IconWrapper>
                                <img src={ChartIcon} alt='chart icon' />
                            </IconWrapper>
                            Chart
                        </NavLink>
                    </NavListItem>

                    <NavListItem>
                        <NavLink to={`/${zoneId}/${fieldId}/table`}>
                            <IconWrapper>
                                <img src={TableIcon} alt='table icon' />
                            </IconWrapper>
                            Table
                        </NavLink>
                    </NavListItem>

                    <NavListItem>
                        <NavLink to={`/${zoneId}/${fieldId}/maps`}>
                            <IconWrapper>
                                <img src={MapsIcon} alt='maps icon' />
                            </IconWrapper>
                            Maps
                        </NavLink>
                    </NavListItem>

                    <NavListItem>
                        <NavLink to={`/${zoneId}/${fieldId}/notifications`}>
                            <IconWrapper>
                                <NotificationIcon />
                            </IconWrapper>
                            Notifications
                        </NavLink>
                    </NavListItem>
                </NavList>
            </Nav>
            <FooterWrapper>
                <img src={atfieldLogo} alt='atfield logo' />
            </FooterWrapper>
        </NavigationWrapper>
    );
};
export default Navigation;
