import styled, {keyframes, css} from "styled-components/macro";

export const shake = keyframes`
	from {
	transform: translateX(-.5rem);
	}	
	40% {
		transform: translateX(.5rem);
	}
	80% {
		transform: translateX(-.5rem);
	}
	to {
		transform: translateX(0);
	}
`;

export const Scale = (factor) => keyframes`
    0% {transform: scale(1);}
    50% {transform: scale(calc(${factor}));}
    100% {transform: scale(1);}
`;