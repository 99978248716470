import React from 'react';
import styled from 'styled-components/macro';

import Navigation, { NavigationWrapper } from '../Navigation/Navigation';
import NewHeader, { HeaderContainer } from '../Header/NewHeader';
import { Outlet } from 'react-router-dom';

export const Main = styled.main`
    flex-grow: 1;
    background: var(--light-green);
    overflow-y: hidden;
    color: inherit;

    padding: 0.5rem;
    height: calc(100vh - var(--header-height));
`;

const ScreenWrapper = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    width: calc(100vw - var(--nav-width));
    color: inherit;
`;

const Wrapper = styled.div`
    --nav-width: 20.7rem;
    --header-height: 70px;

    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: stretch;
    & ${NavigationWrapper} {
        width: var(--nav-width);
    }
    & ${HeaderContainer} {
        height: var(--header-height);
    }
`;

const NewLayout = () => {
    return (
        <Wrapper>
            <Navigation />
            <ScreenWrapper>
                <NewHeader />
                <Main>
                    <Outlet />
                </Main>
            </ScreenWrapper>
        </Wrapper>
    );
};

export default NewLayout;
