import { useCallback, useEffect } from 'react';
import { useMap } from 'react-leaflet';

const ChangeView = ({ center, zoom, childrenFgRef, recenterDeps }) => {
    const map = useMap();

    const getChildrenBounds = useCallback(
        childrenFgRef.current?.getBounds.bind(childrenFgRef.current) ?? (() => {}),
        [childrenFgRef.current?.getBounds]
    );

    useEffect(() => {
        if (getChildrenBounds()?.isValid()) {
            map.fitBounds(getChildrenBounds().pad(0.05));
        } else if (Array.isArray(center) && center.length === 2 && center.every(elm => !!elm)) {
            map.setView(center, center.some(crd => crd === 0) ? 1.5 : 15);
        } else {
            map.fitBounds([
                [-50, -180],
                [70, 179],
            ]);
        }
    }, [JSON.stringify(center), zoom, getChildrenBounds].concat(recenterDeps));

    return null;
};

export default ChangeView;
