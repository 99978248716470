const serverAddress = process.env.REACT_APP_SERVER_ADDRESS;

/**
 * Fetch wrapper for Winessense APIs.
 * @param {string} apiName Name of API at https://www.atfieldtech.com/
 * @param {Object} body Body of request
 * @throws Will throw error with response status if response is not ok.
 * @returns Returns API data object with all 'NaN's replaced with null.
 */
export async function fetchAPI(apiName, body, options = {}) {
    if (!serverAddress) throw new Error('REACT_APP_SERVER_ADDRESS env not variable found!');

    const { serverURL } = options;

    return fetch((serverURL ? serverURL : serverAddress) + apiName, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body),
    }).then(response => {
        if (!response.ok) {
            console.log(`fetchAPI: ${apiName} Error: ${response.status}`);
            throw new Error(`Service not available. Try again later! \nError: ${response.status}`);
        }
        return getJSONnoNaN(response, apiName);
    });
}

async function getJSONnoNaN(response, apiName) {
    // handle unexpected NaN values from server
    // replacing them with null
    let dataText = await response.text();
    dataText = dataText.split('NaN');
    console.log(dataText.join(null) === '');
    if (dataText.length - 1) {
        console.log(`NaN PATCH >> found ${dataText.length - 1} NaN values in ${apiName}`);
    }
    dataText = dataText.join(null);
    return dataText && JSON.parse(dataText);
}

// Factory which returns functions that convert API data between versions
export const DataAdapter = (apiName, from = 'from', to = 'to') => {
    switch (apiName) {
        case 'sHrly':
        case 'sDaly':
            if (`${from}` === '0.4' && `${to}` === '0.2') return seriesDataAdapter_v04_v02;

        default:
            return data => data;
    }
};

//sHrly and sDaly from v0.2 to v0.4
function seriesDataAdapter_v04_v02(data04) {
    const colNames = Object.keys(data04);
    const dataAmount = data04[colNames[0]].length;
    const data02 = [];
    for (let i = 0; i < dataAmount; i++) {
        data02[i] = [];
        for (let col of colNames) {
            data02[i].push(data04[col][i]);
        }
    }
    return { cols: colNames, data: data02 };
}
