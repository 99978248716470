import React from 'react';
import styled from 'styled-components/macro';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { FlexMiddle } from '../styled/primitives.css';
import { NarrowForm, FormTitle, SubmitButton, FormText, WinessenseLogo } from './styledEntry.css';
import TextInput from '../Custom/TextInput';
import { fetchAPI } from '../../utils/fetchAPI';
import { ErrorMessage } from '@hookform/error-message';

const Container = styled.div`
    width: 100%;
    height: 100%;
    ${FlexMiddle}
`;

const schema = yup.object().shape({
    email: yup.string().email('Email address must be valid!'),
});

const ForgotPass = () => {
    const navigate = useNavigate();

    const {
        register,
        handleSubmit,
        formState: { errors },
        setError,
    } = useForm({
        mode: 'onChange',
        resolver: yupResolver(schema),
    });

    function onSubmit(data, e) {
        fetchAPI('fPass', {
            v: '1.0',
            email: data.email,
        })
            .then(response => {
                if (response.resp_code === 0) {
                    navigate('/landing/reset_pass', { state: { email: data.email } });
                } else setError('submit', { message: response.resp_text });
            })
            .catch(error => setError('submit', { message: error.message }));
    }

    return (
        <Container>
            <WinessenseLogo width='8rem' />
            <FormTitle>Forgot your password?</FormTitle>

            <FormText>
                Enter your email address and we will send a verification code
                <br />
                you can use to restart your password.
            </FormText>

            <NarrowForm onSubmit={handleSubmit(onSubmit)}>
                <TextInput
                    {...register('email')}
                    type='text'
                    placeholder='Email'
                    errors={errors}
                />
                <SubmitButton>Send reset email</SubmitButton>
                <ErrorMessage
                    errors={errors}
                    name='submit'
                    render={({ message }) => <FormText>{message}</FormText>}
                />
            </NarrowForm>
            <FormText>
                <Link to='/landing/login'>Back to Login page</Link>
            </FormText>
        </Container>
    );
};

export default ForgotPass;
