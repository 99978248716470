import React from 'react';
import styled from 'styled-components/macro';

import sensorImg from '../../assets/img/login2.png';
import atfieldLogo from '../../assets/img/atfield-logo.svg';
import { Outlet, useMatch } from 'react-router-dom';

const Container = styled.div`
    display: flex;
    background: var(--green);
    height: 100%;
    overflow-y: auto;
`;

const SensorImage = styled.div`
    flex: 1;
    ${({ visible }) =>
        visible ? `background-image: url(${sensorImg});` : 'display: none;'}
    background-size: 230%;
    background-position: 45% 35%;
    background-repeat: no-repeat;
    @media (max-aspect-ratio: 17/13) {
        display: none;
    }
`;

const LandingContainer = styled.div`
    flex: 1;
    height: max-content;
    min-height: 100%;

    display: flex;
    flex-direction: column;
    color: var(--white);
    font-size: 1.1rem;
    & * {
        font-size: inherit;
    }
`;

const LandingContent = styled.div`
    flex: 1 0 0;
    width: 100%;
`;

const LandingFooter = styled.div`
    padding: 1.2rem;
    width: 100%;
    background: var(--dark-green);
    & > img {
        display: block;
        margin-left: auto;
        margin-right: 2rem;
    }
`;

export default function Landing({ children, showImg }) {
    return (
        <Container>
            <SensorImage visible={!!showImg} />
            <LandingContainer>
                <LandingContent>
                    {children ? children : <Outlet />}
                </LandingContent>
                <LandingFooter>
                    <img src={atfieldLogo} alt='Atfield Logo' />
                </LandingFooter>
            </LandingContainer>
        </Container>
    );
}
