import { useEffect, useRef } from 'react';
import useMultiRef from './useMultiRef';

export default function useOnClickOutside(callback) {
    const callbackRef = useRef(); // initialize mutable ref, which stores callback
    const [getMultiRefs, addRef] = useMultiRef(); // refs for "inside" elements

    // update cb on each render, so second useEffect has access to current value
    useEffect(() => {
        callbackRef.current = callback;
    });

    useEffect(() => {
        document.addEventListener('mousedown', handleClick);
        return () => document.removeEventListener('mousedown', handleClick);

        function handleClick(e) {
            if (!callbackRef.current) return;
            const clickInsideRefs = getMultiRefs().reduce(
                (prev, curr) => prev || curr.contains(e.target),
                false
            );
            if (!clickInsideRefs) {
                callbackRef.current(e);
            }
        }
    }, [getMultiRefs()]);

    return addRef;
}
