import styled from 'styled-components/macro';
import { StyledButton } from '../../styled/styledFormElements.css';

import LeafletMap from '../components/LeafletMap';

export const polygonPathOptions = {
    stroke: true,
    color: '#38f',
    weight: 3,
    opacity: 0.5,
    fill: true,
    fillColor: '#38f',
    fillOpacity: 0.2,
};

export const HorizontalSplit = styled.div`
    height: 100%;
    width: 100%;
    position: relative;
    display: flex;
    ${LeafletMap} {
        width: 70%;
    }
`;

export const InfoBar = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    height: min-content;

    padding: 0.5rem;
    gap: 0.5rem;
    ${StyledButton} {
        margin: 0 auto;
    }
`;

export const FormContainer = styled.div`
    width: min-content;
    min-width: 18rem;
    form {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
    }
    fieldset {
        width: 100%;
        padding: 0.5rem;
        display: grid;
        gap: 0.5rem;
        margin-bottom: 0.5rem;
        background-color: var(--light-green);
    }
`;

export const Polylabel = styled.div`
    font-size: 1.2rem;
    font-weight: bold;

    width: min-content;
    height: max-content;
    transform: translate(-40%, -50%);

    color: ${props => props.bg || 'black'};
    ${props =>
        props.bg
            ? `
            filter: invert(1) grayscale(1) contrast(1000);
            letter-spacing: 0.1ch;
        `
            : `
            text-shadow:-1px 1px 0 rgba(255, 255, 255, 0.5),
                        1px 1px 0 rgba(255, 255, 255, 0.5),
                        1px -1px 0 rgba(255, 255, 255, 0.5),
                        -1px -1px 0 rgba(255, 255, 255, 0.5);
        `};
`;
