import React, { useState } from 'react';
import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';
import { useAuthCtx } from '../../context/AuthContext';
import useOnClickOutside from '../../hooks/useOnClickOutside';

import UserProfile from './UserProfile';

const UserMenu = ({ showName }) => {
    const [isOpen, setIsOpen] = useState(false);
    const { logout } = useAuthCtx();
    const clickOutsideRef = useOnClickOutside(() => isOpen && setIsOpen(false));

    return (
        <UserProfileContainer>
            <UserProfile
                showName={showName}
                onPress={() => setIsOpen(old => !old)}
                innerRef={clickOutsideRef}
            />
            <Container active={isOpen} ref={clickOutsideRef}>
                <MenuItem>Profile</MenuItem>
                <MenuItem>
                    <Link to='/edit'>Vineyard Editor</Link>
                </MenuItem>
                <MenuItem onClick={() => logout()}>Logout</MenuItem>
            </Container>
        </UserProfileContainer>
    );
};

export default UserMenu;

const UserProfileContainer = styled.div`
    position: relative;
    z-index: 10000;
`;
const Container = styled.ul`
    --menu-position: ${({ active }) => (active ? 'translateX(0%)' : 'translateX(100%)')};
    position: absolute;
    bottom: calc(0px - var(--heder-padding-Y));
    right: calc(0px - var(--heder-padding-X));
    z-index: 100;
    transform: translateY(100%) var(--menu-position);
    transition: transform 300ms ease;

    list-style: none;
    font-size: 1.25em;

    box-shadow: 3px 3px 8px 0px;
    background-color: var(--light-green);
    border: 1px solid var(--green);
    border-top: none;
    width: 200px;
    height: 400px;
`;

const MenuItem = styled.li`
    padding: 0.2em 1em;
    color: inherit;
    cursor: pointer;
    a {
        color: inherit;
        text-decoration: none;
    }
`;
