import React, { useState, useEffect } from 'react';
import styled from 'styled-components/macro';
import { useNavigate, useLocation, Link, Navigate } from 'react-router-dom';
import { FlexMiddle } from '../styled/primitives.css';
import { FormTitle, FormText, WinessenseLogo } from './styledEntry.css';

const Container = styled.div`
    width: 100%;
    height: 100%;
    ${FlexMiddle}
`;

const Redirect = () => {
    const { state } = useLocation();
    const navigate = useNavigate();
    const [timeLeft, setTimeLeft] = useState(5);

    useEffect(() => {
        const interval = setTimeout(() => {
            if (timeLeft > 0) setTimeLeft(oldTime => oldTime - 1);
            else navigate(state.redirect, { state: state, replace: true });
        }, 1000);

        return () => {
            clearInterval(interval);
        };
    }, [timeLeft]);

    return !state ? (
        <Navigate to='/landing/login' />
    ) : (
        <Container>
            <WinessenseLogo width='8rem' />
            <FormTitle>{state?.title}</FormTitle>
            <br />
            <FormText>{state?.explanation}</FormText>
            <FormText>Redirecting in {timeLeft} seconds.</FormText>
            <br />
            <FormText>
                If you don't get redirected,{' '}
                <Link to={state.redirect} replace>
                    click here!
                </Link>
            </FormText>
        </Container>
    );
};

export default Redirect;
