import React from 'react';
import styled from 'styled-components/macro';
import { FlexMiddle } from '../styled/primitives.css';

const CardWrapper = styled.div`
    flex: 1;
    height: 100%;
    border: 1px var(--light-green) solid;
    ${FlexMiddle}
`;

const ImageWrapper = styled.div`
    display: flex;
    justify-content: center;
    width: 5rem;
    height: 5rem;
    border-radius: 50%;
    margin-top: 0.5rem;
`;

const ForecastHeader = styled.div`
    width: 100%;
    ${FlexMiddle}
`;

const ForecastDate = styled.div`
    width: 100%;
    height: 2rem;
    ${FlexMiddle}
    background: var(--light-gray);
    color: var(--dark-gray);

    span {
        font-weight: bold;
    }
`;
const ForecastValues = styled.div`
    ${FlexMiddle}
    justify-content: space-around;
    flex: 1;
    width: 100%;
    color: var(--gray);

    font-weight: bold;
    font-size: 1.1rem;
`;

const diseaseColorStyle = value => {
    if (value === 'good') return 'var(--good-color)';
    if (value === 'fair') return 'var(--fair-color)';
    if (value === 'poor') return 'var(--poor-color)';
    if (isNaN(value) || value === '') return 'var(--gray)';
    if (value < 0) return 'var(--gray)';
    if (value <= 1) return 'var(--good-color)';
    if (value <= 20) return 'var(--fair-color)';
    if (value <= 50) return 'var(--poor-color)';
    if (value <= 100) return 'var(--bad-color)';
    else return 'var(--gray)';
};
const ColoredP = styled.p`
    ${({ value }) => `color: ${diseaseColorStyle(value)};`};
`;

const Card = ({ item }) => {
    let colorFactor1 = 350 - Math.round(+item.air_temp_max) * 10;
    let colorFactor2 = 350 - Math.round(+item.air_temp_min) * 30;

    let yesterday = new Date();
    let today = new Date();
    let tomorrow = new Date();
    yesterday.setDate(today.getDate() - 1);
    tomorrow.setDate(today.getDate() + 1);

    let [weekday, month, day] = item.time.split(' ');
    let isYesterday = Number(day) === yesterday.getDate();
    let isToday = Number(day) === today.getDate();
    let isTomorrow = Number(day) === tomorrow.getDate();

    return (
        <CardWrapper>
            <ForecastHeader>
                <ForecastDate>
                    {isYesterday && <span>Yesterday</span>}
                    {isToday && <span>Today</span>}
                    {isTomorrow && <span>Tomorrow</span>}
                    {!isYesterday && !isToday && !isTomorrow && (
                        <div>
                            {weekday}
                            <span>{` ${day} ${month}`}</span>
                        </div>
                    )}
                </ForecastDate>
                <ImageWrapper>
                    <img src={require(`../../assets/img/svgTemp/${item.desc_icon}.svg`)} />
                </ImageWrapper>
            </ForecastHeader>
            <ForecastValues>
                <ColoredP>{item.precipitation} mm</ColoredP>
                <p
                    style={{
                        color: `rgb(255, ${colorFactor1}, 0`,
                    }}
                >
                    {item.air_temp_max} °C
                </p>
                <p
                    style={{
                        color: `rgb(0, ${colorFactor2}, 255`,
                    }}
                >
                    {item.air_temp_min} °C
                </p>
                <ColoredP>{item.air_humidity.toFixed()} %</ColoredP>
                <ColoredP>{item.wind_speed.toFixed()} km/h</ColoredP>
                <ColoredP value={item.p_viticola_sev}>{item.p_viticola_sev ?? '?'}</ColoredP>
                <ColoredP value={item.e_necator_sev}>{item.e_necator_sev ?? '?'}</ColoredP>
                <ColoredP value={item.b_cinerea_sev}>{item.b_cinerea_sev ?? '?'}</ColoredP>
                <ColoredP value={item.g_bidwellii_sev}>{item.g_bidwellii_sev ?? '?'}</ColoredP>
                <ColoredP value={item.spray_conditions}>{item.spray_conditions}</ColoredP>
            </ForecastValues>
        </CardWrapper>
    );
};

export default Card;
