import React from 'react';
import styled from 'styled-components/macro';
import { MapContainer as Map, TileLayer, Polygon } from 'react-leaflet';

import Card from './Card';

import Temperature from '../../assets/img/currentData/Temperature.svg';
import AirHumidity from '../../assets/img/currentData/AirHumidity.svg';
import WindSpeed from '../../assets/img/currentData/WindSpeed.svg';
import WindDirection from '../../assets/img/currentData/WindDirection.svg';
import Rain from '../../assets/img/currentData/Rain.svg';
import PotDisease from '../../assets/img/currentData/PotDisease.svg';

const Container = styled.div`
    display: flex;
`;

const MapWrapper = styled.div`
    width: 16rem;
    height: 16rem;
`;

const DataWrapper = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
`;

const DataCards = styled.div`
    display: flex;
    flex: 1;
    padding: 0 1vw;
    gap: 1vw;
    align-items: flex-end;
`;

const LastUpdated = styled.div`
    text-align: right;
    padding: 0.2rem 1rem;
`;

function dataAdapter(cCondData) {
    if (!cCondData) return null;
    const flatArray = [...cCondData.readings, ...cCondData.stats];
    const getVal = (valName) => flatArray.find((obj) => obj.name === valName).value;
    const decimalPlaces = (number, decimals) => Number(number).toFixed(decimals);
    const readingsFixedDecimals = (indx, decimals = 0) =>
        Number(cCondData?.readings[indx].value).toFixed(decimals);
    return [
        {
            imgName: Temperature,
            value: `${decimalPlaces(getVal('air temperature'), 1)} °C`,
            textValue: 'Air temperature',
        },
        {
            imgName: AirHumidity,
            value: `${decimalPlaces(getVal('relative air humidity'), 1)} %`,
            textValue: 'Relative\n air humidity',
        },
        {
            imgName: WindSpeed,
            value: `${decimalPlaces(getVal('wind speed'), 1)} km/h\n ${getVal(
                'wind direction'
            )}`,
            textValue: 'Wind speed',
        },
        {
            imgName: Rain,
            value: `${decimalPlaces(getVal('rainfall'), 1)} mm`,
            textValue: 'Rainfall',
        },
        {
            imgName: Rain,
            value: `${decimalPlaces(getVal('rainfall 24h'), 1)} mm`,
            textValue: 'Rainfall 24h',
        },
        {
            imgText: 'BBCH',
            value: `${getVal('bbch')}`,
            textValue: 'Phenological development stages',
        },
        {
            imgText: 'GDD',
            value: `${getVal('growing degree days')}`,
            textValue: 'Growing degree days',
        },
        {
            imgName: PotDisease,
            value: `${getVal('pot_disease_sev')}`,
            textValue: 'Potential disease severity',
        },
    ];
}

const CurrentData = ({ coordinates, lat, lng, zoom, currData }) => {
    const data = dataAdapter(currData);

    return (
        <Container>
            <MapWrapper>
                {coordinates && lat && lng && (
                    <Map
                        center={{ lat: lat, lng: lng }}
                        zoom={zoom}
                        scrollWheelZoom={true}
                        style={{ height: '100%', width: '100%' }}
                    >
                        <TileLayer
                            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                            url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
                        />

                        {coordinates.map((item, index) => (
                            <Polygon
                                className='polygon'
                                positions={item}
                                key={index}
                                color={'#3E644D'}
                            ></Polygon>
                        ))}
                    </Map>
                )}
            </MapWrapper>
            <DataWrapper>
                <DataCards>
                    {data?.map((item, indx) => (
                        <Card item={item} key={indx} />
                    ))}
                </DataCards>
                <LastUpdated>
                    <p>
                        Last Updated: {new Date().toString().split('GMT')[0].slice(0, -4)}
                    </p>
                </LastUpdated>
            </DataWrapper>
        </Container>
    );
};

export default CurrentData;
