import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';


import clockIcon from '../../assets/img/clock.svg';
// RENDERUJE SE 2 PUTA, cleanup se ne desava, set interval se vrti isti
const DateTime = () => {
	const [date, setDate] = useState(extractDateParts(new Date()));

	useEffect(() => {
		const timerID = setInterval(() => {
			const currentDate = extractDateParts(new Date()) 
			setDate(prevDate => {
				return prevDate.minute == currentDate.minute 
					? prevDate 
					: currentDate
			})
		}, 1000);
		return () => clearInterval(timerID)
	}, []);

	function extractDateParts(dateObj){
		return new Intl.DateTimeFormat('en-gb', {
			minute: 'numeric',
			hour: 'numeric',
			weekday: 'long',
			day: 'numeric',
			month: 'long',
			year: 'numeric',
		}).formatToParts(dateObj)										//get array of date parts as objects
		.reduce((obj, item) => (obj[item.type] = item.value, obj) ,{})	//make object from array of objects
	}
	
	return (
		<DateTimeContainer>
			<DateWrapper>
				{`${date.weekday}, `}
				<span>{`${date.day}. ${date.month} ${date.year}`}</span>
			</DateWrapper>
			<TimeWrapper>
				<img src={clockIcon} alt='clockIcon' />
				<span>
					{`${date.hour}:${date.minute}`}
				</span>
			</TimeWrapper>
		</DateTimeContainer>
	);
};

const DateTimeContainer = styled.div`
`;

const DateWrapper = styled.div`
	width: max-content;
	span {
		font-weight: bold;
	}
`;
const TimeWrapper = styled.div`
	display: flex;
	justify-content: flex-end;
	gap: .2em;
`;
export default DateTime;
