import React from 'react';
import styled from 'styled-components/macro';
import { useNavigate, useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { ErrorMessage } from '@hookform/error-message';
import { FlexMiddle } from '../styled/primitives.css';
import { NarrowForm, FormTitle, SubmitButton, FormText, WinessenseLogo } from './styledEntry.css';
import TextInput from '../Custom/TextInput';
import { fetchAPI } from '../../utils/fetchAPI';

const Container = styled.div`
    width: 100%;
    height: 100%;
    ${FlexMiddle}
    text-align: center;
`;


const schema = yup.object().shape({
    verification: yup
        .string()
        .required('Enter verification code!')
        .matches(/^[\d]{6}$/, 'Invalid code!'),
});

const Verify = () => {
    const navigate = useNavigate();
    const { state } = useLocation();

    const {
        register,
        handleSubmit,
        formState: { errors },
        setError,
        clearErrors,
    } = useForm({
        mode: 'onChange',
        resolver: yupResolver(schema),
    });

    function resendVerificationEmail() {
        fetchAPI('gCode', {
            v: '1.0',
            email: state.email,
            hash_pass: state.hash_pass,
        })
            .then(response => {
                if (response.resp_code === 0) {
                    navigate('/landing/verify', { state: state, replace: true });
                } else setError('submit', { message: response.resp_text });
            })
            .catch(error => setError('submit', { message: error.message }));
    }

    function onSubmit(data, e) {
        fetchAPI('vUser', {
            v: '1.0',
            email: state.email,
            code: data.verification,
        })
            .then(response => {
                if (response.resp_code === 0) {
                    navigate('/landing/redirect', {
                        state: {
                            ...state,
                            from: 'verify',
                            redirect: '/landing/login',
                            title: 'Email verified!',
                            explanation: 'You will be automatically redirected to Log in page.',
                        },
                    });
                } else setError('submit', { message: response.resp_text });
            })
            .catch(error => setError('submit', { message: error.message }));
    }

    return (
        <Container>
            <WinessenseLogo width='8rem' />
            <FormTitle>Verify your email</FormTitle>

            <FormText>
                We've sent an email to <b>{`${state.email}`}</b>.
                <br />
                Enter the verification code from email into the form below.
                <br />
                If you have not received verification email, please check
                <br />
                your "Spam" folder or click the "Send it again!" link.
            </FormText>

            <NarrowForm onSubmit={handleSubmit(onSubmit)} onFocus={e => clearErrors('submit')}>
                <TextInput
                    {...register('verification')}
                    type='text'
                    placeholder='Verification code'
                    errors={errors}
                />
                <SubmitButton>Verify email</SubmitButton>
                <ErrorMessage
                    errors={errors}
                    name='submit'
                    render={({ message }) => <FormText>{message}</FormText>}
                />
            </NarrowForm>

            <FormText>
                Didn't get verification email?{' '}
                <a href='#' onClick={resendVerificationEmail}>
                    Send it again!
                </a>
            </FormText>
        </Container>
    );
};

export default Verify;
