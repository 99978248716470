import React, { useEffect, useMemo, useState } from 'react';
import { Marker, Popup, useMapEvents } from 'react-leaflet';

const MapEvents = props => {
    
    const funcStrings = Object.values(props).reduce((curr, prev) => prev + curr, '');

    const LeafletEventHandlerFnMap = useMemo(()=>{

        const handlerFnMap = {};
        for (let [key, value] of Object.entries(props)) {
            if (key.slice(0, 2) !== 'on') continue;
            handlerFnMap[key.slice(2).toLowerCase()] = value;
        }
        return handlerFnMap

    },[funcStrings])
   
   
    useMapEvents(LeafletEventHandlerFnMap);
 

    return null;
};

export default MapEvents;
